import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import studios from '../views/services/studios.vue'
import studiosDetails from '../components/services/studios/list-details.vue'
import orders from '../views/orders/order.vue'
import ordersDetails from '../components/orders/list-details.vue'
import orderDetailsPayment from '../components/orders/order-details/payment.vue'
import notifications from '../views/notifications/notifications.vue'
import profile from '../views/profile/profile.vue'
import about from '../views/static-pages/about.vue'
import terms from '../views/static-pages/terms.vue'
import faqs from '../views/static-pages/faqs.vue'
import agreement from '../views/static-pages/agreement.vue'
import contactUs from '../views/static-pages/contact-us.vue'
import refundPolicy from '../views/static-pages/refund-policy.vue'
import bePartnerWithUs from '../views/static-pages/be-partner-with-us.vue'
import favourite from '../views/favourite/index.vue'
import chats from '../views/chats/index.vue'
import chatDetails from '../views/chats/details.vue'
import userContracts from '../views/user-contracts/details.vue'
import NotFound from '../views/NotFound.vue'
import axios from "axios";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {title: 'Home'}
    },
    {
        path: '/spaces',
        name: 'studios',
        component: studios,
        meta: {title: 'Studios'}
    },
    {
        path: '/spaces/:id/:marketer_id?',
        name: 'studiosDetails',
        component: studiosDetails,
        meta: {title: 'Studios Deatils'},
        beforeEnter: (to, from, next) => {
            // Check if id parameter is a string
            if (isNaN(to.params.id)) {
                // Redirect to "notfound" route
                next({name: 'NotFound'}); // Update with your actual notfound route name
            } else {
                next();
            }
        },
    },
    {
        path: '/orders',
        name: 'orders',
        component: orders,
        meta: {title: 'Orders'}
    },
    {
        path: '/orders/studio-order-details/:id',
        name: 'studioOrdersDetails',
        component: ordersDetails,
        meta: {title: 'studio Order details'}
    },
    {
        path: '/orders/studio-order-details/payment/:id',
        name: 'studioOrdersDetailsPayment',
        component: orderDetailsPayment,
        meta: {title: 'studio Order details payment'}
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: notifications,
        meta: {title: 'notifications'}
    },
    {
        path: '/profile',
        name: 'profile',
        component: profile,
        meta: {title: 'profile'}
    },
    {
        path: '/about',
        name: 'about',
        component: about,
        meta: {title: 'about'}
    },
    {
        path: '/terms',
        name: 'terms',
        component: terms,
        meta: {title: 'terms'}
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: faqs,
        meta: {title: 'faqs'}
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: agreement,
        meta: {title: 'agreement'}
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: contactUs,
        meta: {title: 'contact-us'}
    },
    {
        path: '/refund-policy',
        name: 'refund-policy',
        component: refundPolicy,
        meta: {title: 'refund-policy'}
    },
    {
        path: '/be-partner-with-us',
        name: 'be-partner-with-us',
        component: bePartnerWithUs,
        meta: {title: 'be-partner-with-us'}
    },
    {
        path: '/favourite',
        name: 'favourite',
        component: favourite,
        meta: {title: 'favourite'}
    },
    {
        path: '/chats',
        name: 'chats',
        component: chats,
        meta: {title: 'chats'}
    },
    {
        path: '/chat/:id',
        name: 'chatDetails',
        component: chatDetails,
        meta: {title: 'chat details'},
        beforeEnter: (to, from, next) => {
            if (isNaN(to.params.id)) {
                next({name: 'NotFound'});
            } else {
                next();
            }
        },
    },
    {
        path: '/user-contracts/:id',
        name: 'userContracts',
        component: userContracts,
        meta: {title: 'user contract details'},
        beforeEnter: (to, from, next) => {
            if (isNaN(to.params.id)) {
                next({name: 'NotFound'});
            } else {
                next();
            }
        },
    },
    {
        path: '/.well-known/apple-developer-merchantid-domain-association',
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
        meta: {title: 'Not Found'}
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

router.beforeEach((to, from, next) => {
    /* check chat status */
    const userObject = JSON.parse(localStorage.getItem('user'));
    if (userObject) {
        try {
            axios.post("close-chats", {}, {headers: {'Authorization': 'Bearer ' + userObject.accessToken}})
                .then().catch();
        } catch (error) {
            console.error(error);
        }
    }
    next();
});

router.beforeEach((to, from, next) => {
    const privatePages = ['/profile', '/orders', '/favourite', '/chats'];
    const authRequired = privatePages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    if (authRequired && !loggedIn) {
        next('/');
    } else {
        next();
    }
});


export default router