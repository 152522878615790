<template>
  <section class="orders">
    <v-container>
      <v-row>
        <filterSide
            @filterOrdersParams="handleFilterOrdersParams"
            :sortValue="sortValue"
            :currentPage="currentPage"
            ref="childComponent"
        />
        <v-col cols="12" md="9" class="mt-5">
          <v-row>
            <v-col cols="12">
              <div
                  class="__bread d-flex justify-content-between align-items-start flex-row flex-wrap"
              >
                <div
                    class="d-flex justify-start align-start flex-row flex-wrap gap-3 mt-2"
                >
                  <span style="font-size: 14px">
                    <span class="font-weight-bold" style="font-size: 16px">
                      {{ totalOrders }}
                    </span>
                    {{ $t("projects.result") }}
                  </span>
                  <v-breadcrumbs :items="Bread"></v-breadcrumbs>
                </div>
                <div class="sort__">
                  <v-select :placeholder="$t('projects.sortBy')"
                            required outlined dense
                            :items="itemsSort"
                            :item-text="(sort) => sort.title"
                            :item-value="(sort) => sort.value"
                            v-model="sortValue"
                  ></v-select>
                </div>
              </div>
            </v-col>
            <orderProjects
                :allOrders="allOrders"
                :typeOrder="typeOrder"
                v-if="typeOrder === 'projects'"
            />
            <orderStudios
                :allOrders="allOrders"
                :typeOrder="typeOrder"
                v-if="typeOrder === 'studios'"
            />
            <orderAcademy
                :allOrders="allOrders"
                :typeOrder="typeOrder"
                v-if="typeOrder === 'cine_academies'"
            />
            <orderMarkets
                :allOrders="allOrders"
                :typeOrder="typeOrder"
                v-if="typeOrder === 'professional_markets'"
            />
            <orderStocks
                :allOrders="allOrders"
                :typeOrder="typeOrder"
                v-if="typeOrder === 'cine_stocks'"
            />
            <div v-if="allOrders.length == 0 && !loadingData">
              <NoData/>
            </div>
            <div class="" v-if="loadingData">
              <v-row>
                <v-col cols="12" md="12">
                  <v-skeleton-loader
                      class="mx-auto"
                      type="list-item-three-line , list-item-three-line , actions"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="12">
                  <v-skeleton-loader
                      class="mx-auto"
                      type="list-item-three-line , list-item-three-line , actions"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="12">
                  <v-skeleton-loader
                      class="mx-auto"
                      type="list-item-three-line , list-item-three-line , actions"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div class="pagination__ mt-5" v-if="allOrders.length > 0">
              <template>
                <div class="text-center">
                  <v-btn
                      class="show_more"
                      color="#000000DE"
                      v-if="showMoreButton"
                      @click="loadMoreProjects"
                  >{{ $t("display-more") }}
                  </v-btn
                  >
                </div>
              </template>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import filterSide from "@/components/orders/filter.vue";
import orderProjects from "./order-type/projects.vue";
import orderStudios from "./order-type/studios.vue";
import orderAcademy from "./order-type/academy.vue";
import orderMarkets from "./order-type/markets.vue";
import orderStocks from "./order-type/stocks.vue";
import NoData from '@/views/no-data.vue'
import axios from "axios";

export default {
  components: {
    filterSide,
    orderProjects,
    orderAcademy,
    orderStudios,
    orderMarkets,
    orderStocks,
    NoData
  },
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t("my-orders"),
          disabled: false,
          to: "",
        },
      ],
      itemsSort: [
        {
          title: this.$t("itemsSort.new"),
          value: "desc",
        },
        {
          title: this.$t("itemsSort.old"),
          value: "asc",
        }
      ],
      attrs: {
        class: "mb-2",
        boilerplate: true,
      },
      sortValue: "",
      allOrders: [],
      last_page: "",
      totalOrders: 0,
      currentPage: 1,
      typeOrder: "",
      showMoreButton: true,
      loadingData: false,
    };
  },
  methods: {
    handleFilterOrdersParams(params) {
      this.loadingData = true;
      axios
          .get("my-orders", {params})
          .then((res) => {
            this.loadingData = false;
            let newData = [];
            if (Array.isArray(res.data.data.data)) {
              newData = res.data.data.data;
            }
            this.currentPage = res.data.data.pagination.currentPage;
            this.totalOrders = res.data.data.pagination.total;
            this.last_page = res.data.data.pagination.last_page;
            this.typeOrder = res.data.additional.type;
            localStorage.setItem("typeOrder", res.data.additional.type);
            if (this.currentPage === 1) {
              this.allOrders = newData;
            } else {
              this.allOrders = [...this.allOrders, ...newData];
            }
            this.showMoreButton = this.currentPage < this.last_page;
          })
          .catch((e) => {
            this.loadingData = false;
          });
    },
    loadMoreProjects() {
      this.currentPage++;
      this.$refs.childComponent.emitFilterOrders(this.currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.show_more {
  color: #fff !important;
}

.__bread {
  .v-breadcrumbs {
    padding-top: 0 !important;
    display: block;
  }
}

.orders {
  ._top {
    p {
      color: #868e96;
      margin-bottom: 5px;
    }
  }

  ._title {
    .v-card__title {
      padding: 0;
      color: #212529;
      font-size: 18px;
      text-align: start;
    }
  }

  ._info {
    p,
    i {
      color: #212529;
      font-weight: 500;
      font-size: 16px;
    }

    i {
      font-size: 22px;
    }
  }
}

.v-card {
  box-shadow: none !important;
  border: 1px solid #e8e9ea;
  border-color: #e8e9ea !important;
}
</style>

<style scoped lang="css">
.sort__ .v-menu__content.theme--light.menuable__content__active {
  z-index: 999 !important;
}

.sort__ .v-input {
  width: 200px !important;
}

.sort__ .v-select.v-text-field input {
  cursor: pointer !important;
}
</style>
