<template>
  <section class="static-pages">
    <v-container>
      <div class="__bread mt-5">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div class="content" v-if="getFaqs.length > 0">
        <h3>{{ $t('footer.faq') }}</h3>
        <hr/>
        <v-expansion-panels class="mb-6">
          <v-expansion-panel v-for="(item, index) in getFaqs" :key="index">
            <v-expansion-panel-header expand-icon="mdi-menu-down" class="text-start" style="line-height: 24px;">
              {{ item.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-6 text-justify" v-html="item.answer"></v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>
        <NoData/>
      </div>
    </v-container>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NoData from "../no-data.vue";

export default {
  components: {
    NoData,
  },
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t('footer.faq'),
          disabled: true,
          to: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters('home', ['getFaqs'])
  },
  mounted() {
    this.$store.dispatch('home/getFaqs')
  }
};
</script>

<style lang="scss" scoped>
.static-pages {
  height: 100%;
  background-color: #fafafa;
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }

  .__bread {
    box-shadow: none !important;

    .v-breadcrumbs {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: none !important;
      padding: 12px;
    }

    .v-breadcrumbs__item {
      color: #000 !important;
    }

    .v-breadcrumbs__item--disabled {
      color: #868e96 !important;
    }
  }

  .content {
    margin-top: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    h3 {
      font-weight: bold;
      margin-top: 5px;
      color: #4d4d4d;
    }

    p {
      line-height: 1.8;
      color: #4d4d4d;
      text-align: start;
    }

    hr {
      height: 5px;
      color: #e0e0e0;
      width: 100%;
    }
  }

  .v-expansion-panel-header__icon {
    margin-inline-start: auto !important;
    margin-left: inherit !important;
  }

  .v-expansion-panel-content__wrap {
    background: #f6f6f6 !important;
    border: 1px solid #e0e0e0 !important;
    padding: 13px !important;
  }
}
</style>
