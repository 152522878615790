<template>
  <div class="edit_profile">
    <h3>{{ $t('personal-info-edit') }}</h3>
    <hr/>
    <div class="edit_profile_from">
      <v-row>
        <v-col cols="12" md="12">
          <div
              class="avatar d-flex justify-content-start flex-column align-items-start"
          >
            <p>{{ $t('personal-image') }}</p>
            <div class="select_img relative">
              <img v-if="GetDataProfile" :src="GetDataProfile.image_url" alt="avatar" width="130" height="130"/>
              <v-skeleton-loader v-else type="image" width="130" height="130"
                                 style="border-radius: 130px;"></v-skeleton-loader>

              <div class="input d-flex justify-content-start flex-column">
                <v-icon> mdi-pen</v-icon>
                <input
                    type="file"
                    name="image"
                    @change="onFileChange"
                    accept="image/png, image/jpeg, image/bmp"
                />
              </div>
              <div class="avatar_info">
                <span>
                  {{ $t('add-personal-image') }}
                  <br/>
                  {{ $t('allowed-size-256px') }}
                </span>
              </div>
            </div>
          </div>
        </v-col>

        <hr/>
        <v-col cols="12" md="6">
          <div
              class="d-flex justify-content-start align-items-start flex-column w-100 form"
              style="gap: 10px; height: 90px"
          >
            <span> {{ $t('auth.firstname') }} </span>
            <v-text-field
                v-model="GetDataProfile.first_name"
                required
                outlined
                class="w-100"
                :error-messages="firstNameError"
                @input="$v.GetDataProfile.first_name.$touch()"
                @blur="$v.GetDataProfile.first_name.$touch()"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div
              class="d-flex justify-content-start align-items-start flex-column form"
              style="gap: 10px; height: 90px"
          >
            <span> {{ $t('second-name') }} </span>
            <v-text-field
                v-model="GetDataProfile.last_name"
                required
                class="w-100 h-3"
                outlined
                :error-messages="lastNameError"
                @input="$v.GetDataProfile.last_name.$touch()"
                @blur="$v.GetDataProfile.last_name.$touch()"
            ></v-text-field>
          </div>
        </v-col>
        <hr style="margin: 10px 0 22px 0"/>
      </v-row>
      <div class="d-flex justify-content-end py-3">
        <v-btn
            class="save"
            depressed
            color="#000"
            @click="handleProfile"
            :loading="loading"
        >
          {{ $t('save-edits') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, maxLength} from "vuelidate/lib/validators";
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  mixins: [validationMixin],
  validations: {
    GetDataProfile: {
      first_name: {required, maxLength: maxLength(30)},
      last_name: {required, maxLength: maxLength(30)},
    },
  },
  data() {
    return {
      image: null,
      loading: false,
    };
  },
  computed: {
    firstNameError() {
      const errors = [];
      if (!this.$v.GetDataProfile.first_name.$dirty) return errors;
      !this.$v.GetDataProfile.first_name.maxLength &&
      errors.push(this.$t("errors.FirstnameLength"));
      !this.$v.GetDataProfile.first_name.required &&
      errors.push(this.$t("errors.FirstnameReq"));
      return errors;
    },
    lastNameError() {
      const errors = [];
      if (!this.$v.GetDataProfile.last_name.$dirty) return errors;
      !this.$v.GetDataProfile.last_name.maxLength &&
      errors.push(this.$t("errors.FirstnameLength"));
      !this.$v.GetDataProfile.last_name.required &&
      errors.push(this.$t("errors.LastnameReq"));
      return errors;
    },
    ...mapGetters(["GetDataProfile", "getMessageProfile", "getErrorProfile"]),
    // PAYLOADPROFILE() {
    //   const PAYLOAD = {
    //     first_name: this.GetDataProfile.first_name,
    //     last_name: this.GetDataProfile.last_name,
    //     image: this.image,
    //   };
    //   const profileData = { ...PAYLOAD };
    //   return profileData;
    // },
  },
  created() {
    this.handleGetDataProfile();
  },
  methods: {
    ...mapActions(["handleGetDataProfile"]),
    onFileChange(e) {
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload = () => {
        this.GetDataProfile.image_url = reader.result;
      };
    },
    // handleProfile() {
    //   this.$v.$touch();
    //   if (this.$v.$invalid) return;
    //   this.$store.dispatch("changeProfile", this.PAYLOADPROFILE)
    //     .then((res) => {
    //       this.TriggerNotify("success", this.getMessageProfile);
    //     })
    //     .catch((e) => {
    //       this.TriggerNotify("error", e.response.data.message);
    //     });
    // },
    handleProfile() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      const form_data = new FormData();
      form_data.append("first_name", this.GetDataProfile.first_name);
      form_data.append("last_name", this.GetDataProfile.last_name);
      form_data.append("image", this.image);
      axios
          .post("profile-update", form_data)
          .then((res) => {
            this.loading = false;
            this.TriggerNotify("success", res.data.message);
            localStorage.setItem('userImage', res.data.data.image_url)
            this.handleGetDataProfile()
            location.reload()
          })
          .catch((e) => {
            this.loading = false;
            this.TriggerNotify("error", e.response.data.message);
          });
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  background-color: transparent !important;
}

.edit_profile {
  hr {
    color: #e0e0e0;
    height: 15px !important;
    margin-bottom: 22px;
  }

  h3 {
    color: #4d4d4d;
    text-align: start;
    font-size: 20px;
    font-weight: bold;
  }

  .edit_profile_from {
    p {
      font-size: 15px;
      color: #4d4d4d;
      margin-bottom: 5px !important;
    }

    .select_img {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      img {
        border-radius: 50%;
        object-fit: unset;
        border-radius: 50%;
        border: 1px solid #000
      }

      .input {
        position: relative;

        i {
          position: absolute;
          bottom: 8px;
          right: 99px;
          background: #fff;
          padding: 5px;
          border-radius: 50%;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        input {
          position: absolute;
          top: -40px;
          opacity: 0;
        }
      }

      .avatar_info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        margin: 10px 0;

        span {
          color: #868e96;
          font-size: 15px;
        }
      }
    }

    .form {
      span {
        color: #000;
        font-size: 15px;
      }
    }
  }

  .save {
    color: #fff;
    margin-inline-start: auto;
    letter-spacing: 0;
    padding: 12px !important;
    height: 43px !important;
  }
}
</style>
