<template>
  <section class="Not__found">
    <v-container>
      <div class="__bread mt-10 mt-md-0">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column mt-16">
        <img class="img-fluid" src="/img/notFoundImage.png" alt="not found image">
        <h6>{{ $t('please-contact-admin') }}</h6>
        <p>{{ $t('sorry-page-not-found') }}</p>
        <div class="Not__found__btns">
          <router-link to="/">{{ this.$t("Breadcrumb.home") }}</router-link>
          <router-link to="/contact-us">{{ $t('contact-us') }}</router-link>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    Bread() {
      return [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t('page-not-found'),
          disabled: true,
          to: "",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.Not__found {
  min-height: 75vh;
  background: #fafafa;
  padding: 0 0 30px 0;
  @media(max-width: 991px) {
    padding: 30px 0
  }

  img {
    //margin-top: 48px;
    //width: 300px;
    //margin-bottom: 48px;
  }

  h6 {
    color: #000;
    font-size: 20px;
    margin-bottom: 24px !important;
    font-weight: bold;
  }

  p {
    color: #969696;
    font-size: 18px;
    margin-bottom: 32px !important;
  }

  .Not__found__btns {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    a {
      padding: 16px 32px;
      border-radius: 4px;
      background-color: #000;
      color: #fff;
      width: 200px;

      &:last-child {
        background-color: #e0e0e0;
        color: #000;
      }
    }
  }
}

.__bread {
  box-shadow: none !important;

  .v-breadcrumbs {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none !important;
    padding: 12px;
  }

  .v-breadcrumbs__item {
    color: #000 !important;
  }

  .v-breadcrumbs__item--disabled {
    color: #868e96 !important;
  }
}
</style>
