// store/moduleName.js
import axios from 'axios'

const state = {
    siteData: "",
    services: [],
    sections: [],
    social: {},
    homeSetting: {},
    countries: [],
    cities: [],
    specialStudios: [],
    studioCategories: [],
    paymentIcons: [],
    whatsappSettings: {},
    seoSettings: {},
    explanations: [],
    contactMessage: "",
    faqs: []
};

const mutations = {
    setSiteData(state, data) {
        state.siteData = data.data;
        if (data && data.additional) {
            state.services = data.additional.services;
            state.sections = data.additional.sections;
            state.social = data.additional.social;
            state.homeSetting = data.additional.homeSetting;
            state.countries = data.additional.countries;
            state.cities = data.additional.cities;
            state.specialStudios = data.additional.specialStudios;
            state.studioCategories = data.additional.studioCategories;
            state.paymentIcons = data.additional.paymentIcons;
            state.whatsappSettings = data.additional.whatsappSettings;
            state.seoSettings = data.additional.seoSettings;
            state.explanations = data.additional.explanations;
        }
    },
    SET_CONTACT_DATA(state, contactMessage) {
        state.contactMessage = contactMessage
    },
    SET_DATA_FAQS(state, faqs) {
        state.faqs = faqs
    }
};

const actions = {
    fetchSiteData({commit}) {
        axios.get('site-data')
            .then(response => {
                commit('setSiteData', response.data);
            })
            .catch(error => {
                console.error('Error fetching site data:', error);
            });
    },
    async contactUs({commit}, payload) {
        return axios.post('send-contact-us-message', payload).then((res) => {
            commit('SET_CONTACT_DATA', res.data.message)
        })
    },
    async getFaqs({commit}) {
        return axios.get('answered-questions').then((res) => {
            commit('SET_DATA_FAQS', res.data.data.data)
        })
    }
};

const getters = {
    getSiteData(state) {
        return state.siteData;
    },
    getSections(state) {
        return state.sections;
    },
    getServices(state) {
        return state.services;
    },
    getSocial(state) {
        return state.social;
    },
    getSetting(state) {
        return state.homeSetting;
    },
    getCountries(state) {
        return state.countries;
    },
    getCities(state) {
        return state.cities;
    },
    getStudios(state) {
        return state.specialStudios;
    },
    getCatg(state) {
        return state.studioCategories;
    },
    getPayment(state) {
        return state.paymentIcons;
    },
    getWhatsappSettings(state) {
        return state.whatsappSettings;
    },
    getSeoSettings(state) {
        return state.seoSettings;
    },
    getExplanations(state) {
        return state.explanations;
    },
    getContactData: state => state.contactMessage,
    getFaqs: state => state.faqs
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};