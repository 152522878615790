<template>
  <section class="categories_sec" v-if="sections.length > 0">
    <div class="container">
      <div class="section_title">
        <h1>{{ $t("navbar.sections") }}</h1>
        <p>{{ $t("home.descSections") }}</p>
      </div>
      <div class="row" v-if="sections.length > 0">
        <div style="direction: rtl">
          <carousel v-bind="options">
            <slide
              class="slide-item"
              v-for="(item, index) in sections"
              :key="index"
            >
              <router-link :to="{
                    path: `/sections`,
                    query: { skills: item.id }
                  }">
                <div class="category_item">
                  <div class="card custom-section-div">
                    <img
                      class="card-img"
                      :src="item.image"
                      alt="section image"
                    />
                    <div class="card-img-overlay">
                      <div class="row">
                        <div class="col-12">
                          <p class="card-title">{{ item.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </slide>
          </carousel>
        </div>
      </div>
      <div v-else class="row">
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["sections"],
  data() {
    return {
      options: {
        loop: true,
        perPage: 1,
        autoplay: true,
        autoplayTimeout: 3000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        navigationEnabled: false,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        perPageCustom: [
          [0, 1],
          [480, 1],
          [768, 3],
          [1000, 3],
          [1200, 4],
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.categories_sec {
  padding: 35px 30px;
  @media(max-width: 767px){
    padding: 10px  
  }
  .category_item {
    margin: 15px 7px 0;
  }
}
.section_title {
  p {
    margin-bottom: 40px !important;
  }
}
.custom-section-div {
  color: #fff;
  border: none;
  overflow: hidden;
}

.custom-section-div .card-img {
  width: 100%;
  height: 330px;
  object-fit: cover;
}

.custom-section-div .card-img-overlay {
  top: auto;
  z-index: 9;
}

.custom-section-div .card-img-overlay p {
  font-size: 20px;
}

.custom-section-div img {
  transition: transform 0.5s; /* Animation */
  height: 250px;
}

.custom-section-div:hover img {
  transform: scale(1.25);
}

.custom-section-div:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0,
    rgba(17, 17, 17, 0.75) 100%
  );
}

.custom-section-div:hover:after {
  transition: 0.5s ease;
  background: linear-gradient(
    180deg,
    rgba(17, 17, 17, 0.25) 0,
    rgba(17, 17, 17, 0.75) 100%
  );
}

</style>
