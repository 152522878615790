<template>
  <div class="edit_profile">
    <h3>{{ $t('email-edit') }}</h3>
    <hr />
    <div class="edit_profile_from">
      <v-row>
        <v-col cols="12" md="12">
          <div
            class="d-flex justify-content-start align-items-start flex-column w-100 form"
            style="gap: 10px; height: 90px"
          >
            <span> {{ $t('auth.email') }} </span>
            <v-text-field
              v-model="GetDataProfile.email"
              required
              outlined
              class="w-100"
              :error-messages="emailErrors"
              @input="$v.GetDataProfile.email.$touch()"
              @blur="$v.GetDataProfile.email.$touch()"
            ></v-text-field>
          </div>
        </v-col>
        <hr style="margin: 10px 0 22px 0"/>
      </v-row>
      <div class="d-flex justify-content-end py-3">
        <v-btn
          class="save"
          depressed
          color="#000"
          @click="handleEmail"
          :loading="loading"
          :disabled="isEmailMatchingLocalStorage"
        >
          {{ $t('save-edits') }}
        </v-btn>
      </div>
      <v-dialog
        v-model="verifyEmail"
        width="600px"
        class="modal_auth"
      >
        <v-card class="dialog_auth">
          <div
            class="dialog_title d-flex justify-content-center align-items-center flex-column"
          >
            <v-card-title class="font-weight-bold">
              {{ $t('email-edit') }}
            </v-card-title>
            <span @click="verifyEmail = false" class="mx-2 close_btn">
              <v-icon color="#000"> mdi-close </v-icon>
            </span>
            <v-card-text color="#6c757d">
              {{ $t("auth.addverify") }}
            </v-card-text>
            <hr class="m-0"/>
          </div>
          <form style="margin-top: 48px !important">
            <v-container>
              <div>
                <p class="d-flex justify-start items-start">
                  {{ $t("auth.verify") }}
                </p>
                <v-text-field
                  v-model="verify"
                  :placeholder="$t('auth.add-verify')"
                  required
                  outlined
                ></v-text-field>
              </div>
              <div class="flex justift-start items-start mt-5 mb-5">
                <v-btn
                  class="submit"
                  :loading="loading"
                  color="#000"
                  @click="handleVerifyEmail"
                >
                  {{ $t("auth.submit") }}
                </v-btn>
              </div>
            </v-container>
          </form>
          <v-spacer></v-spacer>
          <div
            class="mb-3 p-3 d-flex justify-content-center align-items-center flex-column Ques_auth"
          >
            <hr class="mb-5"/>
            <p>
              {{ $t("auth.problem") }}
              <router-link to="/contact-us"> {{ $t("auth.contactUs") }} </router-link>
            </p>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";

export default {
  mixins: [validationMixin],
  validations: {
    GetDataProfile: {
      email: { required, email },
    },
  },
  data() {
    return {
      verifyEmail: false,
      verify: "",
      loading: false,
    };
  },
  computed: {
    isEmailMatchingLocalStorage() {
      // Retrieve the value from local storage
      const localStorageMobile = localStorage.getItem("getStoredEmail");

      // Check if the local storage value exists and is equal to the v-model value
      return localStorageMobile && localStorageMobile === this.GetDataProfile.email;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.GetDataProfile.email.$dirty) return errors;
      !this.$v.GetDataProfile.email.email &&
        errors.push(this.$t("errors.email"));
      !this.$v.GetDataProfile.email.required &&
        errors.push(this.$t("errors.emailReq"));
      return errors;
    },
    VerifyError() {
      const errors = [];
      if (!this.$v.verify.$dirty) return errors;
      !this.$v.verify.required && errors.push(this.$t("errors.checkboxReq"));
      return errors;
    },
    ...mapGetters([
      "GetDataProfile",
      "getMessageEmail",
      "getErrorEmail",
      "GetVerifyEmail",
    ]),
    PAYLOADEMAIL() {
      const PAYLOAD = {
        email: this.GetDataProfile.email,
      };
      localStorage.setItem("editEmail", this.GetDataProfile.email);
      return {...PAYLOAD};
    },
    PAYLOADVERIFYEMAIL() {
      const editEmail = localStorage.getItem("editEmail");
      const PAYLOAD = {
        code: this.verify,
        email: editEmail,
      };
      return {...PAYLOAD};
    },
  },
  created() {
    this.handleGetDataProfile();
  },
  mounted() {
    if (this.GetDataProfile !== null && typeof this.GetDataProfile !== 'undefined') {
      localStorage.setItem("getStoredEmail", this.GetDataProfile.email);
    }
  },
  methods: {
    ...mapActions(["handleGetDataProfile"]),
    handleEmail() {
      if (this.isEmailMatchingLocalStorage) {
        return
      }
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      this.$store
        .dispatch("changeEmail", this.PAYLOADEMAIL)
        .then((res) => {
          this.loading = false;
          this.verifyEmail = true;
          this.TriggerNotify("success", this.getMessageEmail);
          localStorage.removeItem("getStoredEmail")
        })
        .catch((err) => {
          this.loading = false;
          this.TriggerNotify("error", err.response.data.data.email);
          // console.log(err)
        });
    },
    handleVerifyEmail() {
      if(this.verify == ''){
        return
      }
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      this.$store
        .dispatch("verifyEmail", this.PAYLOADVERIFYEMAIL)
        .then((res) => {
          this.loading = false;
          this.verifyEmail = false;
          this.TriggerNotify("success", this.GetVerifyEmail);
        })
        .catch((err) => {
          this.loading = false;
          this.TriggerNotify("error", err.response.data.message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  background-color: transparent !important;
}
.edit_profile {
  h3 {
    color: #4d4d4d;
    text-align: start;
    font-size: 20px;
    font-weight: bold;
  }
  .edit_profile_from {
    margin-top: 24px;
    hr {
      color: #e0e0e0;
    }
    p {
      font-size: 15px;
      color: #4d4d4d;
      margin-bottom: 5px !important;
    }
    .select_img {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      img {
        border-radius: 50%;
      }
      .input {
        position: relative;
        i {
          position: absolute;
          bottom: 8px;
          right: 99px;
          background: #fff;
          padding: 5px;
          border-radius: 50%;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        input {
          position: absolute;
          top: -40px;
          opacity: 0;
        }
      }
      .avatar_info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        margin: 10px 0;
        span {
          color: #868e96;
          font-size: 15px;
        }
      }
    }
    .form {
      span {
        color: #000;
        font-size: 15px;
      }
    }
  }
  .save {
    color: #fff;
    margin-inline-start: auto;
    letter-spacing: 0;
    padding: 12px !important;
    height: 46px !important;
  }
}
.v-dialog {
  .v-card {
    background-color: #fff !important;
  }
}

.close_btn {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 5px 5px 3px 5px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 0;
}
</style>
