import axios from 'axios'

const state = {
    notifications: [],
    sidebar_notifications: [],
}
const mutations = {
    SET_ALL_NOTIFICATIONS(state, notifications) {
        state.notifications = notifications
    },
    SET_SIDEBAR_NOTIFICATIONS(state, notifications) {
        state.sidebar_notifications = notifications
    },
}
const actions = {
    async GetAllNotifications({commit}) {
        return axios.get("get-notifications").then(res => {
            commit("SET_ALL_NOTIFICATIONS", res.data.data.data)
        })
    },
    async GetSidebarNotifications({commit}) {
        return axios.get("get-sidebar-notifications").then(res => {
            commit("SET_SIDEBAR_NOTIFICATIONS", res.data.data.data)
        })
    },
}
const getters = {
    getAllNotifications: state => state.notifications,
    getSidebarNotifications: state => state.sidebar_notifications,
}
export default {
    state,
    mutations,
    actions,
    getters
}