<template>
  <v-dialog v-model="reviewDialog" width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="btn">
        <v-icon>mdi-star</v-icon>
        أضف تقييمك
      </v-btn>
    </template>

    <v-card class="studio_details_modal">
      <div
          class="d-flex justify-content-between align-items-center flex-row pt-3 px-2"
      >
        <span class="font-weight-bold"> تقييم العقار </span>
        <button @click="reviewDialog = false">
          <v-icon> mdi-close</v-icon>
        </button>
      </div>
      <v-divider></v-divider>
      <div
          class="p-2 d-flex justify-content-start align-items-start flex-row"
          style="gap: 8px"
      >
        <div>
          <img
              :src="getOrderStudioDeatils.studio.main_image_url"
              alt="studio"
              width="80"
              height="70"
          />
        </div>
        <div class="d-flex justify-content-start align-items-start flex-column">
          <h6>{{ getOrderStudioDeatils.studio.name }}</h6>
          <span> {{ getOrderStudioDeatils.studio.category_name }} </span>
          <div
              class="d-flex justify-content-start align-items-start flex-row"
              style="gap: 14px"
          >
            <v-rating
                v-model="getOrderStudioDeatils.studio.totalRates"
                background-color="#ccc"
                color="#FC7D12"
                dense
                hover
                readonly
                half-increments
                size="15"
            ></v-rating>
            <span>
              <v-icon small> mdi-map-marker-outline </v-icon
              >{{ getOrderStudioDeatils.studio.city_name }}
            </span>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div
          class="rate px-2 d-flex justify-content-between align-items-start flex-row"
      >
        <span class="font-weight-bold"> مستوى الخدمة </span>
        <v-rating
            v-model="rate"
            background-color="#ccc"
            color="#FC7D12"
            dense
            hover
            size="24"
            required
        ></v-rating>
      </div>
      <v-divider></v-divider>
      <span
          class="note px-2 text-sm d-flex justify-content-start align-items-start"
      >
        1 جودة سيئة، 5 جودة ممتازة
      </span>
      <v-divider></v-divider>
      <div
          class="mt-4 px-2 text-sm d-flex justify-content-start align-items-start flex-column"
      >
        <h6>أخبر اخرين عن رأيك</h6>
        <v-textarea v-model="comment" outlined class="w-100 mt-3" required></v-textarea>
      </div>
      <v-card-actions
          class="d-flex justify-content-end align-items-start flex-row px-3 actions"
          style="gap: 10px"
      >
        <v-btn color="#000" depressed @click="handleReview" :loading="loading"> أضف تقييمك</v-btn>
        <v-btn color="#E0E0E0" @click="reviewDialog = false" depressed>
          تراجع
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: ['getOrderStudioDeatils'],
  data() {
    return {
      reviewDialog: false,
      rate: "",
      comment: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getReviewData']),
    reviewLoad() {
      const payload = {
        studio_id: this.getOrderStudioDeatils.studio.id,
        order_id: this.getOrderStudioDeatils.id,
        rate: this.rate,
        comment: this.comment
      };
      return {...payload};
    }
  },
  methods: {
    handleReview() {
      this.loading = true;
      this.$store.dispatch('ReviewStudio', this.reviewLoad).then(() => {
        this.TriggerNotify("success", this.getReviewData);
        this.loading = false;
        this.reviewDialog = false;
        location.reload();
      }).catch((err) => {
        this.loading = false;
        this.TriggerNotify("error", err.response.data.message);
      })
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  background-color: #e0e0e0 !important;
  box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
  border-radius: 4px;
  color: #4d4d4d;
  font-size: 0.875rem;
  height: 44px !important;
  line-height: 44px;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;

  i {
    margin-inline-end: 5px;
    color: #4d4d4d !important;
  }
}

.actions {
  button {
    letter-spacing: 0;

    &:first-of-type {
      color: #fff;
    }
  }
}

hr {
  padding: 0;
  margin: 8px;
  opacity: 0.5;
}

.studio_details_modal {
  img {
    border-radius: 8px;
    object-fit: cover;
  }

  h6 {
    color: #000;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .note {
    color: #868e96;
    font-size: 13px;
  }

  fieldset {
    border-color: #e0e0e0 !important;
  }
}
</style>
