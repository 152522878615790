<template>
  <div class="edit_profile">
    <h3>{{ $t('mobile-edit') }}</h3>
    <hr/>
    <div class="edit_profile_from">
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-start mt-6">
            <h6 class="pb-0 mb-3"> {{ $t('auth.country') }} </h6>
            <v-select class="country-select" :items="countries" v-model="GetDataProfile.country_id" item-value="id"
                      :label="$t('auth.choose-country')" :error-messages="countryIdErrors">
              <template v-slot:selection="{ item, index }">
                <img class="country-img" :src="item.flag_path" :alt="item.name"/>
                &nbsp;(+{{ item.code }})&nbsp;{{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                <img class="country-img" :src="item.flag_path" :alt="item.name"/>
                &nbsp;(+{{ item.code }})&nbsp;{{ item.name }}
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="d-flex justify-content-start align-items-start flex-column w-100 form relative mobile"
               style="gap: 10px; height: 90px">
            <span> {{ $t('auth.phone') }} </span>
            <!--            <div class="saudi-num">-->
            <!--              <span> 966+ </span>-->
            <!--              <img src="../../../public/img/ksa.webp" alt="ksa" width="17" height="17"/>-->
            <!--            </div>-->
            <v-text-field dir="ltr" v-model="GetDataProfile.mobile" required outlined class="w-100"
                          :error-messages="PhoneErrors" @blur="$v.GetDataProfile.mobile.$touch()"></v-text-field>
          </div>
        </v-col>
        <hr style="margin: 10px 0 22px 0"/>
      </v-row>
      <div class="d-flex justify-content-end py-3">
        <v-btn class="save" depressed color="#000" @click="handlePhone" :loading="loading"
               :disabled="isMobileMatchingLocalStorage && isCountryIdMatchingLocalStorage">
          {{ $t('save-edits') }}
        </v-btn>
      </div>
      <v-dialog v-model="verifyPhone" width="600px" class="modal_auth">
        <v-card class="dialog_auth">
          <div class="dialog_title d-flex justify-content-center align-items-center flex-column">
            <v-card-title class="font-weight-bold">
              {{ $t('mobile-edit') }}
            </v-card-title>
            <v-card-text v-if="ifSendWhatsapp" color="#6c757d">{{ $t('auth.add-verify-whatsapp') }}</v-card-text>
            <v-card-text v-else color="#6c757d">{{ $t('auth.addverifyMoile') }}</v-card-text>
            <hr class="m-0"/>
          </div>
          <form style="margin-top: 48px !important">
            <v-container>
              <div>
                <p class="d-flex justify-start items-start">
                  {{ $t("auth.verify") }}
                </p>
                <v-text-field v-model="verify" :placeholder="$t('auth.add-verify')" required outlined></v-text-field>
              </div>
              <div class="flex justift-start items-start mt-5 mb-5">
                <v-btn class="submit" :loading="loading" color="#000" @click="handleVerifyPhone">
                  {{ $t("auth.submit") }}
                </v-btn>
              </div>
            </v-container>
          </form>
          <v-spacer></v-spacer>
          <div class="mb-3 p-3 d-flex justify-content-center align-items-center flex-column Ques_auth">
            <hr class="mb-5"/>
            <p>
              {{ $t("auth.problem") }}
              <router-link to="/contact-us">
                {{ $t("auth.contactUs") }}
              </router-link>
            </p>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  props: ["GetDataProfile"],
  mixins: [validationMixin],
  validations: {
    GetDataProfile: {
      country_id: {
        required,
      },
      mobile: {
        required,
        numeric,
      },
    },
  },
  data() {
    return {
      verifyPhone: false,
      ifSendWhatsapp: false,
      loading: false,
      verify: "",
      formSubmitted: false,
    };
  },
  computed: {
    isMobileMatchingLocalStorage() {
      const localStorageMobile = localStorage.getItem("getStoredMobile");
      // Check if the local storage value exists and is equal to the v-model value
      return localStorageMobile && (localStorageMobile === this.GetDataProfile.mobile);
    },
    isCountryIdMatchingLocalStorage() {
      const localStorageCountryId = localStorage.getItem("getStoredCountryId");
      // Check if the local storage value exists and is equal to the v-model value
      return localStorageCountryId && (parseInt(localStorageCountryId) === this.GetDataProfile.country_id);
    },

    PhoneErrors() {
      const errors = [];
      if (!this.$v.GetDataProfile.mobile.$dirty) return errors;
      !this.$v.GetDataProfile.mobile.required && errors.push(this.$t("errors.enter-valid-number"));
      !this.$v.GetDataProfile.mobile.numeric && errors.push(this.$t("errors.enter-valid-number"));
      return errors;
    },
    countryIdErrors() {
      const errors = [];
      if (!this.$v.GetDataProfile.country_id.$dirty) return errors;
      !this.$v.GetDataProfile.country_id.required && errors.push(this.$t("errors.checkboxReq"));
      return errors;
    },

    ...mapGetters("home", ["getCountries"]),
    ...mapGetters(["GetSmsType2", "getMessagePhone", "getErrorPhone", "GetVerifyEmail"]),

    countries() {
      return this.getCountries;
    },

    PAYLOADPHONE() {
      const PAYLOAD = {
        country_id: this.GetDataProfile.country_id,
        mobile: this.GetDataProfile.mobile,
      };
      localStorage.setItem("editCountryId", PAYLOAD.country_id);
      localStorage.setItem("editPhone", PAYLOAD.mobile);
      return {...PAYLOAD};
    },
    PAYLOADVERIFYPHONE() {
      const PAYLOAD = {
        code: this.verify,
        country_id: localStorage.getItem("editCountryId"),
        mobile: localStorage.getItem("editPhone"),
      };
      return {...PAYLOAD};
    },
  },
  mounted() {
    if (this.GetDataProfile !== null && typeof this.GetDataProfile !== 'undefined') {
      localStorage.setItem("getStoredMobile", this.GetDataProfile.mobile);
      localStorage.setItem("getStoredCountryId", this.GetDataProfile.country_id);
    }
  },
  methods: {
    handlePhone() {
      if (this.isMobileMatchingLocalStorage && this.isCountryIdMatchingLocalStorage) {
        return
      }
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      this.$store
          .dispatch("changePhone", this.PAYLOADPHONE)
          .then(() => {
            this.loading = false;
            this.verifyPhone = true;
            this.ifSendWhatsapp = (this.GetSmsType2 === 'whatsapp');

            this.TriggerNotify("success", this.getMessageProfile);
            localStorage.removeItem("getStoredMobile")
            localStorage.removeItem("getStoredCountryId")
          })
          .catch((err) => {
            this.loading = false;
            this.TriggerNotify("error", err.response.data.data.country_id);
            this.TriggerNotify("error", err.response.data.data.mobile);
          });
    },
    handleVerifyPhone() {
      if (this.verify === "") {
        return
      }
      this.loading = true;
      this.$store
          .dispatch("verifyPhone", this.PAYLOADVERIFYPHONE)
          .then((res) => {
            this.verifyPhone = false;
            this.loading = false;
            this.TriggerNotify("success", this.GetVerifyEmail);
          })
          .catch((err) => {
            this.loading = false;
            this.TriggerNotify("error", err.response.data.message);
          });
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  background-color: transparent !important;
}

.edit_profile {
  h3 {
    color: #4d4d4d;
    text-align: start;
    font-size: 20px;
    font-weight: bold;
  }

  .edit_profile_from {
    hr {
      color: #e0e0e0;
    }

    p {
      font-size: 15px;
      color: #4d4d4d;
      margin-bottom: 5px !important;
    }

    .select_img {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      img {
        border-radius: 50%;
      }

      .input {
        position: relative;

        i {
          position: absolute;
          bottom: 8px;
          right: 99px;
          background: #fff;
          padding: 5px;
          border-radius: 50%;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        input {
          position: absolute;
          top: -40px;
          opacity: 0;
        }
      }

      .avatar_info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        margin: 10px 0;

        span {
          color: #868e96;
          font-size: 15px;
        }
      }
    }

    .form {
      span {
        color: #000;
        font-size: 15px;
      }
    }
  }

  .save {
    color: #fff;
    margin-inline-start: auto;
    letter-spacing: 0;
    padding: 12px !important;
    height: 46px !important;
  }

  .saudi-num {
    position: absolute;
    top: 115px;
    left: 30px;
    z-index: 9;
    background: #fafafa;
    padding: 6px 22px;
    border-radius: 5px;

    span {
      margin-inline-end: 5px;
    }
  }
}

.v-dialog {
  .v-card {
    background-color: #fff !important;
  }
}

.close_btn {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 5px 5px 3px 5px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 0;
}

.mobile {
  margin-top: 24px;
}
</style>
