import axios from 'axios'

const state = {
    projects: [],
    cities: [],
    servcies: [],
    currentPage: 1,
    totalProjects: 0,
    last_page: "",
}
const mutations = {
    SET_ALL_PROJECTS(state, newData) {
        let dataToUpdate = [];
        if (Array.isArray(newData)) {
            dataToUpdate = newData;
        }
        if (state.currentPage === 1) {
            state.projects = dataToUpdate;
        } else {
            state.projects = [...state.projects, ...dataToUpdate];
        }
    },
    SET_ALL_CITIES(state, cities) {
        state.cities = cities
    },
    SET_ALL_SERVICES(state, servcies) {
        state.servcies = servcies
    },
    SET_CURRENT_PAGE(state, currentPage) {
        state.currentPage = currentPage
    },
    SET_TOTAL_PROJECTS(state, totalProjects) {
        state.totalProjects = totalProjects
    },
    SET_LAST_PAGE(state, last_page) {
        state.last_page = last_page
    },
    incrementCurrentPage(state) {
        state.currentPage++;
    },
}
const actions = {
    async GetAllProjects({ commit }, params) {
        // const mergedParams = Object.assign({}, params, { page: state.currentPage });
        return axios.get("projects", { params }).then(res => {
            commit("SET_ALL_PROJECTS", res.data.data.data)
            commit("SET_ALL_CITIES", res.data.additional.cities)
            commit("SET_ALL_SERVICES", res.data.additional.sections)
            commit("SET_CURRENT_PAGE", res.data.data.pagination.currentPage)
            commit("SET_LAST_PAGE", res.data.data.pagination.last_page)
            commit("SET_TOTAL_PROJECTS", res.data.data.pagination.total)
        })
    },
    async loadMoreProjects({ commit }) {
        commit("incrementCurrentPage");
    },
}
const getters = {
    getAllProjects: state => state.projects,
    getAllCities: state => state.cities,
    getAllServies: state => state.servcies,
    getCurrentPage: state => state.currentPage,
    getTotalProjects: state => state.totalProjects,
    getLastPage: state => state.last_page,
}
export default {
    state,
    mutations,
    actions,
    getters
}