<template>
  <div>
    <!-- Search Engine -->
    <meta name="robots" content="index, follow"/>
    <meta name="image" :content="seo_settings.image">
    <meta name="keywords" :content="seo_settings.keywords"/>
    <meta name="description" :content="seo_settings.description">
    <meta name="author" :content="seo_settings.name">
    <!-- Schema.org markup for Google+ -->
    <meta itemprop="name" :content="seo_settings.name">
    <meta itemprop="description" :content="seo_settings.description">
    <meta itemprop="image" :content="seo_settings.image">
    <!-- Twitter Card data -->
    <meta name="twitter:card" content="seenpark">
    <meta name="twitter:site" content="@seenpark">
    <meta name="twitter:creator" content="@seenpark">
    <meta name="twitter:title" :content="seo_settings.name">
    <meta name="twitter:description" :content="seo_settings.description">
    <meta name="twitter:image" :content="seo_settings.image">
    <!-- Open Graph data -->
    <meta property="og:title" :content="seo_settings.name"/>
    <meta property="og:description" :content="seo_settings.description"/>
    <meta property="og:site_name" :content="seo_settings.name"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://seenpark.sa"/>
    <meta property="og:image" :content="seo_settings.image"/>
    <title v-text="seo_settings.name"></title>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      seo_settings: {
        name: "منصة سين بارك",
        description: "منصة سين بارك توفر عقارات منوعه للإيجار مثل عقار تصوير، عقارات مكتبيه، مطاعم ومنازل وغيرها للإيجار اليومي.",
        keywords: "إيجار، تأجير، معدات تصوير، عقارات تصوير، إيجار يومي، تصوير",
        image: "https://seenpark.sa/img/whatsapp-sin-logo.png",
      }
    }
  },
  computed: {
    ...mapGetters("home", ['getSeoSettings'])
  },
  async created() {
    await this.setSeoSettings();
  },
  methods: {
    setSeoSettings() {
      setTimeout(() => {
        this.seo_settings = this.getSeoSettings;
      }, 2000);
    }
  }
};
</script>
