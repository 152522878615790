import axios from 'axios'
const state = {
    data: null
};

const mutations = {
    SET_ADD_PROJECT(state, data) {
        state.data = data
    }
}

const actions = {
    async addProjectStore({ commit }, payload) {
        return axios.post('add-project', payload).then(res => {
            commit('SET_ADD_PROJECT', res.data.message)
        })
    }
};

const getters = {
    getMessagePro: state => state.data
}


export default {
    actions,
    state,
    mutations,
    getters
};