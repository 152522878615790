<template>
  <div class="filter-side  mt-5">
    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="filter_title">
          {{ $t('type') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="filter_fixed">
          <v-radio-group mandatory v-model="filter.type" @change="handleFavsList"> 
            <div
              class="filter_item"
              v-for="(type, index) in typeFav"
              :key="index"
            >
              <v-radio color="#0d6efd" :label="type.name" :value="type.value">
              </v-radio>
            </div>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panel: [0],
      filter: {
        type: "",
      },
      typeFav: [
        // {
        //   id: 1,
        //   name: "مشاريعي",
        //   value: "projects",
        // },
        // {
        //   id: 2,
        //   name: "سيني ستوك",
        //   value: "cine_stocks",
        // },
        // {
        //   id: 1,
        //   name: "سيني أكاديمي",
        //   value: "cine_academies",
        // },
        {
          id: 1,
          name: this.$t('Breadcrumb.stdios'),
          value: "studios",
        },
        // {
        //   id: 1,
        //   name: "سوق المحترفين",
        //   value: "professional_markets",
        // },
      ],
    };
  },
  computed:{
    filterFavPayload(){
      const payload = {
        type: this.filter.type
      }
      return {...payload}
    }
  },
  mounted(){
    // this.handleFavsList()
  },
  methods:{
    handleFavsList(){
      this.$store.dispatch('GetFavsList' , this.filterFavPayload)
    }
  }
};
</script>

<style></style>
