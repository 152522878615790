<template>
    <section class="profile_section">
        <v-container>
            <div class="__bread mb-5">
                <v-breadcrumbs :items="Bread"></v-breadcrumbs>
            </div>
            <Profile />
        </v-container>
    </section>
</template>

<script>
import axios from "axios";
import Profile from "@/components/profile/index.vue";
export default {
  components: {
    Profile
  },
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t("my-profile"),
          disabled: true,
          to: "",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.profile_section {
  background-color: #fafafa;
  padding: 0 0;
  height: 100%;
  @media (max-width: 991px) {
    padding: 70px 0;
  }
  .__bread {
  box-shadow: none !important;
  .v-breadcrumbs {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none !important;
    padding: 12px;
  }
  .v-breadcrumbs__item {
    color: #000 !important;
  }
  .v-breadcrumbs__item--disabled {
    color: #868e96 !important;
  }
}
}
</style>