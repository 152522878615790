<template>
  <Favorite />
</template>

<script>
import Favorite from "../../components/favourite/index.vue"
export default {
    components:{
        Favorite
    }
}
</script>

<style>

</style>