<template>
  <section class="studios_list pb-5">
    <!-- pinned space -->
    <transition name="fade">
      <div class="cine_academies_lists mt-5" v-if="pinnedSpace">
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <router-link :to="{ name: 'studiosDetails', params: { id: pinnedSpace.id } }">
              <div class="pinned-space">
                <div class="studios_lists__item">
                  <div class="studios_lists__item__img">
                    <img :src="pinnedSpace.image_500_url" alt=""/>
                  </div>
                  <div class="studios_rate d-flex justify-content-center align-start gap-1"
                       v-if="pinnedSpace.totalRates > 0">
                    <i class="mdi mdi-star mdi-18px yellow--text"></i>
                    <p>{{ pinnedSpace.totalRates }}</p>
                  </div>
                  <div class="studios_info d-flex justify-content-start text-start flex-column flex-wrap">
                    <span class="title_ text-limit"> {{ pinnedSpace.name }} </span>
                    <div class="d-flex flex-column">
                  <span class="catg__ text-limit">
                    <span class="catg__">{{ pinnedSpace.city_name }}</span>
                    &nbsp;
                    <span class="catg__" v-if="pinnedSpace.district_name !== ''">
                      - {{ pinnedSpace.district_name }}
                    </span>
                    &nbsp;
                    <span class="catg__">
                      - {{ pinnedSpace.category_name }}
                    </span>
                  </span>
                      <p class="price__ text-limit">
                        {{ pinnedSpace.rent_price }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </v-col>
        </v-row>
      </div>
    </transition>

    <!-- show spaces -->
    <div class="cine_academies_lists mt-5" v-if="studiosList.length > 0">
      <v-row :class="showMap ? 'scroll-div' : ''">
        <v-col :class="showMap ? 'col-12 col-sm-6 col-md-12' : 'col-12 col-sm-6 col-md-4'"
               v-for="(list, index) in studiosList" :key="index">
          <router-link :to="{ name: 'studiosDetails', params: { id: list.id } }">
            <div class="studios_lists__item">
              <div class="studios_lists__item__img">
                <img :src="list.image_500_url" alt=""/>
              </div>
              <div class="studios_rate d-flex justify-content-center align-start gap-1" v-if="list.totalRates > 0">
                <i class="mdi mdi-star mdi-18px yellow--text"></i>
                <p>{{ list.totalRates }}</p>
              </div>
              <div class="show-offer" v-if="list.offer !== ''">{{ list.offer }}</div>
              <div class="studios_info d-flex justify-content-start text-start flex-column flex-wrap">
                <span class="title_ text-limit"> {{ list.name }} </span>
                <div class="d-flex flex-column">
                  <span class="catg__ text-limit">
                    <span class="catg__">{{ list.city_name }}</span>
                    &nbsp;
                    <span class="catg__" v-if="list.district_name !== ''">
                      - {{ list.district_name }}
                    </span>
                    &nbsp;
                    <span class="catg__">
                      - {{ list.category_name }}
                    </span>
                  </span>
                  <p class="price__ text-limit">
                    {{ list.rent_price }}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </div>
    <div v-if="studiosList.length === 0 && !loadingData">
      <NoData/>
    </div>
    <div v-if="loadingData">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import firebase from "firebase/app";
import "firebase/messaging";
import NoData from "@/views/no-data.vue";

export default {
  props: ["showMap", "pinnedSpace", "studiosList", "loadingData"],
  components: {
    NoData,
  },
  data() {
    return {
      attrs: {
        class: "mb-2",
        boilerplate: true,
      },
    };
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.v-card__title {
  text-align: start !important;
  color: #000 !important;
  padding: 12px 10px 0 10px !important;
  font-size: 19px !important;
  font-weight: 500 !important;
}

._subtitle {
  text-align: start !important;
  width: 100% !important;
  display: block !important;
  padding: 0 10px 20px 10px !important;
  color: #868e96;
  font-size: 16px;
}

.v-card {
  margin-bottom: 15px;
  transition: 0.3s ease-in-out all;

  .type_cine {
    background: #00000080 0 0 no-repeat padding-box;
    border-radius: 2px;
    padding: 4px 8px;
    display: inline-flex;
    position: absolute;
    top: 10px;
    left: 15px;

    span {
      color: #fff;
    }

    i {
      color: #fff;
    }
  }
}

.studios_lists__item {
  position: relative;
  border-radius: 10px;
  height: 250px;

  .studios_lists__item__img {
    border-radius: 6px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent linear-gradient(180deg, #ffffff00 0, #000000 100%) 0 0 no-repeat padding-box;
      border-radius: 6px;
    }

    img {
      width: 100%;
      border-radius: 10px;
      height: 250px;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .studios_rate {
    position: absolute;
    left: 15px;
    top: 10px;
    background: #00000080 0 0 no-repeat padding-box;
    border-radius: 2px;
    padding: 2px 4px 2px 5px;

    p {
      margin-bottom: 0 !important;
      margin-top: 2px;
      color: #fff;
    }
  }

  .studios_info {
    position: absolute;
    bottom: 0;
    padding: 15px;
    right: 0;
    width: 100%;

    span {
      color: #fff;
    }

    .date_ {
      color: #e0e0e0;
      font-size: 12px;

      i {
        color: #e0e0e0;
        font-size: 14px;
      }
    }

    .title_ {
      font-weight: 600;
      margin: 4px 0 0 0;
      font-size: 17px;
    }

    .catg__ {
      font-size: 15px;
      color: #6c757d !important;
    }

    .price__ {
      color: #fff;
      font-weight: bold;
      padding: 5px;
      border-radius: 4px;
    }

    .text-limit {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.pinned-space {
  border-left: 3px solid #111;
  padding-left: 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.scroll-div {
  overflow-y: scroll;
  height: 72vh;
}

.show-offer {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #09BC86;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
}
</style>
