<template>
  <div>
    <div class="filter_projects">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-sm-6 col-lg-auto">
          <label>{{ $t('choose-city') }}</label>
          <v-select :items="cities"
                    :item-text="(sec) => sec.name"
                    :item-value="(sec) => sec.id"
                    v-model="filter.citiesVal"
                    filled solo rounded :label="$t('filterProject.city')" prepend-inner-slot
                    no-data-text="لا يوجد بيانات">
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-auto">
          <label>{{ $t('choose-category') }}</label>
          <v-select :items="categories"
                    :item-text="(sec) => sec.name"
                    :item-value="(sec) => sec.id"
                    v-model="filter.categoriesVal"
                    filled solo rounded :label="$t('filterProject.category')" prepend-inner-slot
                    no-data-text="لا يوجد بيانات">
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-auto">
          <label>{{ $t('choose-date2') }}</label>
          <v-menu :close-on-content-click="true" :nudge-right="0" transition="scale-transition"
                  offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filter.dateVal" :placeholder="$t('choose-date2')"
                            readonly filled solo rounded v-bind="attrs"
                            v-on="on" style="border: none;">
                <template #prepend-inner>
                  <v-icon color="#4D4D4D" style="margin-inline-end: 10px;">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker :locale="lang" v-model="filter.dateVal"
                           :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="col-12 col-sm-6 col-lg-auto">
          <button class="__search_project" :loading="loading" @click="search">
            {{ $t("filterProject.search") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "filter-space",
  data: () => ({
    lang: localStorage.getItem('lang') || 'ar',
    cities: [],
    categories: [],
    filter: {
      citiesVal: [],
      categoriesVal: [],
      dateVal: '',
    },
    loading: false,
  }),
  created() {
    // fetch cities
    axios.get("cities", {})
        .then(({data}) => {
          this.cities = data.data;
        });
    axios.get("categories", {})
        .then(({data}) => {
          this.categories = data.data;
        });
  },
  methods: {
    search() {
      this.$router.push({
        name: 'studios',
        query: {
          cityId: this.filter.citiesVal,
          category_id: this.filter.categoriesVal,
          add_date: this.filter.dateVal
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.filter_projects {
  margin: 0 auto;
  border-radius: 100px;
  border: 0.5px solid #ACACAC;
  background: #FFF;
  backdrop-filter: blur(11px);
  opacity: 1;
  -webkit-backdrop-filter: blur(30px);
  padding: 35px 24px 10px 24px;
  width: 80%;
  @media (max-width: 1263px) {
    width: 100%;
    padding: 24px;
    border-radius: 45px;
  }

  .row label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }
}

.__search_project {
  background: #000000 0 0 no-repeat padding-box;
  box-shadow: 0 0 1px #1D77B129;
  border-radius: 50px;
  opacity: 1;
  color: #fff;
  line-height: 16px;
  height: 50px;
  padding: 12px 24px;
  margin-inline-start: 1px;
  font-family: "Tajawal", sans-serif;
  width: 100%;
  margin-top: 5px;
}

select {
  font-weight: 300;
}

.v-label {
  top: 15px !important;
}
</style>
