<template>
        <div
        class="orders_buttons d-flex justify-content-start align-start flex-row flex-wrap gap-3"
      >
        <v-btn> <v-icon> mdi-lifebuoy </v-icon> الدعم </v-btn>
        <editProject />
        <v-btn>
          <v-icon> mdi-account-group </v-icon> افتح المشروع للجميع
        </v-btn>
        <v-btn>
          <v-icon> mdi-lock-open-variant </v-icon> إعادة المشروع للجميع
        </v-btn>
        <v-btn> <v-icon> mdi-lock </v-icon> اغلاق المشروع </v-btn>
        <v-btn>
          <v-icon> mdi-chat-processing-outline </v-icon> محادثة المحترف
        </v-btn>
        <v-btn> <v-icon> mdi-plus </v-icon> مشروع مماثل </v-btn>
      </div>
</template>

<script>
import editProject from './editProject.vue'
export default {
  components:{
    editProject
  }
}
</script>

<style scoped lang="scss">
.order_details {
  .order_title {
    h3 {
      color: #4d4d4d;
      font-size: 22px;
      font-weight: bold;
    }
    span {
      color: #6c757d !important;
    }
  }
}
.orders_buttons {
  button {
    background-color: #e0e0e0 !important;
    box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
    border-radius: 4px;
    color: #4d4d4d;
    font-size: 0.875rem;
    height: 44px !important;
    line-height: 44px;
    letter-spacing: 0;
    font-size: 16px;
    i {
      margin-inline-end: 5px;
    }
  }
}
.order_details,
.order_info,
.order_files,
.order_offers_item {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #e8e9ea;
  border-radius: 4px;
  padding: 24px;
  font-size: 14px;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-color: #e8e9ea !important;
  h3 {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 21px;
  }
  p {
    text-align: start;
    line-height: 1.8;
    font-size: 16px;
  }
  .user_img {
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
  }
  .user_info {
    span {
      color: #212529 !important;
    }
  }
  .rating_location {
    .lcoation__,
    i {
      color: #6c757d !important;
      font-size: 14px;
    }
  }
}
.order_files {
  .media-type {
    background: #2386c8 0 0 no-repeat padding-box;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    padding: 2px 4px;
  }
  .media-name {
    color: #2386c8;
  }
  .media-size {
    color: #868e96;
    font-size: 12px;
  }
}
.offers_upcoming {
  h3 {
    text-align: start;
    color: #4d4d4d;
    font-weight: bold;
    font-size: 21px;
  }
  .offer-price {
    background: #e0e0e0 0 0 no-repeat padding-box;
    box-shadow: 0 0 1px #1d77b129;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: bold;
  }
  .offer-note {
    font-size: 11px;
    color: #868e96;
    display: block;
  }
}
.order_details_sec {
  background-color: #fafafa;
  padding: 30px 0;
}
</style>
