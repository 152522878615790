<template>
  <section class="studios_page">
    <v-container>
      <v-row class="mt-16 mt-md-8 pt-12 pt-md-0 mb-8">
        <!-- filters -->
        <v-col class="col-12 col-md-12 col-lg-12">
          <div class="filter-side">
            <div class="row align-items-start text-center">
              <!-- filters -->
              <div class="col-12 col-lg">
                <div class="row mt-0">
                  <!-- search -->
                  <div class="col-12 col-sm-6 col-lg-4">
                    <div class="search__">
                      <v-text-field append-icon="mdi-magnify" filled solo class="Search_input"
                                    :label="$t('projects.search')"
                                    v-model="filter.search" clearable @input="getStudios"></v-text-field>
                    </div>
                  </div>
                  <!-- categories -->
                  <div class="col-12 col-sm-6 col-lg-4">
                    <div class="search__">
                      <v-select
                          :items="categories"
                          :item-text="(sec) => sec.name"
                          :item-value="(sec) => sec.id"
                          v-model="filter.categories"
                          multiple filled solo :label="$t('filterProject.category')" prepend-inner-slot
                          :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"
                          :no-data-text="$t('no-data')"
                          @change="getStudios">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                          ( + {{ filter.categories.length - 1 }} )
                        </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="CategoriesToggleCheckAll">
                            <v-list-item-action>
                              <v-icon>{{ categoriesIcon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t('choose-all') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- cities -->
                  <div class="col-12 col-sm-6 col-lg-4">
                    <div class="search__">
                      <v-select
                          :items="cities"
                          :item-text="(sec) => sec.name"
                          :item-value="(sec) => sec.id"
                          v-model="filter.cities"
                          multiple filled solo :label="$t('filterProject.city')" prepend-inner-slot
                          :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"
                          :no-data-text="$t('no-data')"
                          @change="getStudios">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                          ( + {{ filter.cities.length - 1 }} )
                        </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="CitiesToggleCheckAll">
                            <v-list-item-action>
                              <v-icon>{{ citiesIcon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t('choose-all') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- features -->
                  <div class="col-12 col-sm-6 col-lg-4">
                    <div class="search__">
                      <v-select
                          :items="features"
                          :item-text="(sec) => sec.name"
                          :item-value="(sec) => sec.id"
                          v-model="filter.features"
                          multiple filled solo :label="$t('features')" prepend-inner-slot
                          :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"
                          :no-data-text="$t('no-data')"
                          @change="getStudios">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                          ( + {{ filter.features.length - 1 }} )
                        </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="FeaturesToggleCheckAll">
                            <v-list-item-action>
                              <v-icon>{{ featuresIcon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t('choose-all') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- facilities -->
                  <div class="col-12 col-sm-6 col-lg-4">
                    <div class="search__">
                      <v-select
                          :items="facilities"
                          :item-text="(sec) => sec.name"
                          :item-value="(sec) => sec.id"
                          v-model="filter.facilities"
                          multiple filled solo :label="$t('facilities')" prepend-inner-slot
                          :menu-props="{closeOnClick: closeOnClick,closeOnContentClick: closeOnClick}"
                          :no-data-text="$t('no-data')"
                          @change="getStudios">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                          ( + {{ filter.facilities.length - 1 }} )
                        </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="FacilitiesToggleCheckAll">
                            <v-list-item-action>
                              <v-icon>{{ facilitiesIcon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t('choose-all') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- sort -->
                  <div class="col-12 col-sm-6 col-lg-4">
                    <v-select :placeholder="$t('projects.sortBy')"
                              required filled solo
                              v-model="sort"
                              :items="itemsSort"
                              :item-text="(sort) => sort.title"
                              :item-value="(sort) => sort.value"
                              @change="getStudios"
                    ></v-select>
                  </div>
                </div>
              </div>
              <!-- show map -->
              <div class="col-12 col-lg-auto">
                <button @click.prevent="handleShowMap" class="btn btn-light py-4 px-8">
                  <span v-if="!showMap"><i class="mdi mdi-map"></i>&nbsp;{{ $t('show-map') }}</span>
                  <span v-else><i class="mdi mdi-close"></i>&nbsp;{{ $t('hide-map') }}</span>
                </button>
              </div>
            </div>
          </div>
        </v-col>

        <!-- show spaces -->
        <v-col :class="showMap ? 'col-12 col-md-4 col-lg-3' : 'col-12 col-md-12 col-lg-12'">
          <!-- spaces count -->
          <div class="text-start" v-if="showMap">
            <h6>
              {{ $t('spaces-menu-map') }}
              &nbsp;
              <span class="badge bg-dark">{{ totalStudios }}</span>
            </h6>
          </div>

          <!-- show spaces -->
          <listStduios
              :showMap="showMap"
              :pinnedSpace="pinnedSpace"
              :studiosList="studiosList"
              :loadingData="loadingData"
          />
        </v-col>
        <!-- map -->
        <v-col class="col-12 col-md-8 col-lg-9" v-if="showMap && service">
          <transition name="fade">
            <div class="row">
              <div class="col-12">
                <div class="position-relative">
                  <!-- map -->
                  <GmapMap
                      :center="{lat:parseFloat(service.latitude), lng:parseFloat(service.longitude)}"
                      :zoom="12"
                      map-type-id="terrain"
                      style="width: 100%; height: 75vh;">
                    <gmap-marker
                        :key="studio.id"
                        v-for="studio in studiosList"
                        :position="{lat:parseFloat(studio.latitude), lng:parseFloat(studio.longitude)}">
                      <gmap-info-window>
                        <a href="javascript:void(0);" @click.prevent="pinnedSpace = studio;">
                          <p class="d-inline-flex align-items-center fw-bold">
                            <i class="mdi mdi-map-marker"></i>&nbsp;
                            {{ studio.rent_price }}&nbsp;
                            <span class="d-inline-flex align-items-center">
                        <i class="mdi mdi-star mdi-18px yellow--text"></i>
                        &nbsp;{{ studio.totalRates }}
                      </span>
                          </p>
                        </a>
                      </gmap-info-window>
                    </gmap-marker>
                  </GmapMap>

                  <!-- show more -->
                  <v-btn class="btn btn-light border-dark py-6 position-absolute" v-if="showMoreButton"
                         @click="loadMoreStudios"
                         style="top: 10px; right: calc(50% - 100px);">
                    <i class="mdi mdi-magnify mdi-18px"></i>&nbsp;{{ $t("search-in-this-area") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </transition>
        </v-col>
      </v-row>

      <!-- show more -->
      <div class="text-center">
        <v-btn class="show_more" color="#000000DE" v-if="!showMap && showMoreButton" @click="loadMoreStudios">
          {{ $t("display-more") }}
        </v-btn>
      </div>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import listStduios from "../../components/services/studios/list.vue";

export default {
  components: {
    listStduios,
  },
  data() {
    return {
      close: true,
      filter: {
        search: "",
        categories: [],
        cities: [],
        features: [],
        facilities: [],
        dates: "",
      },
      categories: [],
      cities: [],
      features: [],
      facilities: [],
      isEmptyFilters: false,
      showMoreButton: true,
      studiosList: [],
      last_page: "",
      currentPage: 1,
      loadingData: false,
      sort: "",
      itemsSort: [
        {
          title: this.$t("itemsSort.new"),
          value: "desc",
        },
        {
          title: this.$t("itemsSort.old"),
          value: "asc",
        }
      ],
      totalStudios: 0,
      closeOnClick: false,
      service: null,
      pinnedSpace: null,
      showMap: false,
    };
  },
  computed: {
    idcity() {
      return this.$route.query.cityId;
    },
    idCategory() {
      return this.$route.query.category_id;
    },
    checkAllCategories() {
      return this.filter.categories.length === this.categories.length
    },
    checkSomeCategories() {
      return this.filter.categories.length > 0 && !this.checkAllCategories
    },
    categoriesIcon() {
      if (this.checkAllCategories) return 'mdi-close-box'
      if (this.checkSomeCategories) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    checkAllCities() {
      return this.filter.cities.length === this.cities.length
    },
    checkSomeCities() {
      return this.filter.cities.length > 0 && !this.checkAllCities
    },
    citiesIcon() {
      if (this.checkAllCities) return 'mdi-close-box'
      if (this.checkSomeCities) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    checkAllFeatures() {
      return this.filter.features.length === this.features.length
    },
    checkSomeFeatures() {
      return this.filter.features.length > 0 && !this.checkAllFeatures
    },
    featuresIcon() {
      if (this.checkAllFeatures) return 'mdi-close-box'
      if (this.checkSomeFeatures) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    checkAllFacilities() {
      return this.filter.facilities.length === this.facilities.length
    },
    checkSomeFacilities() {
      return this.filter.facilities.length > 0 && !this.checkAllFacilities
    },
    facilitiesIcon() {
      if (this.checkAllFacilities) return 'mdi-close-box'
      if (this.checkSomeFacilities) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  created() {
    const params = new URLSearchParams(this.$route.query);
    if (params.has("cityId")) {
      const cityIdFromUrl = params.get("cityId");
      this.filter.cities = [parseInt(cityIdFromUrl, 10)];
    }
    if (params.has("category_id")) {
      const categoryIdFromUrl = params.get("category_id");
      this.filter.categories = [parseInt(categoryIdFromUrl, 10)];
    }
    if (params.has("citiesVal")) {
      this.filter.cities = params.get("citiesVal").split(",").map(Number);
    }
    if (params.has("categoriesVal")) {
      this.filter.categories = params.get("categoriesVal").split(",").map(Number);
    }
    if (params.has("add_date")) {
      this.filter.dates = params.get("add_date");
    }
  },
  mounted() {
    this.getStudios();

    setTimeout(() => {
      this.service.latitude += 1;
      this.service.longitude += 1;
    }, 1000);
  },
  methods: {
    async getStudios() {
      this.updateUrlWithoutParams();
      this.loadingData = true;

      const cityParams = {};
      this.filter.cities.forEach((cityId, index) => {
        cityParams[`city_id[${index}]`] = cityId || this.idcity;
      });

      const catgParams = {};
      this.filter.categories.forEach((category_id, index) => {
        catgParams[`category_id[${index}]`] = category_id || this.idCategory;
      });

      const params = {
        search_text: this.filter.search,
        radioInline: this.sort,
        add_date: this.filter.dates,
        page: this.currentPage,
        ...cityParams,
        ...catgParams,
        features: this.filter.features,
        facilities: this.filter.facilities,
      };

      axios.get("studios", {params})
          .then((res) => {
            this.loadingData = false;

            let newData = [];
            if (Array.isArray(res.data.data.data)) {
              newData = res.data.data.data;
            }

            this.categories = res.data.additional.categories;
            this.cities = res.data.additional.cities;
            this.features = res.data.additional.features;
            this.facilities = res.data.additional.facilities;
            this.service = res.data.additional.service;
            this.currentPage = res.data.data.pagination.currentPage;
            this.totalStudios = res.data.data.pagination.total;
            this.last_page = res.data.data.pagination.last_page;

            if (this.currentPage === 1) {
              this.studiosList = newData;
            } else {
              this.studiosList = [...this.studiosList, ...newData];
            }

            this.showMoreButton = this.currentPage < this.last_page;
            this.pinnedSpace = null;
          })
          .catch((e) => {
            this.loadingData = false;
          });
    },
    updateUrlWithoutParams() {
      const baseUrl = window.location.href.split("?")[0]; // Get the base URL
      history.pushState({}, "", baseUrl); // Update URL without query parameters
    },
    loadMoreStudios() {
      this.currentPage++;
      this.getStudios();
    },
    CategoriesToggleCheckAll() {
      this.$nextTick(() => {
        if (this.checkAllCategories) {
          this.filter.categories = [];
        } else {
          this.filter.categories = this.categories.map(a => a.id);
        }
        this.getStudios();
      });
    },
    CitiesToggleCheckAll() {
      this.$nextTick(() => {
        if (this.checkAllCities) {
          this.filter.cities = [];
        } else {
          this.filter.cities = this.cities.map(a => a.id);
        }
        this.getStudios();
      });
    },
    FeaturesToggleCheckAll() {
      this.$nextTick(() => {
        if (this.checkAllFeatures) {
          this.filter.features = [];
        } else {
          this.filter.features = this.features.map(a => a.id);
        }
        this.getStudios();
      });
    },
    FacilitiesToggleCheckAll() {
      this.$nextTick(() => {
        if (this.checkAllFacilities) {
          this.filter.facilities = [];
        } else {
          this.filter.facilities = this.facilities.map(a => a.id);
        }
        this.getStudios();
      });
    },
    handleShowMap() {
      this.showMap = !this.showMap;
      this.pinnedSpace = null;
    }
  },
};
</script>

<style scoped lang="scss">
.show_more {
  color: #fff !important;
}

.studios_page {
  background: #fff;
  height: 100%;
}
</style>
<style scoped lang="css">
.sort__ .v-menu__content.theme--light.menuable__content__active {
  z-index: 999 !important;
}

.sort__ .v-input {
  width: 200px !important;
}

.sort__ .v-select.v-text-field input {
  cursor: pointer !important;
}

.filter-side [class*="col-"] {
  padding: 0 12px !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #E0E0E0 !important;
}

.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: 6px;
}
</style>