<template>
  <section
    class="how-work"
    :style="{
      'background-image': 'url(' + homeSetting.how_work_cover_url + ')',
    }"
  >
    <div class="container">
      <div class="_title">
        <h1>{{ homeSetting.how_work_title }}</h1>
        <span v-html="homeSetting.how_work_description" v-if="homeSetting.how_work_description"> </span>
      </div>
      <div class="steps">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <div class="info-steps">
              <!-- <span> 1 </span> -->
              <img :src="homeSetting.step_one_icon_url" alt="step" />
              <h5>{{ homeSetting.how_work_step_one_title }}</h5>
              <p v-html="homeSetting.how_work_step_one_description"></p>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <div class="info-steps">
              <!-- <span> 2 </span> -->
              <img :src="homeSetting.step_two_icon_url" alt="step" />
              <h5>{{ homeSetting.how_work_step_two_title }}</h5>
              <p v-html="homeSetting.how_work_step_two_description"></p>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <div class="info-steps last">
              <!-- <span> 3 </span> -->
              <img :src="homeSetting.step_three_icon_url" alt="step" />
              <h5>{{ homeSetting.how_work_step_three_title }}</h5>
              <p v-html="homeSetting.how_work_step_three_description"></p>
            </div>
          </v-col>
          <!-- <v-col cols="12" md="3" sm="6">
            <div class="info-steps last">
              <span> 4 </span>
              <img :src="homeSetting.step_four_icon_url" alt="step" />
              <h5>{{ homeSetting.how_work_step_four_title }}</h5>
              <p v-html="homeSetting.how_work_step_four_description">
                
              </p>
            </div>
          </v-col> -->
        </v-row>
      </div>
      <div style="direction: rtl" class="slider steps">
        <carousel v-bind="options">
          <slide class="slide-item">
            <div class="info-steps">
              <!-- <span> 1 </span> -->
              <img :src="homeSetting.step_one_icon_url" alt="step" />
              <h5>{{ homeSetting.how_work_step_one_title }}</h5>
              <p v-html="homeSetting.how_work_step_one_description"></p>
            </div>
          </slide>
          <slide class="slide-item">
            <div class="info-steps">
              <!-- <span> 2 </span> -->
              <img :src="homeSetting.step_two_icon_url" alt="step" />
              <h5>{{ homeSetting.how_work_step_two_title }}</h5>
              <p v-html="homeSetting.how_work_step_two_description"></p>
            </div>
          </slide>
          <slide class="slide-item">
            <div class="info-steps last">
              <!-- <span> 3 </span> -->
              <img :src="homeSetting.step_three_icon_url" alt="step" />
              <h5>{{ homeSetting.how_work_step_three_title }}</h5>
              <p v-html="homeSetting.how_work_step_three_description"></p>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["homeSetting"],
  data() {
    return {
      options: {
        loop: true,
        margin: 5,
        perPage: 3,
        center: true,
        autoplay: true,
        autoplayTimeout: 3000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        navigationEnabled: false,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        // responsive: true,
        perPageCustom: [
          [0, 1],
          [480, 1],
          [768, 1],
          [1000, 2],
          [1200, 3],
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
.how-work {
  background-image: url("../../../public/img/Image1.png");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  height: auto;
  padding: 30px 30px;
  margin-top: 60px;
  @media (max-width: 580px) {
    padding: 25px 0;
  }
  &:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
  ._title {
    z-index: 9;
    position: relative;
    color: #fff;
    margin-bottom:48px;
    h1 {
      font-weight: bold;
      margin-bottom: 15px;
    }
    span {
      font-size: 17px;
    }
  }
  .steps {
    z-index: 9;
    position: relative;
    margin-top: 35px;
    .info-steps {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      color: #fff;
      position: relative;
      &:after {
        // background-image: url("../../../public/img/dashed.png");
        // content: "";
        // position: absolute;
        // top: 64px;
        // left: -105px;
        // width: 200px;
        // height: 20px;
        // background-repeat: repeat-x;
        @media (max-width: 991px) {
          display: none;
        }
      }
      span {
        font-weight: 700;
        font-size: 24px;
      }
      h5{
        font-size: 20px;
        font-weight: bold;;
      }
      &.last {
        &:after {
          display: none;
        }
      }
      img {
        width: 148px;
        height: 148px;
      }
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  .steps.slider {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
}
</style>
