<template>
  <div>
    <!-- skeleton placeholder -->
    <chat-details-skeleton-loader v-if="loading.data"></chat-details-skeleton-loader>

    <!-- chat body -->
    <div class="chat-body" v-if="!loading.data">
      <!-- chat header -->
      <div class="chat-header mb-4">
        <div class="row p-0 align-items-center">
          <div class="col-auto">
            <img class="img-fluid chat-header-image" :src="data.chat_details.chat_member.user_image_url"
                 :alt="data.chat_details.chat_member.user_name">
          </div>
          <div class="col">
            <span class="chat-header-username">{{ data.chat_details.chat_member.user_name }}</span>
            <small class="badge bg-danger-subtle text-danger py-2 w-100 text-pre-wrap"
                   v-if="data.chat_details.chat_warning_msg">
              {{ data.chat_details.chat_warning_msg }}
            </small>
          </div>
        </div>
      </div>

      <!-- chat messages -->
      <div class="chat-messages mb-4" id="chat-messages" @scroll="handleScroll">
        <!-- no data yes -->
        <not-found-messages v-if="!data_length"></not-found-messages>

        <!-- messages -->
        <div v-if="data_length" v-for="item in data.data" :key="item.message_id" class="row"
             :class="{'justify-content-end': item.message_if_mine}">
          <div class="col-10 col-sm-6 col-lg-5">
            <div class="chat-message"
                 :class="(item.message_if_mine) ? ((item.message_type === 'image') ? 'my-message-image' : 'my-message') : ((item.message_type === 'image') ? 'other-message-image' : 'other-message')">
              <img v-if="item.message_type === 'image'" class="img-fluid chat-message-image" :src="item.message_body"
                   alt="image" @click.prevent="openLightbox(item.message_body)">
              <div v-if="item.message_type === 'text'" class="message-text">{{ item.message_body }}</div>
              <div class="message-time text-end">
                <i class="mdi mdi-check-all" :class="{'is-reading': item.message_is_read}"></i>&nbsp;
                <span>{{ item.message_custom_date }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- watch more -->
        <div class="text-center" v-if="data.pagination.has_more_pages">
          <!-- loading -->
          <div class="spinner-border" role="status" v-if="loading.more">
            <span class="visually-hidden">Loading...</span>
          </div>
          <!-- watch more -->
          <!--          <a href="javascript:void(0);" class="link-dark d-block" @click="showMore">{{ $t("show-more") }} ...</a>-->
        </div>
      </div>

      <!-- chat footer -->
      <div class="chat-footer">
        <div v-if="data.chat_details.chat_status === 'opened'" class="row align-items-center">
          <div class="col">
            <div class="input-group">
              <input type="text" v-model="sendMessageForm.text" class="form-control form-control-lg"
                     :placeholder="$t('write-your-message-here')" @keyup.enter="sendMessage('text', $event)">
              <label for="chat-upload-file" class="chat-upload-file">
                <i class="mdi mdi-attachment"></i>
              </label>
              <input type="file" @change="sendMessage('image', $event)" class="form-control d-none"
                     id="chat-upload-file"
                     accept=".png, .x-png, .jpg, .jpeg, .pjpeg, .webp">
            </div>
          </div>
          <div class="col-auto">
            <button class="btn btn-dark" @click="sendMessage('text', $event)">
              <i class="mdi mdi-send-variant-outline mdi-24px" v-if="!loading.send"
                 :class="(lang === 'ar' ? 'mdi-rotate-180' : '')"></i>
              <!-- loading -->
              <span class="spinner-border d-block" role="status" v-if="loading.send">
                      <span class="visually-hidden">Loading...</span>
                    </span>
            </button>
          </div>
        </div>

        <!-- closed chat -->
        <div v-if="data.chat_details.chat_status === 'closed'" class="closed-chat">
          <i class="mdi mdi-information-variant-circle"></i>&nbsp;
          <span>{{ $t('chat-closed-for-reasons') }}</span>
        </div>
      </div>
    </div>

    <!-- image full preview -->
    <LightBox :media="media" v-if="lightBoxStatus" @onClosed="closeLightbox"></LightBox>
  </div>
</template>

<script>
import('vue-it-bigger/dist/vue-it-bigger.min.css');
import LightBox from 'vue-it-bigger';

import axios from "axios";
import {io} from 'socket.io-client';
import NotFoundMessages from "@/components/chats/not-found-messages.vue";
import ChatDetailsSkeletonLoader from "@/components/chats/chat-details-skeleton-loader.vue";

export default {
  components: {LightBox, ChatDetailsSkeletonLoader, NotFoundMessages},
  props: {
    chat_id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "ar",
      loading: {
        data: false,
        more: false,
        send: false,
      },
      data: null,
      data_length: 0,
      page: 1,
      sendMessageForm: {
        text: '',
        image: ''
      },
      socket: null,
      lightBoxStatus: false,
      media: [{
        type: 'image',
        thumb: '',
        src: '',
      }],
    }
  },
  async created() {
    this.loading.data = true;
    await this.getData();
    this.loading.data = false;

    let that = this;
    this.socket = io('https://chat.seenpark.sa/');
    this.socket.disconnect();
    this.socket.connect();

    this.socket.on('send-message-api-' + that.chat_id, function (jsonMessage) {
      let userData = JSON.parse(localStorage.getItem('user'));
      let message = JSON.parse(jsonMessage);
      if (message.message_sender_id !== userData.id) {
        that.page = 1;
        that.getData();
      }
    });
  },
  methods: {
    async getData() {
      try {
        let sendData = new FormData();
        sendData.append('chat_id', this.chat_id);
        sendData.append('page', this.page);

        await axios.post("open-chat", sendData)
            .then((res) => {
              let allData = res.data.data;
              if (this.page > 1) {
                this.data.data = [...this.data.data, ...allData.data];
                this.data.pagination = allData.pagination;
                this.data_length = this.data.data.length;
              } else {
                this.data = allData;
                this.data_length = allData.data.length;
              }
            })
            .catch((e) => {
              this.TriggerNotify('error', e.response.data.message);
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        console.error(error);
      }
    },
    async showMore() {
      if (!this.loading.more) {
        this.loading.more = true;
        this.page = ++this.data.pagination.current_page;
        await this.getData();
        this.loading.more = false;
      }
    },
    async sendMessage(type, event) {
      try {
        this.loading.send = true;

        let sendData = new FormData();
        sendData.append('chat_id', this.chat_id);
        sendData.append('sender_type', 'member');
        sendData.append('message_type', type);
        sendData.append('message', this.sendMessageForm.text);
        if (type === 'image') {
          let files = event.target.files || event.dataTransfer.files;
          if (files.length) {
            this.sendMessageForm.image = files[0];
          }
          sendData.append('message', this.sendMessageForm.image);
        }

        await axios.post("send-message", sendData)
            .then((res) => {
              let message = res.data.data;
              this.page = 1;
              this.getData();

              this.$emit('changeLastMessage', message.message_id);
              this.socket.emit('send-message-api', message);

              this.sendMessageForm = {text: '', image: ''};
              this.loading.send = false;
            })
            .catch((e) => {
              this.TriggerNotify('error', e.response.data.message);
              this.loading.send = false;
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        console.error(error);
        this.loading.send = false;
      }
    },
    openLightbox(image) {
      this.media[0].thumb = image;
      this.media[0].src = image;
      this.lightBoxStatus = true;
    },
    closeLightbox() {
      this.lightBoxStatus = false;
    },
    handleScroll(event) {
      const {scrollTop, offsetHeight, scrollHeight} = event.target;
      if ((Math.abs(scrollTop) + offsetHeight + 1) >= scrollHeight) {
        this.showMore();
      }
    }
  },
  watch: {
    chat_id() {
      this.page = 1;
      this.getData();
    }
  }
}
</script>