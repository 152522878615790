<template>
  <div class="offers_upcoming mt-5">
    <h3>العروض المقدمة</h3>
    <hr style="width: 100%" />
    <v-alert
      color="#CCE5FF"
      class="text-start p-3"
      v-if="!getAllOffersProject.length"
    >
      لا يوجد عروض بعد.
    </v-alert>
    <div v-if="Object.keys(getAllOffersProject).length > 0">
      <v-card
        class="order_offers_item"
        v-for="(offer, index) in getAllOffersProject"
        :key="index"
      >
        <div
          class="d-flex justify-content-between align-items-start flex-row w-100"
        >
          <div>
            <div
              class="d-flex justify-content-start align-items-start gap-3 flex-row"
            >
              <div class="user_img">
                <img :src="offer.professional_image" alt="user" />
              </div>
              <div class="user_info">
                <div
                  class="d-flex justify-content-start align-items-start flex-row flex-wrap gap-2"
                >
                  <span> {{ offer.professional_name }} </span>
                  <span>
                    <img
                      src="../../../../../public/img/ksa.webp"
                      alt="flag"
                      width="25"
                      height="15"
                      style="object-fit: contain"
                    />
                  </span>
                </div>
                <span
                  class="mt-2 text-start"
                  style="
                    color: #6c757d !important;
                    font-size: 13px;
                    display: block;
                    start-align: start;
                  "
                >
                  فوتوغرافر إعلانات ومنتجات
                </span>
                <div
                  class="rating_location d-flex justify-content-start align-items-start flex-row flex-wrap gap-4 mt-2"
                >
                  <v-rating
                    :v-model="offer.professional_rate"
                    background-color="#e0e0e0"
                    color="#FC7D12"
                    dense
                    half-increments
                    hover
                    size="15"
                    readonly
                  ></v-rating>
                  <div>
                    <v-icon> mdi-map-marker </v-icon>
                    <span class="lcoation__"> {{ offer.city_name }} </span>
                  </div>
                </div>
              </div>
            </div>
            <p class="w-75">
              {{ offer.description }}
            </p>
          </div>
          <div
            class="offer_val d-flex justify-content-center align-items-center flex-column flex-wrap gap-1"
          >
            <span class="offer-price"> {{ offer.final_offer_money }} </span>
            <span class="offer-note"> شامل ضريبة القيمة المضافة </span>
            <span
              class="offer-button d-flex justify-content-center align-items-center flex-row gap-2"
            >
              <v-btn
                color="#02900C"
                class="w-25"
                style="min-width: 40px !important"
                @click="acceptOffers(offer.id)"
                v-if="
                  offer.member_agree_status == 'wait' &&
                  this.acceptOfferStatus == false
                "
              >
                <v-icon color="#fff"> mdi-check-bold </v-icon>
              </v-btn>
              <v-btn
                color="#B90000"
                class="w-25"
                style="min-width: 40px !important"
                @click="rejectOffers(offer.id)"
                v-if="
                  offer.member_agree_status == 'wait' &&
                  this.refuseOfferStatus == false
                "
              >
                <v-icon color="#fff"> mdi-close </v-icon>
              </v-btn>
            </span>
          </div>
        </div>
        <v-alert
          dense
          text
          type="green"
          class="w-100"
          v-if="offer.member_agree_status == 'agree'"
        >
          {{ offer.member_agree_status }}
        </v-alert>
        <v-alert
          dense
          text
          type="red"
          class="w-100"
          v-if="offer.member_agree_status == 'refuse'"
        >
          {{ offer.member_agree_status }}
        </v-alert>
      </v-card>
    </div>
    <div v-if="Object.keys(getAllOffersProject).length = 0">
      <v-skeleton-loader
        v-bind="attrs"
        type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="list-item-three-line"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      rating: 4.5,
      project_id: this.$route.params.id,
      offer_id: 1,
      acceptOfferStatus: false,
      refuseOfferStatus: false,
      attrs: {
        class: "mb-2",
        boilerplate: true,
      },
    };
  },
  created() {
    const id = this.$route.params.id;
    this.getOffersProjects(id);
  },
  methods: {
    ...mapActions(["getOffersProjects"]),
    acceptOffers(offerId) {
      const payload = {
        project_id: this.project_id,
        offer_id: offerId,
      };
      this.$store
        .dispatch("acceptOffer", payload)
        .then((res) => {
          this.TriggerNotify("success", res.response.data.message);
          this.acceptOfferStatus = true;
        })
        .catch((error) => {
          this.TriggerNotify("error", error.response.data.message);
        });
    },
    rejectOffers(offerId) {
      const payload = {
        project_id: this.project_id,
        offer_id: offerId,
      };
      this.$store
        .dispatch("rejectOffer", payload)
        .then((res) => {
          this.TriggerNotify("success", res.response.data.message);
          this.refuseOfferStatus = true;
        })
        .catch((error) => {
          this.TriggerNotify("error", error.response.data.message);
        });
    },
  },
  computed: {
    ...mapGetters(["getAllOffersProject", "getMessage", "getMessageRej"]),
  },
};
</script>

<style scoped lang="scss">
.order_details {
  .order_title {
    h3 {
      color: #4d4d4d;
      font-size: 22px;
      font-weight: bold;
    }
    span {
      color: #6c757d !important;
    }
  }
}
.orders_buttons {
  button {
    background-color: #e0e0e0 !important;
    box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
    border-radius: 4px;
    color: #4d4d4d;
    font-size: 0.875rem;
    height: 44px !important;
    line-height: 44px;
    letter-spacing: 0;
    font-size: 16px;
    i {
      margin-inline-end: 5px;
    }
  }
}
.order_details,
.order_info,
.order_files,
.order_offers_item {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #e8e9ea;
  border-radius: 4px;
  padding: 24px;
  font-size: 14px;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-color: #e8e9ea !important;
  h3 {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 21px;
  }
  p {
    text-align: start;
    line-height: 1.8;
    font-size: 16px;
  }
  .user_img {
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
  }
  .user_info {
    span {
      color: #212529 !important;
    }
  }
  .rating_location {
    .lcoation__,
    i {
      color: #6c757d !important;
      font-size: 14px;
    }
  }
}
.order_files {
  .media-type {
    background: #2386c8 0 0 no-repeat padding-box;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    padding: 2px 4px;
  }
  .media-name {
    color: #2386c8;
  }
  .media-size {
    color: #868e96;
    font-size: 12px;
  }
}
.offers_upcoming {
  h3 {
    text-align: start;
    color: #4d4d4d;
    font-weight: bold;
    font-size: 21px;
  }
  .offer-price {
    background: #e0e0e0 0 0 no-repeat padding-box;
    box-shadow: 0 0 1px #1d77b129;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: bold;
  }
  .offer-note {
    font-size: 11px;
    color: #868e96;
    display: block;
  }
}
.order_details_sec {
  background-color: #fafafa;
  padding: 30px 0;
}
</style>
