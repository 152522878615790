<template>
  <v-dialog v-model="CancelDialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="btn">
        <v-icon>mdi-lock-outline</v-icon>
        إلغاء الطلب
      </v-btn>
    </template>

    <v-card>
      <div
          class="d-flex justify-content-between align-items-center flex-row pt-3 px-2"
      >
        <span class="font-weight-bold">
          تأكيد إلغاء الطلب
        </span>
        <button @click="CancelDialog = false">
          <v-icon> mdi-close</v-icon>
        </button>
      </div>
      <v-divider></v-divider>
      <p class="text-start px-3"> هل أنت متأكد من رغبتك في طلب إلغاء الطلب؟ </p>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-content-end align-items-start flex-row px-3 actions" style="gap:10px;">
        <v-btn color="#000" depressed @click="handlecancelOrder" :loading="loadingCancel">
          إلغاء الطلب
        </v-btn>
        <v-btn color="#E0E0E0" @click="CancelDialog = false" depressed>
          تراجع
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      CancelDialog: false,
      loadingCancel: false
    };
  },
  computed: {
    ...mapGetters(['getCancelData']),
    cancelPayload() {
      const payload = {
        order_id: this.$route.params.id
      };
      return {...payload};
    },
  },
  methods: {
    handlecancelOrder() {
      this.loadingCancel = true
      this.$store.dispatch('cancelOrder', this.cancelPayload).then((res) => {
        this.loadingCancel = false
        this.CancelDialog = false
        this.$router.push('/orders')
        this.TriggerNotify("success", this.getCancelData);
      }).catch((err) => {
        this.loadingCancel = false
        this.CancelDialog = false
        this.TriggerNotify("error", err.response.data.message);
      })
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  background-color: #e0e0e0 !important;
  box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
  border-radius: 4px;
  color: #4d4d4d;
  font-size: 0.875rem;
  height: 44px !important;
  line-height: 44px;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;

  i {
    margin-inline-end: 5px;
    color: #4d4d4d !important;
  }
}

.actions {
  button {
    letter-spacing: 0;

    &:first-of-type {
      color: #fff;
    }
  }
}

hr {
  padding: 0;
  margin: 8px;
  opacity: 0.5;
}
</style>
