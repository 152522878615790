import {validationMixin} from 'vuelidate'
import {
    required,
    minLength,
    email,
    maxLength,
    sameAs, numeric, regex, minValue, maxValue
} from 'vuelidate/lib/validators'

export const signupMixin = {
    mixins: [validationMixin],
    validations: {
        Firstname: {required, maxLength: maxLength(30)},
        Lastname: {required, maxLength: maxLength(30)},
        country_id: {
            required,
        },
        mobile: {
            required,
            numeric,
        },
        email: {required, email},
        // password: {
        //     required,
        //     minLength: minLength(6),
        // },
        // passwordConfimation: {sameAsPassword: sameAs('password')},
        checkbox: {
            checked(val) {
                return val;
            },
        },
    },
    data: () => ({
        Firstname: "",
        Lastname: "",
        email: "",
        country_id: "",
        mobile: "",
        // password: "",
        // passwordConfimation: '',
        checkbox: false,
        isFormValid: true,
        verify: ''
    }),

    computed: {
        checkboxErrors() {
            const errors = [];
            if (!this.$v.checkbox.$dirty) return errors;
            !this.$v.checkbox.checked && errors.push(this.$t('errors.checkboxReq'));
            return errors;
        },
        FirstnameErrors() {
            const errors = [];
            if (!this.$v.Firstname.$dirty) return errors;
            !this.$v.Firstname.maxLength &&
            errors.push(this.$t('errors.FirstnameLength'));
            !this.$v.Firstname.required && errors.push(this.$t('errors.FirstnameReq'));
            return errors;
        },
        LastnameErrors() {
            const errors = [];
            if (!this.$v.Lastname.$dirty) return errors;
            !this.$v.Lastname.maxLength &&
            errors.push(this.$t('errors.FirstnameLength'));
            !this.$v.Lastname.required && errors.push(this.$t('errors.LastnameReq'));
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push(this.$t('errors.email'));
            !this.$v.email.required && errors.push(this.$t('errors.emailReq'));
            return errors;
        },
        countryIdErrors() {
            const errors = [];
            if (!this.$v.country_id.$dirty) return errors;
            !this.$v.country_id.required && errors.push(this.$t("errors.checkboxReq"));
            return errors;
        },
        PhoneErrors() {
            const errors = [];
            if (!this.$v.mobile.$dirty) return errors;
            !this.$v.mobile.required && errors.push(this.$t("errors.enter-valid-number"));
            !this.$v.mobile.numeric && errors.push(this.$t("errors.enter-valid-number"));
            return errors;
        },
        // passowrdErrors() {
        //     const errors = []
        //     if (!this.$v.password.$dirty) return errors
        //     !this.$v.password.required &&
        //     errors.push(this.$t('errors.passwordReq'))
        //     !this.$v.password.minLength &&
        //     errors.push(this.$t('errors.password'))
        //     return errors
        // },
        // passowrdConfirmErrors() {
        //     const errors = []
        //     if (!this.$v.passwordConfimation.$dirty) return errors
        //     !this.$v.passwordConfimation.sameAsPassword &&
        //     errors.push(this.$t('errors.passNotMatch'))
        //     return errors
        // },
    },
}


export const loginMixin = {
    mixins: [validationMixin],
    validations: {
        country_id: {
            required,
        },
        mobile: {
            required,
            numeric,
        },
        // password: {
        //     required,
        //     minLength: minLength(6),
        // }
    },
    data: () => ({
        country_id: "",
        mobile: "",
        // password: "",
    }),

    computed: {
        countryIdErrors() {
            const errors = [];
            if (!this.$v.country_id.$dirty) return errors;
            !this.$v.country_id.required && errors.push(this.$t("errors.checkboxReq"));
            return errors;
        },
        mobileErrors() {
            const errors = [];
            if (!this.$v.mobile.$dirty) return errors;
            !this.$v.mobile.required && errors.push(this.$t("errors.enter-valid-number"));
            !this.$v.mobile.numeric && errors.push(this.$t("errors.enter-valid-number"));
            return errors;
        },
        // passowrdErrors() {
        //     const errors = []
        //     if (!this.$v.password.$dirty) return errors
        //     !this.$v.password.required &&
        //     errors.push(this.$t('errors.passwordReq'))
        //     !this.$v.password.minLength &&
        //     errors.push(this.$t('errors.password'))
        //     return errors
        // },
    },
}


export const forgetPasswordMixin = {
    mixins: [validationMixin],
    validations: {
        country_id: {
            required,
        },
        mobile: {
            required,
            numeric,
        },
        verify: {required},
        confirmPass: {required},
        password: {required},
    },
    data: () => ({
        country_id: "",
        mobile: "",
        verify: "",
        password: '',
        confirmPass: '',
    }),
    computed: {
        countryIdErrors() {
            const errors = [];
            if (!this.$v.country_id.$dirty) return errors;
            !this.$v.country_id.required && errors.push(this.$t("errors.checkboxReq"));
            return errors;
        },
        PhoneErrors() {
            const errors = [];
            if (!this.$v.mobile.$dirty) return errors;
            !this.$v.mobile.required && errors.push(this.$t("errors.enter-valid-number"));
            !this.$v.mobile.numeric && errors.push(this.$t("errors.enter-valid-number"));
            return errors;
        },
        verifyErrors() {
            const errors = [];
            if (!this.$v.verify.$dirty) return errors;
            !this.$v.verify.required && errors.push("verify is required");
            return errors;
        },
        passwordErrors() {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('password is required')
            return errors
        },
        confirmPassErrors() {
            const errors = []
            if (!this.$v.confirmPass.$dirty) return errors
            !this.$v.confirmPass.required && errors.push('password is required')
            return errors
        },
    },
}


export const validationDateAndAhours = {
    mixins: [validationMixin],
    validations: {
        Startdate: {required},
        Enddate: {required},
        Starttime: {required},
        Endtime: {required},
    },
    data() {
        return {
            Startdate: "",
            Enddate: "",
            Starttime: "",
            Endtime: ""
        }
    },
    computed: {
        StartdateErrors() {
            const errors = []
            if (!this.$v.Startdate.$dirty) return errors
            !this.$v.Startdate.required && errors.push(' بداية التاريخ مطلوبة')
            return errors
        },
        EnddateErrors() {
            const errors = []
            if (!this.$v.Enddate.$dirty) return errors
            !this.$v.Enddate.required && errors.push(' نهاية التاريخ مطلوبة ')
            return errors
        },
        StarttimeErrors() {
            const errors = []
            if (!this.$v.Starttime.$dirty) return errors
            !this.$v.Starttime.required && errors.push('بداية الوقت مطلوبة')
            return errors
        },
        EndtimeErrors() {
            const errors = []
            if (!this.$v.Endtime.$dirty) return errors
            !this.$v.Endtime.required && errors.push('نهاية الوقت مطلوبة')
            return errors
        },
    }
}


export const validationPayment = {
    mixins: [validationMixin],
    validations: {
        nameCard: {
            required, noNumbers(value) {
                return /^[^0-9]*$/.test(value);
            },
        },
        numberCard: {required, numeric, maxLength: maxLength(16)},
        cvvCard: {required, maxLength: maxLength(3), minLength: minLength(3), numeric},
        dateCard: {required, isDateValid: value => /^\d{4}\-\d{2}$/.test(value)},
    },
    data() {
        return {
            nameCard: "",
            numberCard: "",
            cvvCard: "",
            dateCard: "",
        }
    },
    computed: {
        nameCardErrors() {
            const errors = [];
            if (!this.$v.nameCard.$dirty) return errors;
            !this.$v.nameCard.required && errors.push('اسم البطاقة مطلوب');
            !this.$v.nameCard.noNumbers && errors.push('لا يجب أن يحتوي اسم البطاقة على أرقام');
            return errors;
        },
        numberCardErrors() {
            const errors = [];
            if (!this.$v.numberCard.$dirty) return errors;
            !this.$v.numberCard.required && errors.push('رقم البطاقة مطلوب');
            !this.$v.numberCard.numeric && errors.push('رقم البطاقة يجب أن يحتوي على أرقام فقط');
            !this.$v.numberCard.maxLength && errors.push('رقم البطاقة يجب أن يحتوي على 16 رقمًا');
            return errors;
        },
        cvvCardErrors() {
            const errors = []
            if (!this.$v.cvvCard.$dirty) return errors
            !this.$v.cvvCard.required && errors.push('CCV مطلوب')
            !this.$v.cvvCard.maxLength && errors.push('CCV يجب أن يحتوي على 3 أرقام فقط')
            !this.$v.cvvCard.minLength && errors.push('CCV يجب أن يحتوي على 3 أرقام ')
            !this.$v.cvvCard.numeric && errors.push('CCV يجب أن يحتوي على أرقام فقط')
            return errors
        },
        dateCardErrors() {
            const errors = [];
            if (!this.$v.dateCard.$dirty) return errors;
            !this.$v.dateCard.required && errors.push('تاريخ البطاقة مطلوب');
            !this.$v.dateCard.isDateValid && errors.push('تاريخ البطاقة يجب أن يكون في الشكل YYYY-MM');
            return errors;
        },
    }
}


export const contactUs = {
    mixins: [validationMixin],
    validations: {
        name: {required, maxLength: maxLength(30)},
        email: {required, email},
        mobile: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(14),
            numeric,
        },
        message: {required}
    },
    data() {
        return {
            name: "",
            email: "",
            mobile: "",
            message: "",
        }
    },
    computed: {
        nameError() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.maxLength &&
            errors.push(this.$t('errors.FirstnameLength'));
            !this.$v.name.required && errors.push(this.$t('errors.FirstnameReq'));
            return errors;
        },
        emailError() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push(this.$t('errors.email'));
            !this.$v.email.required && errors.push(this.$t('errors.emailReq'));
            return errors;
        },
        mobileError() {
            const errors = [];
            if (!this.$v.mobile.$dirty) return errors;
            !this.$v.mobile.minLength && errors.push(this.$t('errors.phoneNotValid'));
            !this.$v.mobile.maxLength && errors.push(this.$t('errors.phoneNotValid'));
            !this.$v.mobile.required && errors.push(this.$t('errors.phoneNotValid'));
            !this.$v.mobile.numeric && errors.push(this.$t('errors.phoneNotValid'));
            return errors;
        },
        messageError() {
            const errors = [];
            if (!this.$v.message.$dirty) return errors;
            !this.$v.message.required && errors.push(this.$t('errors.meesageREQ'));
            return errors;
        },
    }
}

// export const verifyMixin = {
//     mixins: [validationMixin],
//     validations: {
//       verify: { required },
//     },
//     data: () => ({
//       verify: "",
//     }),
//     computed: {
//       verifyErrors() {
//         const errors = [];
//         if (!this.$v.verify.$dirty) return errors;
//         !this.$v.verify.required && errors.push("verify is required");
//         return errors;
//       },
//     },
// }