<template>
  <section class="order_details_sec">
    <v-container>
      <div class="__bread">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div v-if="Object.keys(getAllProjectDetails).length > 0">
        <div class="status_isReview">
          <v-alert
            color="#CCE5FF"
            class="text-start p-3"
            v-if="getAllProjectDetails.status == 'wait'"
          >
            هذا المشروع مازال قيد المراجعه حاليا، نرجو منك الانتظار وسيصلك إشعار
            حين إتمام مراجعته من قبل فريقنا
          </v-alert>
        </div>
        <div
          class="order_title d-flex justify-content-between align-start flex-row flex-wrap mt-5"
        >
          <div
            class="d-flex justify-content-start align-start flex-column flex-wrap"
          >
            <h3>{{ getAllProjectDetails.name }}</h3>
            <span> {{ getAllProjectDetails.category_title }} - الاعلانات </span>
          </div>
          <div class="order_status">
            <v-chip
              class="ma-2"
              label
              color="#FF9822"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'wait'"
            >
              {{ $t('status-wait') }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
              color="#FFC128"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'special'"
            >
              خاص
            </v-chip>
            <v-chip
              class="ma-2"
              label
              color="#2E8B57"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'open'"
            >
              {{ $t('status-open') }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
              color="#000"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'wait'"
            >
              {{ $t('status-working') }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
              color="#000"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'completed'"
            >
              {{ $t('status-completed') }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
              color="#949494"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'closed'"
            >
              {{ $t('status-closed') }}
            </v-chip>
            <v-chip
              class="ma-2"
              label
              color="#949494"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'canceled_by_admin'"
            >
              ملغى من قبل الأدمن
            </v-chip>
            <v-chip
              class="ma-2"
              label
              color="#949494"
              style="color: #fff !important"
              v-if="getAllProjectDetails.status == 'canceled_by_member'"
            >
              ملغى من قبل المحترف
            </v-chip>
          </div>
        </div>
        <hr />
        <projectsBtns />
        <v-row>
          <v-col cols="12" md="8" sm="12" xs="12">
            <v-card class="order_details p-4 mt-5">
              <h3>{{ $t('project-details') }}</h3>
              <hr style="width: 100%" />
              <p>
                {{ getAllProjectDetails.description }}
              </p>
            </v-card>
            <v-card class="order_files p-4 mt-5">
              <div
                class="d-flex justify-content-start align-items-center gap-3 flex-row"
              >
                <span class="media-type"> png </span>
                <span class="media-name"> cine1292462.png </span>
                <span class="media-size"> (16MG) </span>
              </div>
              <hr style="width: 100%" />
              <div
                class="d-flex justify-content-start align-items-center gap-3 flex-row"
              >
                <span class="media-type"> png </span>
                <span class="media-name"> cine1292462.png </span>
                <span class="media-size"> (16MG) </span>
              </div>
            </v-card>
            <offersProject />
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-card class="order_info p-4 mt-5">
              <h3>معلومات المشروع</h3>
              <hr style="width: 100%" />
              <ul
                class="d-flex justify-content-start align-items-start flex-column flex-wrap gap-3"
                style="width: 100% !important"
              >
                <li
                  class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                  style="width: 100% !important"
                >
                  <span>رقم المشروع</span>
                  <span>#{{ getAllProjectDetails.id }}</span>
                </li>
                <li
                  class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                  style="width: 100% !important"
                >
                  <span> تاريخ النشر </span>
                  <span>{{ getAllProjectDetails.display_date }}</span>
                </li>
                <li
                  class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                  style="width: 100% !important"
                >
                  <span> تاريخ التنفيذ </span>
                  <span>27-11-2020</span>
                </li>
                <li
                  class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                  style="width: 100% !important"
                >
                  <span> مدة التنفيذ </span>
                  <span>19 يوم</span>
                </li>
              </ul>
            </v-card>
            <v-card
              class="order_info p-4 d-flex justify-content-start align-items-start flex-row flex-wrap gap-3 mt-5"
            >
              <div class="user_img">
                <img src="../../../../public/img/man-model.jpg" alt="user" />
              </div>
              <div class="user_info">
                <div
                  class="d-flex justify-content-start align-items-start flex-row flex-wrap gap-2"
                >
                  <span> أحمد يسري </span>
                  <span>
                    <img
                      src="../../../../public/img/ksa.webp"
                      alt="flag"
                      width="25"
                      height="15"
                      style="object-fit: contain"
                    />
                  </span>
                </div>
                <span
                  class="mt-2 text-start"
                  style="
                    color: #6c757d !important;
                    font-size: 13px;
                    display: block;
                    start-align: start;
                  "
                >
                  فوتوغرافر إعلانات ومنتجات
                </span>
                <div
                  class="rating_location d-flex justify-content-start align-items-start flex-row flex-wrap gap-4 mt-3"
                >
                  <v-rating
                    v-model="rating"
                    background-color="#e0e0e0"
                    color="#FC7D12"
                    dense
                    half-increments
                    hover
                    size="15"
                  ></v-rating>
                  <div>
                    <v-icon> mdi-map-marker </v-icon>
                    <span class="lcoation__"> الرياض </span>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </div>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import offersProject from "./project-details-comp/project-offer.vue";
import projectsBtns from "./project-details-comp/project-btns.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    offersProject,
    projectsBtns,
  },
  data() {
    return {
      isReview: 0,
      isOpen: 1,
      isPending: 2,
      isComplete: 3,
      isClose: 4,
      isCancel: 5,
      rating: 4.5,
    };
  },
  created() {
    const id = this.$route.params.id;
    this.getProjectDetails(id);
  },
  methods: {
    ...mapActions(["getProjectDetails"]),
  },
  computed: {
    Bread() {
      const breadCrumbs = [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          href: "/",
        },
        {
          text: "مشاريعي",
          disabled: false,
          href: "/projects",
        },
        {
          text: this.getAllProjectDetails.name,
          disabled: true,
          href: "",
        },
      ];
      return breadCrumbs;
    },
    ...mapGetters(["getAllProjectDetails"]),
  },
};
</script>

<style scoped lang="scss">
.__bread {
  box-shadow: none !important;
  .v-breadcrumbs {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none !important;
    padding: 12px;
  }
  .v-breadcrumbs__item {
    color: #000 !important;
  }
  .v-breadcrumbs__item--disabled {
    color: #868e96 !important;
  }
}
.order_details {
  .order_title {
    h3 {
      color: #4d4d4d;
      font-size: 22px;
      font-weight: bold;
    }
    span {
      color: #6c757d !important;
    }
  }
}
.orders_buttons {
  button {
    background-color: #e0e0e0 !important;
    box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
    border-radius: 4px;
    color: #4d4d4d;
    font-size: 0.875rem;
    height: 44px !important;
    line-height: 44px;
    letter-spacing: 0;
    font-size: 16px;
    i {
      margin-inline-end: 5px;
    }
  }
}
.order_details,
.order_info,
.order_files,
.order_offers_item {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #e8e9ea;
  border-radius: 4px;
  padding: 24px;
  font-size: 14px;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-color: #e8e9ea !important;
  h3 {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 21px;
  }
  p {
    text-align: start;
    line-height: 1.8;
    font-size: 16px;
  }
  .user_img {
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
  }
  .user_info {
    span {
      color: #212529 !important;
    }
  }
  .rating_location {
    .lcoation__,
    i {
      color: #6c757d !important;
      font-size: 14px;
    }
  }
}
.order_files {
  .media-type {
    background: #2386c8 0 0 no-repeat padding-box;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    padding: 2px 4px;
  }
  .media-name {
    color: #2386c8;
  }
  .media-size {
    color: #868e96;
    font-size: 12px;
  }
}
.offers_upcoming {
  h3 {
    text-align: start;
    color: #4d4d4d;
    font-weight: bold;
    font-size: 21px;
  }
  .offer-price {
    background: #e0e0e0 0 0 no-repeat padding-box;
    box-shadow: 0 0 1px #1d77b129;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: bold;
  }
  .offer-note {
    font-size: 11px;
    color: #868e96;
    display: block;
  }
}
.order_details_sec {
  background-color: #fafafa;
  padding: 30px 0;
}
</style>
