<template>
  <v-col cols="12" md="3">
    <div class="filter-side mt-5">
      <div class="search__">
        <v-text-field
            append-icon="mdi-magnify"
            solo
            dense
            clearable
            class="Search_input"
            :label="$t('projects.search')"
            v-model="filter.search"
            @input="emitFilterOrders"
        ></v-text-field>
      </div>
      <template>
        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel class="d-none">
            <v-expansion-panel-header class="filter_title">
              {{ $t('order-type') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="filter_fixed">
              <v-radio-group

                  mandatory
                  v-model="filter.type"
                  @change="emitFilterOrders"
              >
                <div
                    class="filter_item"
                    v-for="(type, index) in typeOrder"
                    :key="index"
                >
                  <v-radio
                      color="#0d6efd"
                      :label="type.name"
                      :value="type.value"
                  >
                  </v-radio>
                </div>
              </v-radio-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- <hr style="width: 100%; margin: 0 !important" /> -->
          <v-expansion-panel v-if="filter.type === 'projects'">
            <v-expansion-panel-header class="filter_title">
              {{ $t('order-status') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="filter_fixed">
              <v-radio-group
                  mandatory
                  v-model="filter.statusProject"
                  @change="emitFilterOrders"
              >
                <div
                    class="filter_item"
                    v-for="(status, index) in statusProjects"
                    :key="index"
                >
                  <v-radio
                      color="#0d6efd"
                      :label="status.name"
                      :value="status.value"
                  >
                  </v-radio>
                </div>
              </v-radio-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="filter.type !== 'projects'">
            <v-expansion-panel-header class="filter_title">
              {{ $t('order-status') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="filter_fixed">
              <v-radio-group
                  mandatory
                  v-model="filter.statusProject"
                  @change="emitFilterOrders"
              >
                <div
                    class="filter_item"
                    v-for="(status, index) in statusOrders"
                    :key="index"
                >
                  <v-radio
                      color="#0d6efd"
                      :label="status.name"
                      :value="status.value"
                  >
                  </v-radio>
                </div>
              </v-radio-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <hr style="width: 100%; margin: 0 !important"/>
        </v-expansion-panels>
      </template>
    </div>
  </v-col>
</template>

<script>
import axios from "axios";

export default {
  props: {
    sortValue: {
      type: String,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  name: "orders",
  data() {
    return {
      showMoreButton: true,
      panel: [0, 1],
      filter: {
        search: "",
        type: "",
        radioInline: this.sort,
        statusProject: "",
        statusOrders: "",
      },
      typeOrder: [
        // {
        //   id: 1,
        //   name: "مشاريعي",
        //   value: "projects",
        // },
        // {
        //   id: 2,
        //   name: "سيني ستوك",
        //   value: "cine_stocks",
        // },
        // {
        //   id: 1,
        //   name: "سيني أكاديمي",
        //   value: "cine_academies",
        // },
        {
          id: 1,
          name: this.$t('Breadcrumb.stdios'),
          value: "studios",
        },
        // {
        //   id: 1,
        //   name: "سوق المحترفين",
        //   value: "professional_markets",
        // },
      ],
      statusProjects: [
        {
          id: 0,
          name: this.$t("stocks.all"),
          value: "",
        },
        {
          id: 1,
          name: this.$t('status-wait'),
          value: "wait",
        },
        {
          id: 2,
          name: this.$t('status-open'),
          value: "open",
        },
        {
          id: 3,
          name: this.$t('status-working'),
          value: "working",
        },
        {
          id: 4,
          name: this.$t('status-completed'),
          value: "completed",
        },
        {
          id: 5,
          name: this.$t('status-closed'),
          value: "closed",
        },
        {
          id: 6,
          name: this.$t('status-canceled_by_member'),
          value: "canceled_by_member",
        },
        {
          id: 6,
          name: this.$t('status-canceled_by_admin'),
          value: "canceled_by_admin",
        },
      ],
      statusOrders: [
        {
          id: 0,
          name: this.$t("stocks.all"),
          value: "",
        },
        {
          id: 1,
          name: this.$t('status-wait'),
          value: "wait",
        },
        {
          id: 2,
          name: this.$t('status-open'),
          value: "open",
        },
        {
          id: 3,
          name: this.$t('status-working'),
          value: "inprogress",
        },
        {
          id: 4,
          name: this.$t('status-completed'),
          value: "completed",
        },
        {
          id: 5,
          name: this.$t('status-cancelled'),
          value: "cancelled",
        },
        {
          id: 6,
          name: this.$t('status-rejected'),
          value: "rejected",
        },
      ],
    };
  },
  watch: {
    sortValue() {
      this.emitFilterOrders();
    },
  },
  methods: {
    emitFilterOrders(page) {
      const params = {
        search_text: this.filter.search,
        radioInline: this.sortValue,
        type: this.filter.type,
        filter: this.filter.statusProject || this.filter.statusOrders,
        page: page,
      };
      this.$emit("filterOrdersParams", params);
    },
  },
};
</script>

<style lang="scss" scoped></style>
