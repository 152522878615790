<template>
  <section class="services_sec" v-if="services.length > 0">
    <div class="container">
      <div class="section_title mb-5">
        <h1 v-if="homeSetting.services_title">{{homeSetting.services_title}}</h1>
        <p v-if="homeSetting.services_description">{{ homeSetting.services_description }}</p>
      </div>
      <div class="" v-if="services.length > 0">
        <div class="row">
          <v-col
            cols="12"
            md="6"
            v-for="(item, index) in services"
            :key="index"
            v-if="item.advantages_count == 0"
          >
            <u-animate-container>
              <u-animate
                name="fadeIn"
                delay="2s"
                duration="4s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
              >
                <router-link :to="`/${item.concern_with}`">
                  <div class="card custom-service-div-two">
                    <img
                      class="card-img"
                      :src="item.image_url"
                      :alt="item.name"
                    />

                    <div class="card-img-overlay">
                      <div class="row text-start">
                        <div class="col-12">
                          <h5 class="font-size-21">{{ item.name }}</h5>
                          <p class="d-sm-block">
                            {{ item.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </u-animate>
            </u-animate-container>
          </v-col>
        </div>
        <div v-for="(item, index) in services" :key="index" class="service__">
          <div
            v-if="item.advantages_count !== 0"
            class="d-flex justify-start align-start flex-row gap-4 wrap_div_"
          >
            <div
              class="service_info services_item d-flex justify-content-start align-items-start flex-column position-relative"
              style="width: 50%; height: 300px"
            >
              <h2>{{ item.name }}</h2>
              <p class="">{{ item.description }}</p>
              <ul class="list-unstyled service-features-ul mt-5">
                <li v-for="(item, index) in item.advantages" :key="index">
                  <img
                    src="../../../public/img/icons8-checkmark-24.png"
                    :alt="item.name"
                  />
                  <span> {{ item.name }} </span>
                </li>
              </ul>
              <div class="text-end my-4 see_more">
                <router-link :to="`/${item.concern_with}`" class="link-dark">
                  <span class="d-inline-flex">
                    <span class="me-2 mt-1">
                      {{ $t("home.viewService") }}
                    </span>
                    <img
                      src="../../../public/img/icons8-back-arrow-30.png"
                      alt=""
                      class="mx-2"
                    />
                  </span>
                </router-link>
              </div>
            </div>
            <div style="width: 50%" class="NO_order">
              <router-link :to="`/${item.concern_with}`">
                <div class="card services_item">
                  <img :src="item.image_url" alt="" />
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["services", "advantages" , "homeSetting"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.services_sec {
  padding: 35px 30px;
  @media (max-width: 767px) {
    padding: 10px;
  }
  .services_item {
    margin-top: 35px;
    border: 0 !important;
  }
  .section_title {
    p {
      margin-bottom: 40px !important;
    }
  }
  .service_info {
    background-color: #fafafa !important;
    padding: 15px;
    h2 {
      font-weight: bold;
      color: var(--fourth-color);
      margin-bottom: 15px;
    }
    p {
      text-align: start;
      margin-bottom: 0;
    }
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      li {
        img {
          margin-inline-end: 8px;
        }
      }
    }
  }
}
.custom-service-div {
  color: #fff;
  border: none;
  overflow: hidden;
  height: 100%;
}

.custom-service-div .card-img-overlay {
  top: auto;
  z-index: 9;
  padding: 0 30px 15px 30px;
}

.custom-service-div .card-img {
  height: 100%;
  object-fit: cover;
}

.custom-service-div .card-img-overlay p {
  font-size: 16px;
  width: 85%;
  letter-spacing: 0.02px;
  line-height: 23px;
}

.custom-service-div img {
  transition: transform 0.5s; /* Animation */
}

.service_info:hover img {
  // transform: scale(1.25);
  // height: 360px
}

.NO_order img {
  transition: transform 0.5s;
  height: 300px;
  object-fit: cover;
}
.NO_order:hover img {
  // transform: scale(1.1);
}

.custom-service-div:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(180deg, rgba(255,255,255,0.25) 0, rgba(17,17,17,0.75) 100%);*/
  background: transparent linear-gradient(180deg, #ffffff00 0, #00000080 100%) 0
    0 no-repeat padding-box;
}

.custom-service-div:hover:after {
  transition: 0.5s ease;
  background: linear-gradient(
    180deg,
    rgba(17, 17, 17, 0.25) 0,
    rgba(17, 17, 17, 0.75) 100%
  );
}

.line-height-24 {
  line-height: 24px;
}

.custom-service-div2 {
  color: #fff;
  border: none;
  overflow: hidden;
  height: 100%;
}

.custom-service-div2 .card-img {
  height: 100%;
  object-fit: cover;
}

.custom-service-div2:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, #ffffff00 0, #00000080 100%) 0
    0 no-repeat padding-box;
}
.font-bold {
  font-weight: bold;
}
.service__:nth-child(odd) .services_item {
  order: 1;
}
.service__:nth-child(odd) .NO_order {
  order: 2;
}
.service__:nth-child(even) .services_item {
  order: 2;
}
.service__:nth-child(even) .NO_order {
  order: 1;
}
@media (max-width: 991px) {
  .service__ {
    flex-wrap: wrap;
  }
  .service__ .NO_order,
  .service__ .services_item {
    width: 100% !important;
  }
  .service__:nth-child(even) .services_item {
    order: 1;
  }
  .service__:nth-child(even) .NO_order {
    order: 2;
  }
}

.custom-service-div-two {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  color: #fff;
  border: none;
  overflow: hidden;
  height: 300px;
  transition: 0.5s ease-in-out all;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent
      linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgb(0 0 0 / 94%) 100%) 0
      0 no-repeat padding-box;
  }
  .card-img {
    height: 100%;
    object-fit: cover;
  }
  img {
    transition: 0.5s ease-in-out all;
  }
  .card-img-overlay {
    top: auto;
    z-index: 9;
    padding: 0 30px 15px 30px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 1rem;
    border-radius: calc(0.25rem - 1px);
  }
  .text-start {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: start !important;
    padding: 5px 15px;
  }
  &:hover {
    img {
      transform: scale(1.5);
    }
  }
  p {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 30px;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  h5 {
    font-weight: bold;
    margin-bottom: 15px;
  }
}
.wrap_div_ {
  @media (max-width: 991px) {
    flex-wrap: wrap;
    gap: 8px !important;
  }
}
.see_more {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
