<template>
  <section class="favourite_page">
    <v-container>
      <div class="__bread mt-5">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <v-row>
        <v-col cols="12" md="3"> <filterFav /> </v-col>
        <v-col cols="12" md="9">
          <studios />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import studios from "./studios.vue";
import filterFav from "./filterFav.vue";
export default {
  components: {
    studios,
    filterFav
  },
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t("favorite"),
          disabled: true,
          to: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.favourite_page {
  height: 100%;
  background-color: #fafafa;
  padding: 0px 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }
  .__bread {
    box-shadow: none !important;
    .v-breadcrumbs {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: none !important;
      padding: 12px;
    }
    .v-breadcrumbs__item {
      color: #000 !important;
    }
    .v-breadcrumbs__item--disabled {
      color: #868e96 !important;
    }
  }
}
</style>
