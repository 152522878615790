import axios from 'axios'
const state = {
    projectDeatils: "",
    offerProjects: [],
    acceptOffer: "",
    rejectOffer: "",
    loading: false
}

const mutations = {
    SET_PROJECT_DETAILS(state, data) {
        state.projectDeatils = data
    },
    SET_OFFERS_PROJECT(state, data) {
        state.offerProjects = data
    },
    ACCEPT_OFFER_SUCCESS(state, data) {
        state.acceptOffer = data
    },
    ACCEPT_OFFER_ERROR(state, data) {
        state.acceptOffer = data
    },
    REJECT_OFFER_SUCCESS(state, data) {
        state.rejectOffer = data
    },
    REJECT_OFFER_ERROR(state, data) {
        state.rejectOffer = data
    },
}

const actions = {
    async getProjectDetails({ commit }, id) {
        axios.get(
            `project-details/${id}`).then(res => {
                this.projectDeatils = res.data.data
                commit('SET_PROJECT_DETAILS', res.data.data)
            })
    },
    async getOffersProjects({ commit }, id) {
        axios.get(`project-offers/${id}`).then(res => {
            this.offerProjects = res.data.data.data
            commit('SET_OFFERS_PROJECT', res.data.data.data)
        })
    },
    async acceptOffer({ commit }, payload) {
        axios.post('accept-offer', payload)
            .then(response => {
                commit('ACCEPT_OFFER_SUCCESS', response.data.message);
            })
            .catch(e => {
                commit('ACCEPT_OFFER_ERROR', e.response.data.message);
            });
    },
    async rejectOffer({ commit }, payload) {
        axios.post('reject-offer', payload)
            .then(response => {
                commit('REJECT_OFFER_SUCCESS', response.data.message);
            })
            .catch(e => {
                commit('REJECT_OFFER_ERROR', e.response.data.message);
            });
    }
}

const getters = {
    getAllProjectDetails: state => state.projectDeatils,
    getAllOffersProject: state => state.offerProjects,
    getMessage: state => state.acceptOffer,
    getMessageRej: state => state.rejectOffer
}

export default {
    state,
    mutations,
    actions,
    getters,
}