<template>
  <div>
    <!-- <v-alert
      dense
      color="#FFF3CD"
      class="py-3 px-3 alert_phone_ver"
      v-if="GetDataProfile.verify_mobile == false"
    >
      <div class="d-flex justify-content-between items-center flex-row flex-wrap" style="gap:2px">
        <span class="text-center mt-2"> لم يتم تأكيد رقم جوالك بعد </span>
        <a href="#mobile-tabs-5-2" @click="openMobileTab('mobile-tabs-5-2')">
          <v-btn color="#FFC107" depressed> تاكيد رقم جوالك </v-btn>
        </a>
      </div>  
    </v-alert> -->
    <div class="profile_">
      <v-row>
        <v-col cols="12" md="4">
          <div class="profile_sidebar">
            <div class="profile_data d-flex justify-content-center align-items-center flex-column" style="gap: 10px">
              <template v-if="GetDataProfile">
                <img :src="GetDataProfile.image_url" alt="img-profile" width="120" height="120"/>
                <h5>{{ GetDataProfile.first_name }} {{ GetDataProfile.last_name }}</h5>
              </template>
              <template v-else>
                <v-skeleton-loader type="image" width="120" height="120"
                                   style="border-radius: 120px;"></v-skeleton-loader>
                <v-skeleton-loader type="text" width="120"></v-skeleton-loader>
              </template>
            </div>
            <hr/>
            <div class="sidebar_tabs">
              <v-tabs v-model="tabs" vertical>
                <v-tab href="#mobile-tabs-5-1" class="primary--text">
                  {{ $t('personal-info-edit') }}
                </v-tab>

                <v-tab href="#mobile-tabs-5-2" class="primary--text">
                  {{ $t('mobile-edit') }}
                </v-tab>

                <v-tab href="#mobile-tabs-5-3" class="primary--text">
                  {{ $t('email-edit') }}
                </v-tab>
                <!--                <v-tab href="#mobile-tabs-5-4" class="primary&#45;&#45;text">-->
                <!--                  {{ $t('password-edit') }}-->
                <!--                </v-tab>-->
              </v-tabs>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <div class="profile_edit">
            <v-tabs-items v-model="tabs">
              <v-tab-item value="mobile-tabs-5-1">
                <v-card flat>
                  <v-card-text class="py-0">
                    <Profile/>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="mobile-tabs-5-2">
                <v-card flat>
                  <v-card-text class="py-0">
                    <phone :GetDataProfile="GetDataProfile"/>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="mobile-tabs-5-3">
                <v-card flat>
                  <v-card-text class="py-0">
                    <email/>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!--              <v-tab-item value="mobile-tabs-5-4">-->
              <!--                <v-card flat>-->
              <!--                  <v-card-text class="py-0">-->
              <!--                    <password/>-->
              <!--                  </v-card-text>-->
              <!--                </v-card>-->
              <!--              </v-tab-item>-->
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Profile from "./profile.vue";
import email from "./email.vue";
// import password from "./password.vue";
import phone from "./phone.vue";

export default {
  components: {
    Profile,
    email,
    // password,
    phone,
  },
  data() {
    return {
      tabs: null,
    };
  },
  computed: {
    ...mapGetters(["GetDataProfile"]),
  },
  created() {
    this.handleGetDataProfile();
  },
  methods: {
    ...mapActions(["handleGetDataProfile"]),
    // openMobileTab(tabId) {
    //   // Set the current active tab to the desired tabId
    //   this.tabs = tabId;
    // },
  },
};
</script>

<style scoped lang="scss">
.alert_phone_ver {
  button {
    letter-spacing: 0;
    color: #707070;
    font-size: 16px;
    opacity: 1;
  }
}

.profile_ {
  padding-bottom: 48px;

  .profile_sidebar {
    border: 1px solid #e8e9ea;
    padding: 15px;
    margin-top: 32px;
    border-radius: 4px;
    background-color: #fff;
    @media(max-width: 991px) {
      padding: 70px 0;
    }

    .profile_data {
      overflow-wrap: anywhere;

      img {
        object-fit: unset;
        border-radius: 50%;
        border: 1px solid #000
      }

      h5 {
        color: #000;
        font-weight: bold;
      }
    }

    hr {
      background-color: #e8e9ea;
      color: #e8e9ea;
    }

    .sidebar_tabs {
      .theme--light.v-tabs > .v-tabs-bar {
        background-color: transparent !important;
      }

      .v-slide-group__content {
        width: 100% !important;
      }

      .v-tab--active {
        height: 48px;
        text-align: center;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-inline-start: 16px;
        background-color: #000;
        color: #fff !important;
        font-weight: bold;
        border-radius: 6px;
      }

      .v-tab {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #707070;
        font-size: 17px;
        margin: 3px 0;
        width: 100%;
      }
    }
  }
}

.profile_edit {
  margin-top: 32px
}
</style>
