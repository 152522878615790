<template>
  <div class="add_project">
    <div class="btns__add">
       
      <v-btn @click="editProject = true">
        <v-icon color="#000"> mdi-square-edit-outline </v-icon>
        تعديل المشروع
      </v-btn>
      <v-dialog v-model="editProject" max-width="800px">
        <v-card class="add_offer_dialog">
          <v-card-title class="font-weight-bold">
            تعديل المشروع
          </v-card-title>
          <hr />
          <v-card-text>
            <v-container>
              <form action="">
                <v-row>
                  <v-col cols="12" md="12">
                    <div
                      class="d-flex justify-content-start align-start flex-column gap-2"
                      style="height: 65px"
                    >
                      <span class="title_input">
                        {{ $t("projects.title_project") }}
                      </span>
                      <v-text-field
                        v-model="getAllProjectDetails.name"
                        :placeholder="$t('projects.title_project')"
                        required
                        outlined
                        style="width: 100%; height: 35px"
                        :error-messages="nameErrors"
                        @input="$v.getAllProjectDetails.name.$touch()"
                        @blur="$v.getAllProjectDetails.name.$touch()"
                      >
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" md="12">
                    <div
                      class="d-flex justify-content-start flex-column gap-2"
                      style="height: 65px"
                    >
                      <span class="title_input text-start">
                        {{ $t("projects.execution") }}
                      </span>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="getAllProjectDetails.display_date"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="dateErrors"
                            @input="$v.getAllProjectDetails.display_date.$touch()"
                            @blur="$v.getAllProjectDetails.display_date.$touch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          :active-picker.sync="activePicker"
                          :min="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div
                      class="d-flex justify-content-start flex-column gap-2"
                      style=""
                    >
                      <span class="title_input text-start">
                        {{ $t("projects.city") }}
                      </span>
                      <v-autocomplete
                        v-model="getAllProjectDetails.city_id"
                        :items="citiesItems"
                        :item-text="(city) => city.name"
                        :item-value="(city) => city.id"
                        outlined
                        chips
                        clearable
                        deletable-chips
                        dense
                        small-chips
                        :error-messages="citiesErrors"
                        @input="$v.getAllProjectDetails.city_id.$touch()"
                        @blur="$v.getAllProjectDetails.city_id.$touch()"
                      ></v-autocomplete>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div
                      class="d-flex justify-content-start flex-column gap-2"
                      style=""
                    >
                      <span class="title_input text-start">
                        {{ $t("projects.gender") }}
                      </span>
                      <v-autocomplete
                        v-model="getAllProjectDetails.kind"
                        :items="gender"
                        :item-text="(type) => type.name"
                        :item-value="(type) => type.value"
                        outlined
                        chips
                        clearable
                        deletable-chips
                        dense
                        small-chips
                        :error-messages="typeErrors"
                        @input="$v.getAllProjectDetails.kind.$touch()"
                        @blur="$v.getAllProjectDetails.kind.$touch()"
                      ></v-autocomplete>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div
                      class="d-flex justify-content-start flex-column gap-2"
                      style=""
                    >
                      <span class="title_input text-start">
                        {{ $t("projects.main_ser") }}
                      </span>
                      <v-autocomplete
                        v-model="getAllProjectDetails.main_category_id"
                        :items="servicesItems"
                        :item-text="(ser) => ser.name_ar"
                        :item-value="(ser) => ser.id"
                        outlined
                        chips
                        clearable
                        deletable-chips
                        dense
                        small-chips
                        :error-messages="servicesErrors"
                        @input="$v.getAllProjectDetails.main_category_id.$touch()"
                        @blur="$v.getAllProjectDetails.main_category_id.$touch()"
                        @change="getSub"
                      ></v-autocomplete>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div
                      class="d-flex justify-content-start flex-column gap-2"
                      style=""
                    >
                      <span class="title_input text-start">
                        {{ $t("projects.subcatg") }}
                      </span>
                      <v-autocomplete
                        v-model="getAllProjectDetails.sub_category_id"
                        :items="subCatg"
                        :item-text="(catg) => catg.name"
                        :item-value="(catg) => catg.id"
                        outlined
                        chips
                        clearable
                        deletable-chips
                        dense
                        small-chips
                        :error-messages="categoriesErrors"
                        @input="$v.getAllProjectDetails.sub_category_id.$touch()"
                        @blur="$v.getAllProjectDetails.sub_category_id.$touch()"
                      ></v-autocomplete>
                    </div>
                  </v-col>
                  <v-col cols="12" md="12">
                    <div
                      class="d-flex justify-content-start align-start flex-column gap-2 mt-5"
                    >
                      <span class="title_input">
                        {{ $t("projects.details") }}
                      </span>
                      <v-textarea
                        style="width: 100%; height: 100px"
                        required
                        outlined
                        v-model="getAllProjectDetails.description"
                        :error-messages="detailsErrors"
                        @input="$v.getAllProjectDetails.description.$touch()"
                        @blur="$v.getAllProjectDetails.description.$touch()"
                        rows="3"
                      ></v-textarea>
                    </div>
                  </v-col>
                  <!-- <v-col cols="12" md="12">
                    <div
                      class="d-flex justify-content-start align-start flex-column gap-2 files__"
                    >
                      <span class="title_input">
                        {{ $t("projects.main_ser") }}
                      </span>
                      <span> {{ $t("projects.giga") }} </span>
                      <v-file-input
                        type="file"
                        multiple
                        accept="image/png, image/jpeg, image/jpg"
                        @change="handleFilesProject"
                        required
                      >
                      </v-file-input>
                      <div v-if="editProject.files" class="media_view">
                        <div
                          v-for="(file, index) in editProject.files"
                          :key="file.id"
                          class="media_view_item"
                        >
                          <template>
                            <img :src="file.url" alt="Image" />
                          </template>
                          <div>
                            <v-icon @click="deleteGifts(index)" color="red">
                              mdi-delete
                            </v-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col> -->
                </v-row>
              </form>
            </v-container>
          </v-card-text>
          <hr />
          <v-card-actions class="pr-5">
            <v-btn
              class="btn add_offer"
              :loading="loading"
              @click="addProjectForm"
            >
              <v-icon> mdi-send-outline </v-icon> {{ $t("projects.submit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [validationMixin],
  validations: {
    getAllProjectDetails: {
      name: { required },
      display_date: { required },
      city_id: { required },
      kind: { required },
      main_category_id: { required },
      sub_category_id: { required },
      description: { required },
    },
  },
  data() {
    return {
      editProject: false,
      editProject: {
        name: "",
        cities: "",
        services: "",
        categories: "",
        type: "",
        details: "",
        files: [],
        date: null,
      },
      activePicker: null,
      menu: false,
      items: ["foo", "bar", "fizz", "buzz"],
      servicesItems: [],
      citiesItems: [],
      listProjectsItems: [],
      subCatg: [],
      gender: [
        {
          id: 1,
          name: "ذكر",
          value: "male",
        },
        {
          id: 2,
          name: "أنثى",
          value: "female",
        },
        {
          id: 3,
          name: "كلاهما",
          value: "both",
        },
      ],
      loading: false,
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.getAllProjectDetails.name.$dirty) return errors;
      !this.$v.getAllProjectDetails.name.required && errors.push("name is required.");
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.getAllProjectDetails.display_date.$dirty) return errors;
      !this.$v.getAllProjectDetails.display_date.required && errors.push("date is required.");
      return errors;
    },
    citiesErrors() {
      const errors = [];
      if (!this.$v.getAllProjectDetails.city_id.$dirty) return errors;
      !this.$v.getAllProjectDetails.city_id.required && errors.push("city is required.");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.getAllProjectDetails.kind.$dirty) return errors;
      !this.$v.getAllProjectDetails.kind.required && errors.push("type is required.");
      return errors;
    },
    servicesErrors() {
      const errors = [];
      if (!this.$v.getAllProjectDetails.main_category_id.$dirty) return errors;
      !this.$v.getAllProjectDetails.main_category_id.required &&
        errors.push("services is required.");
      return errors;
    },
    categoriesErrors() {
      const errors = [];
      if (!this.$v.getAllProjectDetails.sub_category_id.$dirty) return errors;
      !this.$v.getAllProjectDetails.sub_category_id.required &&
        errors.push("categories is required.");
      return errors;
    },
    detailsErrors() {
      const errors = [];
      if (!this.$v.getAllProjectDetails.description.$dirty) return errors;
      !this.$v.getAllProjectDetails.description.required &&
        errors.push("details is required.");
      return errors;
    },
    // filesErrors() {
    //   const errors = [];
    //   if (!this.$v.editProject.files.$dirty) return errors;
    //   !this.$v.editProject.files.required && errors.push("name is required.");
    //   return errors;
    // },
    ...mapGetters(["getAllProjectDetails"]),
    selectedCity: {
      get() {
        return this.citiesItems.find((city) => city.id === this.getAllProjectDetails.city);
      },
      set(city) {
        this.getAllProjectDetails.city = city.id;
      },
    },
  },
  created() {
    axios
      .get("projects", {})
      .then(({ data }) => {
        this.listProjectsItems = data.data.data;
        this.servicesItems = data.additional.sections;
        this.citiesItems = data.additional.cities;
      })
      .catch((err) => {});
      const id = this.$route.params.id;
    this.getProjectDetails(id);
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions(["getProjectDetails"]),
    getSub() {
      const sectionID = this.getAllProjectDetails.main_category_id;
      axios.get(`section-categories/${sectionID}`).then((res) => {
        this.subCatg = res.data.data;
      });
    },
    deleteGifts(index) {
      this.editProject.files.splice(index, 1);
    },
    handleFilesProject() {
      this.newFiles = event.target.files;
      this.editProject.files = this.editProject.files || [];
      for (let i = 0; i < this.newFiles.length; i++) {
        const file = this.newFiles[i];
        const url = URL.createObjectURL(file);
        this.editProject.files.push({ name: file.name, url, file });
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    addProjectForm() {
      const PAYLOAD = {
        project_id: this.$route.params.id,
        name: this.getAllProjectDetails.name,
        description: this.getAllProjectDetails.description,
        done_datetime: this.getAllProjectDetails.display_date,
        city_id: this.getAllProjectDetails.city_id,
        main_category_id: this.getAllProjectDetails.main_category_id,
        sub_category_id: this.getAllProjectDetails.sub_category_id,
        kind: this.getAllProjectDetails.kind,
        // project_for: this.editProject.project_for,
        // files: this.editProject.files,
      };
      // const dataToFormData = (data) => {
      //   const formData = new FormData();
      //   Object.entries(data).forEach(([key, val]) => {
      //     if (Array.isArray(val)) {
      //       val.forEach((v, ix) => {
      //         const isFile = key === "files" && v.file;
      //         formData.append(`${key}[${ix}]`, isFile ? v.file : v);
      //       });
      //     } else {
      //       formData.append(key, val);
      //     }
      //   });
      //   return formData;
      // };
      const projectData = { ...PAYLOAD };
      // const formProjectData = dataToFormData(projectData);
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      axios
        .post("update-project", projectData)
        .then((res) => {
          this.loading = false;
          this.editProject = false;
          this.editProject.name = "";
          this.editProject.details = "";
          this.editProject.date = "";
          this.editProject.cities = "";
          this.editProject.services = "";
          this.editProject.categories = "";
          this.editProject.files = [];
          this.TriggerNotify("success", res.data.message);
        })
        .catch((e) => {
          this.TriggerNotify("error", e.response.data.message);
          // this.TriggerNotify("error", e.response.data.errors[0]);
          this.TriggerNotify("error", e.response.data.error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns__add {
  button {
    background-color: #e0e0e0 !important;
    box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
    border-radius: 4px;
    color: #4d4d4d;
    font-size: 0.875rem;
    height: 44px !important;
    line-height: 44px;
    letter-spacing: 0;
    font-size: 16px;
    i {
      margin-inline-end: 5px;
    }
  }
  i {
    color: #000 !important;
  }
}
.add_offer {
  background-color: #000 !important;
  color: #fff !important;
  text-align: center;
  border-radius: 4px;
  height: 45px !important;
  i {
    font-size: 20px;
    margin-inline-end: 5px;
    color: #fff !important;
  }
}
.title_input {
  font-weight: bold;
  color: var(--primary-color);
}
.add_project {
  .v-text-field.v-text-field--enclosed {
    width: 100% !important;
  }
}
.media_view {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  flex-direction: row;
}
.media_view img {
  width: 100px;
  object-fit: contain;
}
.files__ {
  .v-input__slot {
    border: 1px solid #c2c2c2;
    padding: 2px 8px;
    border-radius: 4px;
  }
  .v-input__slot:before {
    border-color: rgb(194 194 194);
  }
  .v-input {
    width: 100%;
  }
}
.v-card__text {
  padding: 0 0 20px !important;
}
.mdi-close-circle::before {
  margin-inline-start: 15px;
}
</style>
