import axios from 'axios'

const state = {
    studiorderDetails: "",
    cancelData: "",
    updateData: "",
    paymentUrl: "",
    reviewData: "",
    errorMessage: "",
    loading: false
}
const getters = {
    getOrderStudioDeatils: state => state.studiorderDetails,
    getCancelData: state => state.cancelData,
    getUpdateData: state => state.updateData,
    getReviewData: state => state.reviewData,
    getPaymentUrl: state => state.paymentUrl,
    geterrorMessage: state => state.errorMessage,
}
const mutations = {
    SET_ORDER_STUDIO(state, studiorderDetails) {
        state.studiorderDetails = studiorderDetails
    },
    SET_CANCEL_DATA(state, cancelData) {
        state.cancelData = cancelData
    },
    SET_UPDATE_ORDER(state, updateData) {
        state.updateData = updateData
    },
    SET_DATA_REVIEW(state, reviewData) {
        state.reviewData = reviewData
    },
    PAYMENT_URL(state, paymentUrl) {
        state.paymentUrl = paymentUrl
    },
    SET_PAYMENT_ERROR(state, errorMessage) {
        state.errorMessage = errorMessage;
    },
}
const actions = {
    async getOrderStudioDetails({commit}, id) {
        return axios.get(`studio-order-details/${id}`).then((res) => {
            commit('SET_ORDER_STUDIO', res.data.data)
        }).catch(() => {
            window.location.href = '/';
        });
    },
    async cancelOrder({commit}, payload) {
        return axios.post('studio-order-cancel', payload).then((res) => {
            commit('SET_CANCEL_DATA', res.data.message)
        })
    },
    async updateStudio({commit}, payload) {
        return axios.post('update-studio-order', payload).then((res) => {
            commit('SET_UPDATE_ORDER', res.data.message)
        })
    },
    async ReviewStudio({commit}, payload) {
        return axios.post('studio-rate', payload).then((res) => {
            commit('SET_DATA_REVIEW', res.data.message)
        })
    },
    async paymentOrder({commit}, payload) {
        try {
            const res = await axios.post('initiate-payment', payload);
            commit('PAYMENT_URL', res.data.data.payment_url);
            return res; // Return the response object
        } catch (error) {
            commit('SET_PAYMENT_ERROR', error.response.data.message);
            throw error;
        }
    }

}

export default {
    state,
    getters,
    mutations,
    actions
}