<template>
  <section>
    <div v-if="loading">
      <v-skeleton-loader type="list-item-three-line" v-for="n in 7"></v-skeleton-loader>
    </div>
    <div v-else>
      <div class="notification_dd" v-if="getAllNotifications.length > 0">
        <h6>{{ $t("footer.notifications") }}</h6>
        <ul class="notification_ul pl-0">
          <li :style="[noti.if_read ? {'background-color': 'white'} : {'background-color': '#eee'}]"
              class="d-flex justify-content-between align-items-center flex-row"
              v-for="(noti, index) in getAllNotifications" :key="index">
            <router-link :to="'/' + noti.url" class="d-flex justify-content-between align-items-start flex-row w-100">
              <div>
                <h4>
                  <span class="mdi mdi-check-all" :class="[noti.if_read ? 'text-primary' : '']"></span>
                  {{ noti.title }}
                </h4>
                <span class="content">{{ noti.body }}</span>
              </div>
              <span class="time">{{ noti.humantime }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div v-else class="d-flex justify-content-start align-items-start flex-column w-100"
           style="gap: 15px">
        <!-- <img src="../../../public/img/no-noti.png" alt="no-noti" width="300" height="300" class="object-fit-contain" /> -->
        <NoData/>
      </div>
    </div>
  </section>
</template>

<script>
import NoData from "../../views/no-data.vue"
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    NoData
  },
  data() {
    return {
      'loading': false
    }
  },
  computed: {
    ...mapGetters(["getAllNotifications"]),
  },
  async created() {
    this.loading = true;
    await this.GetAllNotifications();
    this.loading = false;
  },
  methods: {
    ...mapActions(["GetAllNotifications"]),
  },
};
</script>

<style scoped lang="scss">
.notification_dd {
  user-select: none;
  background: transparent;
  width: 100%;
  height: auto;
  display: block;
  border-radius: 3px;

  h6 {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 10px;
    text-align: start;
  }

  li {
    margin-bottom: 10px;
    padding: 25px 15px;
    border-radius: 8px;

    h4 {
      font-size: 20px;
      text-align: start;
    }

    span {
      color: #212121;

      &.content {
        // width: calc(100% - 100px);
        white-space: pre-wrap;
        text-align: start;
        letter-spacing: 0;
        font-size: 17px;
      }

      &.time {
        font-size: 17px;
      }
    }

    span:last-of-type {
      color: #0000008a;
    }

    &:hover {
      background-color: rgba(126, 126, 127, 0.08) !important;
    }
  }
}
</style>
