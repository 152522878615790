import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import MetaTags from './components/meta-tags.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import home from './store/home'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import carousal from "./plugins/vue-carousal"
import vuetify from './plugins/vuetify'
import vueToast from './plugins/vue-toast'
import i18n from './plugins/i18n'
import wow from './plugins/wow'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSignaturePad from 'vue-signature-pad';
// import firebase from './plugins/firebase'
import '@mdi/font/css/materialdesignicons.css'
import VueMeta from 'vue-meta';
// ----------------------------------------------------------------------------------------------------------------------------
import firebase from 'firebase/app';
import 'firebase/messaging';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDWepZcYj9xcY_D47Xnq_vgSxkifDSZM1w",
    authDomain: "sin-studio-owners.firebaseapp.com",
    projectId: "sin-studio-owners",
    storageBucket: "sin-studio-owners.appspot.com",
    messagingSenderId: "291445398675",
    appId: "1:291445398675:web:e6012109480bc8bad78166",
    measurementId: "G-LX3286YFBT"
};
firebase.initializeApp(firebaseConfig);
// ----------------------------------------------------------------------------------------------------------------------------
let myBaseUrl;
if (process.env.NODE_ENV === 'development') {
    // local API
    // myBaseUrl = 'http://127.0.0.1:8000/api/v1/';
    myBaseUrl = 'http://sinbackend.test/api/v1/';
} else {
    myBaseUrl = (['seenpark.sa', 'www.seenpark.sa'].includes(window.location.hostname)) ? 'https://dashboard.seenpark.sa/api/v1/' : 'https://sinback.bb4itdev.com/api/v1/';
}
axios.defaults.baseURL = myBaseUrl;
// ----------------------------------------------------------------------------------------------------------------------------
//Switch language
const lang = localStorage.getItem('lang') || 'ar';
axios.defaults.headers['lang'] = lang;
document.documentElement.lang = lang;
// ----------------------------------------------------------------------------------------------------------------------------


// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title || '';
//   next();
// });

new Vue({
    store,
    render: h => h(MetaTags)
}).$mount('#head-tags');

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueMeta);
Vue.use(VueGoogleMaps, {
    load: {
        // key: 'AIzaSyAN97fEohPIbz0vWEmqEtETCZfSMjDbUdE', // seen key
        key: 'AIzaSyBcJPAOTECnG_KvG4F0m4H29s_K4xkV1DA', // alkhomry key
        region: lang.toUpperCase(),
        language: lang,
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
});
Vue.use(VueSignaturePad);
new Vue({
    router,
    store,
    home,
    vuetify,
    i18n,
    wow,
    carousal,
    vueToast,
    firebase,
    render: h => h(App)
}).$mount('#app');
