<template>
  <div>
    <section class="order_details_sec"
             v-if="loadingData">
      <v-container>
        <div class="__bread mt-16 mt-md-0">
          <v-breadcrumbs :items="Bread"></v-breadcrumbs>
        </div>
        <div>
          <div class="status_isReview" v-if="getOrderStudioDeatils.status === 'wait'">
            <v-alert color="#CCE5FF" class="text-start p-3">{{ $t('status-is-wait-alert') }}</v-alert>
          </div>

          <div class="status_isReview mt-4 mb-16" v-if="getOrderStudioDeatils.status === 'open'">
            <v-alert color="#FFEEBA">
              <div class="text-start p-1 d-flex justify-content-between align-items-center flex-row flex-wrap w-100">
                <span>
                  {{ $t('studios-of-agree') }}
                  {{ getOrderStudioDeatils.studio_name }}
                  {{ $t('please-pay-to-confirm-order') }}
                </span>
                <router-link class="btn btn-warning" v-if="payment_type === 'e_pay'"
                             :to="{ name: 'studioOrdersDetailsPayment', params: { id: getOrderStudioDeatils.id } }">
                  {{ $t('pay-now') }}
                </router-link>
              </div>
            </v-alert>
          </div>

          <div
              class="order_title d-flex justify-content-start align-start flex-column flex-wrap mt-3"
          >
            <div
                class="d-flex justify-content-start align-start flex-column flex-wrap"
            >
              <h3 class="font-bold text-start">{{ getOrderStudioDeatils.studio_name }}</h3>
            </div>
            <div class="order_status d-flex justify-content-between align-center flex-row  w-100">
              <span class=" text-start">
                {{ getOrderStudioDeatils.studio.category_name }}
              </span>
              <v-chip label color="#FC7D12" style="color: #fff !important"
                      v-if="getOrderStudioDeatils.status === 'wait'">
                {{ $t('status-wait') }}
              </v-chip>
              <v-chip label color="#2E8B57" style="color: #fff !important"
                      v-if="getOrderStudioDeatils.status === 'open'">
                {{ $t('status-open') }}
              </v-chip>
              <v-chip label color="#000" style="color: #fff !important"
                      v-if="getOrderStudioDeatils.status === 'inprogress'">
                {{ $t('status-working') }}
              </v-chip>
              <v-chip label color="#2A3B4E" style="color: #fff !important"
                      v-if="getOrderStudioDeatils.status === 'completed'">
                {{ $t('status-completed') }}
              </v-chip>
              <v-chip label color="#949494" style="color: #fff !important"
                      v-if="getOrderStudioDeatils.status === 'cancelled'">
                {{ $t('status-cancelled') }}
              </v-chip>
              <v-chip label color="#ff3636" style="color: #fff !important"
                      v-if="getOrderStudioDeatils.status === 'rejected'">
                {{ $t('status-rejected') }}
              </v-chip>
            </div>
          </div>

          <hr style="margin-top:12px;margin-bottom: 24px;"/>

          <!-- buttons -->
          <div class="d-flex justify-content-start align-items-center flex-row flex-wrap gap-2">
            <editBook :studio_id="getOrderStudioDeatils.studio_id" :getOrderStudioDeatils="getOrderStudioDeatils"
                      v-if="['wait', 'open'].includes(getOrderStudioDeatils.status)"/>
            <cancelBook v-if="getOrderStudioDeatils.status === 'wait' || getOrderStudioDeatils.status === 'open'"/>
            <review
                v-if="getOrderStudioDeatils.status === 'completed' && getOrderStudioDeatils.rate_status"
                :getOrderStudioDeatils="getOrderStudioDeatils"/>

            <button class="btn btn-chat d-inline-flex justify-center align-center" @click="openChat('admin')">
              <v-icon>mdi-chat-processing-outline</v-icon>
              {{ $t("admin-chat") }}
            </button>
            <button class="btn btn-chat d-inline-flex justify-center align-center" @click="openChat('member')">
              <v-icon>mdi-chat-processing-outline</v-icon>
              {{ $t("provider-chat") }}
            </button>
          </div>

          <v-row>
            <v-col cols="12" md="8" sm="12" xs="12">
              <div class="order_details p-4 mt-5">
                <h3>{{ $t('project-details') }}</h3>
                <hr style="width: 100%"/>
                <p v-html="getOrderStudioDeatils.studio.description">
                </p>
                <ul class="list_studio_details">
                  <li
                      class="d-flex justify-content-start align-items-start gap-5 flex-row"
                  >
                    <span> {{ $t('space') }} </span>
                    <span> {{ getOrderStudioDeatils.studio.name }} </span>
                  </li>
                  <li
                      class="d-flex justify-content-start align-items-start gap-5 flex-row"
                  >
                    <span> {{ $t('studios.city') }} </span>
                    <span> {{ getOrderStudioDeatils.studio.city_name }} </span>
                  </li>
                  <li
                      class="d-flex justify-content-start align-items-start gap-5 flex-row"
                  >
                    <span> {{ $t('studios.space') }} </span>
                    <span>
                      {{ getOrderStudioDeatils.studio.area_in_metres }}
                      {{ $t('studios.meter') }}</span
                    >
                  </li>
                </ul>
                <div class="__studio_imgs mt-3">
                  <div>
                    <img
                        :src="getOrderStudioDeatils.studio.main_image_url"
                        height="115"
                        width="200"
                        alt=""
                    />
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <div class="order_info p-4 mt-5">
                <h3>{{ $t('order-info') }}</h3>
                <hr style="width: 100%"/>
                <ul class="d-flex justify-content-start align-items-start flex-column flex-wrap gap-3"
                    style="width: 100% !important">
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span>{{ $t('order-number') }}</span>
                    <span>{{ getOrderStudioDeatils.sku }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('order-date') }} </span>
                    <span>{{ getOrderStudioDeatils.order_date }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('studios.StartDate') }} </span>
                    <span>{{ getOrderStudioDeatils.from_date }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('studios.endDate') }} </span>
                    <span>{{ getOrderStudioDeatils.to_date }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('studios.StartTime') }} </span>
                    <span>{{ getOrderStudioDeatils.from_time }} {{ $t(getOrderStudioDeatils.from_time_am_pm) }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('studios.EndTime') }} </span>
                    <span>{{ getOrderStudioDeatils.to_time }} {{ $t(getOrderStudioDeatils.to_time_am_pm) }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('reservation-total-' + getOrderStudioDeatils.rent_type + 's') }} </span>
                    <span>{{ getOrderStudioDeatils.total_rent_count }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span class="text-start">
                      {{ $t('studios.insurance_amount') }}
                      <br>
                      <span class="text-danger">
                        {{ getOrderStudioDeatils.insurance_statement }}
                      </span>
                    </span>
                    <span>{{ getOrderStudioDeatils.insurance_amount }}</span>
                  </li>

                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('cost') }} </span>
                    <span>{{ getOrderStudioDeatils.total_price }} {{ $t("studios.ryal") }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('total-facilities-price') }} </span>
                    <span>{{ getOrderStudioDeatils.total_facilities_price }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important"
                      v-if="getOrderStudioDeatils.coupon_id > 0 || getOrderStudioDeatils.discount_money > 0">
                    <span>{{ $t('discount-money') }}&nbsp;
                      {{ '( ' + getOrderStudioDeatils.discount_percent + ' % )' }}
                    </span>
                    <span>{{ getOrderStudioDeatils.discount_money }} {{ $t("studios.ryal") }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('final-price') }} </span>
                    <span>{{ getOrderStudioDeatils.final_price }} {{ $t("studios.ryal") }}</span>
                  </li>

                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important" v-if="getOrderStudioDeatils.status === 'cancelled'">
                    <span> {{ $t('cancel-reason') }} </span>
                    <span>{{ getOrderStudioDeatils.cancel_reason }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important" v-if="getOrderStudioDeatils.status === 'rejected'">
                    <span> {{ $t('refuse-reason') }} </span>
                    <span>{{ getOrderStudioDeatils.reject_reason }}</span>
                  </li>

                  <li v-if="getOrderStudioDeatils.invoice_url"
                      class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important"
                  >
                    <span>{{ $t('invoice') }}</span>
                    <span>
                      <a :href="getOrderStudioDeatils.invoice_url" target="_blank"
                         class="text-success font-weight-bold text-decoration-underline">
                        {{ $t('show') }}
                      </a>
                    </span>
                  </li>
                </ul>
              </div>

              <div class="order_info p-4 mt-5">
                <h3>{{ $t('payment-details') }}</h3>
                <hr style="width: 100%"/>
                <ul class="d-flex justify-content-start align-items-start flex-column flex-wrap gap-3"
                    style="width: 100% !important">
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span>{{ $t('payment-type') }}</span>
                    <span>{{ getOrderStudioDeatils.custom_order_pay_type }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('deposit-money') }} </span>
                    <span>{{ getOrderStudioDeatils.deposit_money }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('rest-money') }} </span>
                    <span>{{ getOrderStudioDeatils.rest_money }}</span>
                  </li>
                </ul>
              </div>

              <div class="order_info p-4 mt-5" v-if="getOrderStudioDeatils.status === 'inprogress'">
                <h3>{{ $t('space-owner-info') }}</h3>
                <hr style="width: 100%"/>
                <ul class="d-flex justify-content-start align-items-start flex-column flex-wrap gap-3"
                    style="width: 100% !important">
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span>{{ $t('contactUs.name') }}</span>
                    <span>{{ getOrderStudioDeatils.studio.member_name }}</span>
                  </li>
                  <li class="d-flex justify-content-between align-items-start flex-row flex-wrap gap-3"
                      style="width: 100% !important">
                    <span> {{ $t('auth.phone') }} </span>
                    <span>{{ getOrderStudioDeatils.studio.member_mobile }}</span>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <div v-else>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line"
      ></v-skeleton-loader>
    </div>
    <v-dialog v-model="successPayment" width="500">
      <v-alert type="success"> {{ $t('paid-successfully') }}</v-alert>
    </v-dialog>
    <v-dialog v-model="failedPayment" width="500">
      <v-alert type="error">{{ $t('sorry-payment-failed') }}</v-alert>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import editBook from "./studios-btns-comp/edit.vue";
import cancelBook from "./studios-btns-comp/cancel.vue";
import review from "./studios-btns-comp/review.vue";
// import payment from "./studios-btns-comp/payment.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    editBook,
    cancelBook,
    review,
    // support,
    // payment,
  },
  data() {
    return {
      attrs: {
        class: "mb-2",
        boilerplate: true,
      },
      successPayment: false,
      failedPayment: false,
      payment_type: 'e_pay',
      loadingData: false
    };
  },
  computed: {
    Bread() {
      return [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("my-orders"),
          disabled: false,
          href: "/orders",
        },
        {
          text: this.getOrderStudioDeatils.studio_name,
          disabled: true,
          href: "",
        },
      ];
    },
    ...mapGetters(["getOrderStudioDeatils"]),
  },
  async created() {
    const id = this.$route.params.id;
    await this.getOrderStudioDetails(id);
    this.loadingData = true;

    const params = new URLSearchParams(window.location.search);
    const message = params.get("message");
    if (message) {
      if (message === "APPROVED") {
        this.successPayment = true;
      } else if (message === "Successful") {
        this.successPayment = true;
      } else if (message === "Failure") {
        this.failedPayment = true;
      } else if (message.includes("DECLINED_INVALID_PIN")) {
        this.failedPayment = true;
      }
    }
  },
  methods: {
    ...mapActions(["getOrderStudioDetails"]),
    async openChat(type) {
      try {
        this.loading = true;
        let sendData = new FormData();
        sendData.append('chat_type', 'with_' + type);
        sendData.append('chat_type_id', 1);
        if (type === 'member') {
          sendData.append('chat_type_id', this.getOrderStudioDeatils.studio.member_id ?? 0);
        }

        await axios.post("open-chat", sendData)
            .then((res) => {
              this.loading = false;
              let chat_id = res.data.data.chat_details.chat_id ?? 0;
              this.$router.push({name: 'chatDetails', params: {id: chat_id}});
            })
            .catch((e) => {
              this.TriggerNotify('error', e.response.data.message);
              this.loading = false;
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.__bread {
  box-shadow: none !important;
  margin-bottom: 24px;

  .v-breadcrumbs {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none !important;
    padding: 12px;
    text-align: start;
  }

  .v-breadcrumbs__item {
    color: #000 !important;
  }

  .v-breadcrumbs__item--disabled {
    color: #868e96 !important;
  }
}

.status_isReview {
  button {
    box-shadow: none !important;
    letter-spacing: 0;
    background-color: #ffc107;
    padding: 5px 12px;
    border-radius: 5px;
  }
}

.order_details {
  .order_title {
    h3 {
      color: #4d4d4d;
      font-size: 25px;
      font-weight: bold;
    }

    span {
      color: #6c757d !important;
    }
  }

  .list_studio_details {
    width: 100%;
    margin-top: 5px;

    li {
      width: 100%;
      padding: 15px;
    }

    li:nth-child(odd) {
      background-color: #fff;
      color: #707070;
    }

    li:nth-child(even) {
      background-color: #f7f7f7;
      color: #707070;
    }

    span {
      color: #707070;
    }
  }

  .__studio_imgs {
    overflow-y: hidden;
    overflow-x: scroll;
    max-height: 95px;
    display: flex;
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    flex-direction: row;
    max-width: 100%;

    img {
      width: 130px;
      height: 80px;
      // object-fit: contain;
      object-position: center;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      margin-left: 5px;
      margin-bottom: 5px;
      background-color: #fff;
    }
  }
}

.orders_buttons {
  button {
    background-color: #e0e0e0 !important;
    box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
    border-radius: 4px;
    color: #4d4d4d;
    font-size: 0.875rem;
    height: 44px !important;
    line-height: 44px;
    letter-spacing: 0;
    font-size: 16px;

    i {
      margin-inline-end: 5px;
    }
  }
}

.order_details,
.order_info {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #e8e9ea;
  border-radius: 4px;
  padding: 24px;
  font-size: 14px;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-color: #e8e9ea !important;

  h3 {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 21px;
  }

  p {
    text-align: start;
    line-height: 1.8;
    font-size: 16px;
  }
}

.order_info {
  ul {
    li {
      span {
        color: #000;
      }

      span:last-of-type {
        color: #868e96;
      }
    }
  }
}

.order_details_sec {
  background-color: #fafafa;
  padding: 0px 0;
  margin-bottom: 25px;
}

.v-alert {
  margin-bottom: 24px !important;
}

.btn-chat {
  background-color: #e0e0e0 !important;
  box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
  border-radius: 4px;
  color: #4d4d4d;
  height: 44px !important;
  line-height: 44px;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;

  i {
    margin-inline-end: 5px;
    color: #4d4d4d !important;
  }
}
</style>