<template>
  <div class="fav_studios">
    <div class="section_title text-start">
      <h4 class="font-weight-bold">{{ $t('Breadcrumb.stdios') }}</h4>
      <hr/>
    </div>
    <v-row v-if="GetListFavs.length > 0">
      <v-col cols="12" sm="6" v-for="(list , index) in GetListFavs" :key="index">
        <router-link :to="{ name: 'studiosDetails', params: { id: list.id } }">
          <div class="studios_lists__item">
            <div class="studios_lists__item__img">
              <img :src="list.main_image_url" :alt="list.name"/>
            </div>
            <div class="studios_rate d-flex justify-content-center align-start gap-1" v-if="list.totalRates > 0">
              <v-rating v-model="list.totalRates" background-color="white" color="yellow accent-4" dense size="18"
                        length="1"></v-rating>
              <p>{{ list.totalRates }}</p>
            </div>
            <div class="studios_info d-flex justify-content-start text-start flex-column flex-wrap">
              <span class="title_"> {{ list.name }}</span>
              <div class="d-flex justify-content-between align-center flex-row flex-wrap">
                <span class="catg__"> {{ list.city_name }} - {{ list.category_name }} </span>
                <span class="price__"> {{ list.rent_price }} </span>
              </div>
            </div>
          </div>
        </router-link>
      </v-col>
    </v-row>
    <div v-if="GetListFavs.length == 0 && !Loading">
      <NoData/>
    </div>
    <v-row v-if="Loading">
      <v-col cols="12" md="6">
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NoData from '@/views/no-data.vue'
import {mapGetters, mapState} from "vuex";

export default {
  components: {
    NoData
  },
  data() {
    return {
      totalRates: 4,
      options: {
        loop: true,
        margin: 25,
        perPage: 0,
        autoplay: true,
        autoplayTimeout: 3000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        touchDrag: true,
        responsive: true,
        navigationEnabled: true,
        perPageCustom: [
          [0, 1],
          [480, 1],
          [768, 2],
          [1000, 3],
          [1200, 3],
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['GetListFavs', 'Loading']),
    ...mapState(['loading'])
  }
};
</script>

<style lang="scss" scoped>
.fav_studios {
  padding: 30px 0;

  .studios_lists__item {
    position: relative;
    border-radius: 10px;
    height: 250px;
    margin: 0 10px;

    .studios_lists__item__img {
      border-radius: 4px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(180deg, #ffffff00 0, #000000 100%) 0 0 no-repeat padding-box;
        border-radius: 4px;
      }

      img {
        width: 100%;
        border-radius: 10px;
        height: 250px;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    .studios_rate {
      position: absolute;
      left: 15px;
      top: 10px;
      background: #00000080 0 0 no-repeat padding-box;
      border-radius: 2px;
      padding: 2px 4px 2px 5px;

      p {
        margin-bottom: 0 !important;
        margin-top: 2px;
        color: #fff;
      }
    }

    .studios_info {
      position: absolute;
      bottom: 0;
      padding: 15px;
      right: 0;
      width: 100%;

      span {
        color: #fff;
      }

      .date_ {
        color: #e0e0e0;
        font-size: 12px;

        i {
          color: #e0e0e0;
          font-size: 14px;
        }
      }

      .title_ {
        font-weight: 600;
        margin: 4px 0 0 0;
        font-size: 17px;
      }

      .catg__ {
        font-size: 15px;
        color: #6c757d !important;
      }

      .price__ {
        color: #fff;
        font-weight: bold;
        padding: 5px;
        border-radius: 4px;
      }
    }
  }
}
</style>
