<template>
  <section class="my_page">
    <v-container>
      <div class="__bread">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>

      <!-- data -->
      <div class="row mt-8 mb-sm-5">
        <!-- my chats -->
        <div class="col-md-5 col-lg-4">
          <chatsRender @changeChatId="changeChatId($event)" :changeLastMessage="changeLastMessage"/>
        </div>

        <!-- chat body -->
        <div class="col-md-7 col-lg-8">
          <chatDetails @changeLastMessage="changeLastMessage = $event" :chat_id="chat_id"/>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import chatsRender from '../../components/chats/chats-render.vue'
import chatDetails from '../../components/chats/chat-details.vue'

export default {
  components: {
    chatsRender,
    chatDetails
  },
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t("chats"),
          disabled: false,
          to: "/chats",
        },
        // {
        //   text: "محمد عادل أحمد",
        //   disabled: true,
        //   to: "",
        // },
      ],
      chat_id: parseInt(this.$route.params.id),
      changeLastMessage: 0,
    };
  },
  methods: {
    changeChatId(event) {
      let currentChatId = parseInt(this.$router.currentRoute.params.id);
      let nextChatId = parseInt(event);
      this.chat_id = nextChatId;
      if (currentChatId !== nextChatId) {
        this.$router.push({name: 'chatDetails', params: {id: event}});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.my_page {
  height: 100%;
  background-color: #fafafa;
  padding: 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }

  .__bread {
    box-shadow: none !important;

    .v-breadcrumbs {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: none !important;
      padding: 12px;
    }

    .v-breadcrumbs__item {
      color: #000 !important;
    }

    .v-breadcrumbs__item--disabled {
      color: #868e96 !important;
    }
  }
}
</style>