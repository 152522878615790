<template>
  <section class="spaces py-5" v-if="specialStudios.length > 0">
    <div class="container">
      <div class="_title">
        <h1>{{ homeSetting.studios_special_title }}</h1>
        <span v-html="homeSetting.studios_special_description"></span>
      </div>
      <div class="items">
        <div class="row justify-content-between">
          <div class="col-auto" v-for="studio in specialStudios" :key="studio.id">
            <router-link :to="{ name: 'studiosDetails', params: { id: studio.id } }">
              <div class="studio-item">
                <div class="img">
                  <img :src="studio.main_image_url" alt="studio"/>
                  <div class="fav-icon" v-if="GetUserData">
                    <BSpinner v-if="studio.loading" style="color: #AB0000;"></BSpinner>
                    <a href="javascript:void(0);" @click.prevent="toggleFavorite(studio.id, studio.isFav)">
                      <i class="mdi" :class='studio.isFav ? "mdi-heart active" : "mdi-heart-outline"'></i>
                    </a>
                  </div>
                  <div class="rate d-flex justify-content-start align-items-center flex-row absoulte" style="gap: 5px"
                       v-if="studio.totalRates > 0">
                    <span>({{ studio.totalRatesCount }}) {{ studio.totalRates }}</span>
                    <v-rating v-model="studio.totalRatesCount" background-color="#ccc" color="#FFA03F" dense readonly
                              size="16" length="1"></v-rating>
                  </div>
                </div>
                <div class="_info text-start mt-4">
                  <span class="Name text-start">{{ studio.name }}</span>
                  <div class="d-flex align-items-center">
                    <i class="mdi mdi-map-marker-outline mdi-18px"></i>&nbsp;
                    <span>
                      {{ studio.city_name }} - {{ studio.category_name }}
                    </span>
                  </div>
                  <div class="d-block text-end mt-8 mt-md-16 custom-price">
                    {{ studio.rent_price }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <div style="direction: rtl; display: none" class="slider">
          <carousel v-bind="options">
            <slide class="slide-item" v-for="studio in specialStudios" :key="studio.id">
              <router-link :to="{ name: 'studiosDetails', params: { id: studio.id } }">
                <div class="studio-item mx-3">
                  <div class="img">
                    <img :src="studio.main_image_url" alt="studio"/>
                    <div class="fav-icon" v-if="GetUserData">
                      <BSpinner v-if="studio.loading" style="color: #AB0000;"></BSpinner>
                      <a href="javascript:void(0);" @click.prevent="toggleFavorite(studio.id, studio.isFav)">
                        <i class="mdi" :class='studio.isFav ? "mdi-heart active" : "mdi-heart-outline"'></i>
                      </a>
                    </div>
                    <div class="rate d-flex justify-content-start align-items-center flex-row absoulte" style="gap: 5px"
                         v-if="studio.totalRates > 0">
                      <v-rating v-model="studio.totalRatesCount" background-color="#ccc" color="#FFA03F" dense readonly
                                size="16" length="1"></v-rating>
                      <span>({{ studio.totalRatesCount }}) {{ studio.totalRates }}</span>
                    </div>
                  </div>
                  <div class="_info text-start mt-4">
                    <span class="Name text-start"> {{ studio.name }} </span>
                    <div class="d-flex align-items-center">
                      <i class="mdi mdi-map-marker-outline mdi-18px"></i>&nbsp;
                      <span>
                      {{ studio.city_name }} - {{ studio.category_name }}
                    </span>
                    </div>
                    <div class="d-block text-end mt-8 mt-md-16 custom-price">
                      {{ studio.rent_price }}
                    </div>
                  </div>
                </div>
              </router-link>
            </slide>
          </carousel>
        </div>
      </div>
    </div>

    <div class="text-center">
      <router-link to="/spaces" class="btn btn-outline-dark slider-btn">
        {{ $t("display-more") }}
      </router-link>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  props: ["specialStudios", "homeSetting"],
  data() {
    return {
      rating: 2,
      options: {
        loop: true,
        margin: 5,
        perPage: 1,
        center: true,
        autoplay: true,
        autoplayTimeout: 3000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        navigationEnabled: false,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        perPageCustom: [
          [0, 1],
          [425, 2],
          [1332, 3],
        ],
      },
      lang: localStorage.getItem("lang") || "ar",
    };
  },
  computed: {
    ...mapGetters(["GetUserData"]),
  },
  methods: {
    toggleFavorite(studioId, isFav) {
      if (isFav) {
        this.removeFromFav(studioId);
      } else {
        this.addToFav(studioId);
      }
    },
    addToFav(studioId) {
      const index = this.specialStudios.findIndex(p => p.id === studioId);
      if (index !== -1) {
        this.specialStudios[index].loading = true;
        axios.post("add-studio-to-favorite?studio_id=" + studioId)
            .then((res) => {
              this.specialStudios[index].loading = false;
              this.specialStudios[index].isFav = true;
              this.TriggerNotify("success", res.data.message);
            })
            .catch((e) => {
              this.TriggerNotify("error", e.response.data.error);
              this.TriggerNotify("error", e.response.data.message);
              this.specialStudios[index].loading = false;
            });
      }
    },
    removeFromFav(studioId) {
      const index = this.specialStudios.findIndex(p => p.id === studioId);
      if (index !== -1) {
        this.specialStudios[index].loading = true;
        axios.post("remove-studio-from-favorite?studio_id=" + studioId)
            .then((res) => {
              this.specialStudios[index].loading = false;
              this.specialStudios[index].isFav = false;
              this.TriggerNotify("success", res.data.message);
            })
            .catch((e) => {
              this.TriggerNotify("error", e.response.data.error);
              this.TriggerNotify("error", e.response.data.message);
              this.specialStudios[index].loading = false;
            });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.spaces {
  margin-top: 60px;
  padding: 0 30px;
  @media (max-width: 580px) {
    padding: 0 0;
  }

  ._title {
    h1 {
      color: #111;
      font-weight: 700;
      font-size: 43px;
    }

    span {
      color: #4D4D4D;
      font-size: 23px;
      font-weight: 500;
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 18px;
      }
      span {
        font-size: 16px;
      }
    }
  }

  .studio-item {
    position: relative;
    background-color: #fff;
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    margin-bottom: 10px;

    @media(max-width: 1263px) {
      direction: rtl !important
    }
    @media (max-width: 768px) {
      border-radius: 16px;
    }

    .img {
      position: relative;

      &::before {
        content: "";
        background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.25) 0,
                rgba(17, 17, 17, 0.75) 100%
        );
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 5;
        border-radius: 50px 50px 0 0;
        @media (max-width: 768px) {
          border-radius: 16px;
        }
      }

      img {
        width: 100%;
        height: 425px;
        position: relative;
        object-fit: cover;
        border-radius: 50px 50px 0 0;
        @media (max-width: 768px) {
          height: 130px;
          border-radius: 16px;
        }
      }
    }

    .fav-icon {
      position: absolute;
      top: 25px;
      left: 35px;
      z-index: 9;
    }

    .fav-icon i {
      font-size: 36px;
    }

    .fav-icon i.active {
      color: #AB0000;
    }

    .rate {
      position: absolute;
      bottom: -15px;
      left: 20px;
      background: #fff;
      padding: 5px 15px;
      border-radius: 50px;
      z-index: 9;
      box-shadow: 0 0 5px 0 #777;
    }

    ._info {
      padding: 0 20px 20px 20px;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-height: 18px; /* fallback */
        max-height: 36px; /* fallback */
        color: #000;
        font-size: 16px;
        font-weight: 500;
      }

      .Name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 25px; /* fallback */
        max-height: 50px; /* fallback */
        color: #000;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .custom-price {
        color: #FFA03F;
        font-size: 18px;
        font-weight: 700;
      }

      @media (max-width: 768px) {
        span {
          line-height: 12px; /* fallback */
          max-height: 24px; /* fallback */
          font-size: 10px;
        }

        .Name {
          line-height: 14px; /* fallback */
          max-height: 28px; /* fallback */
          font-size: 12px;
          font-weight: 700;
        }
        .custom-price {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}

.items .row .studio-item {
  width: 400px;
}

.slider-btn {
  border: 3px solid #ACACAC;
  color: #4D4D4D;
  font-size: 18px;
  font-weight: 700;
  min-width: 300px;
  border-radius: 50px;
  padding: 15px 0;
}

@media (max-width: 768px) {
  .slider-btn {
    border: 0.5px solid #ACACAC;
    color: #4D4D4D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    min-width: 110px;
    border-radius: 30px;
    padding: 10px 0;
  }
}

.items {
  margin: 60px 0 30px 0;

  .row {
    @media (max-width: 1263px) {
      display: none;
    }
  }
}

.slider {
  @media (max-width: 1263px) {
    display: block !important;
  }
}
</style>
