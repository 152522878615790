// import
import Vue from 'vue'
import VueWow from 'vue-wow'

// mount with global
Vue.use(VueWow)

// mount with component
import {UAnimateContainer, UAnimate} from 'vue-wow'

export default {
  components: {
    UAnimateContainer,
    UAnimate
  }
}