<template>
  <div>
    <div class="container">
      <div class="my-16 mt-md-2 pt-6 pt-md-0 position-relative">
        <img src="/img/mainPage/main.png" alt="logo" class="img-fluid slider-bg-image"/>
        <img v-if="siteData.main_background_url" :src="siteData.main_background_url" alt="logo"
             class="img-fluid slider-gif-image"/>
        <img v-if="siteData.white_logo_url" :src="siteData.white_logo_url" alt="logo"
             class="img-fluid slider-logo"/>
        <router-link to="/spaces" class="btn btn-outline-dark slider-btn">
          {{ $t("home.discover-now") }}
        </router-link>
      </div>

      <filterSpace/>
    </div>
  </div>
</template>

<script>
import filterSpace from "./filter/filter-space.vue";

export default {
  props: ["homeSetting", "siteData"],
  components: {
    filterSpace,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.slider-bg-image {
  width: 100%;
  max-height: 500px;
}

.slider-gif-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

.slider-logo {
  position: absolute;
  top: 25px;
  right: 175px;
  width: 325px;
  height: 225px;
  object-fit: contain;
}

.slider-btn {
  position: absolute;
  right: 100px;
  bottom: 50px;
  min-width: 200px;
  padding: 10px 0;
  border-radius: 50px;
  border: 1px solid #ACACAC;
}

@media (max-width: 1263px) {
  .slider-gif-image {
    top: -35px;
    width: 300px;
    height: 300px;
  }

  .slider-logo {
    top: 50px;
    right: 100px;
    width: 200px;
    height: 120px;
  }

  .slider-btn {
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 959px) {
  .slider-gif-image {
    top: -5px;
    width: 250px;
    height: 250px;
  }

  .slider-logo {
    top: 50px;
    right: 50px;
    width: 200px;
    height: 120px;
  }
}

@media (max-width: 768px) {
  .slider-gif-image {
    top: 5px;
    width: 150px;
    height: 150px;
  }

  .slider-logo {
    top: 50px;
    right: 35px;
    width: 100px;
    height: 60px;
  }

  .slider-btn {
    right: 0;
    bottom: -40px;
    min-width: 150px;
    padding: 5px 0;
  }
}
</style>
