<template>
    <v-alert dense color="#FFF3CD" class="alert_phone_ver d-flex justify-content-start align-items-start flex-column w-100" style="margin-top:24px">
        <span class="text-center mt-2" style="color:#856404"> {{ $t('Nodata') }}</span>
    </v-alert>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.v-alert{
    *{
        letter-spacing: 0 !important;
    }
}
</style>