<template>
  <orderCard/>
</template>

<script>
import orderCard from "@/components/orders/list.vue";

export default {
  components: {
    orderCard,
  },
  name: "orders",
};
</script>

<style scoped lang="scss">
.orders {
  background-color: #fafafa;
  padding: 0 0;
  height: 100%;
  @media (max-width: 767px) {
    padding: 70px 0;
  }
}
</style>
