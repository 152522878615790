<template>
  <section class="order_details_sec">
    <v-container>
      <div class="__bread mt-16 mt-md-0">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>

      <!--      <div class="d-flex justify-content-center align-items-center flex-column mt-16">-->
      <!--        <img class="img-fluid" src="/img/notFoundImage.png" alt="not found image">-->
      <!--        <h6>نعتذر منك</h6>-->
      <!--        <p>تم ايقاف الدفع الإلكتروني حاليا وسيتم إبلاغكم فورا حين التشغيل</p>-->
      <!--        <div class="mt-8">-->
      <!--          <router-link class="btn btn-dark text-white mx-3" to="/">{{ this.$t("Breadcrumb.home") }}</router-link>-->
      <!--          <router-link class="btn btn-secondary text-white mx-3" to="/contact-us">{{ $t('contact-us') }}</router-link>-->
      <!--        </div>-->
      <!--      </div>-->

      <iframe v-if="!loading" :src="urwayData.payment_url" width="100%" height="650"></iframe>
      <!--      <div class="mysr-form"></div>-->

      <!-- loading -->
      <div v-if="loading" class="d-flex justify-center">
        <div class="skeleton-loader-width">
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
      </div>

      <v-dialog v-model="failedPayment" width="500">
        <v-alert type="error">{{ $t('order-paid-before') }}</v-alert>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'ar',
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t('e-pay'),
          disabled: true,
          to: "",
        },
      ],
      loading: true,
      failedPayment: false,
      urwayData: null,
      // moyasarData: null
    };
  },
  async mounted() {
    try {
      let orderData = {
        'type': 'studios',
        'for_id': this.$route.params.id,
        'lang': this.lang
      }
      await axios.post("initiate-payment", orderData)
          .then((res) => {
            this.urwayData = res.data.data;
            // console.log(this.urwayData);
            // console.log(this.urwayData.payment_url);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.failedPayment = true;
            setTimeout(() => {
              window.location.href = '/';
            }, 1000);
          });
    } catch (error) {
      this.TriggerNotify('error', this.$t('something-goes-wrong'));
      console.error(error);
    }

    // try {
    //   let orderData = {
    //     'type': 'studios',
    //     'for_id': this.$route.params.id
    //   }
    //   await axios.post("order-payment", orderData)
    //       .then((res) => {
    //         this.moyasarData = res.data.data;
    //
    //         const style = document.createElement('link');
    //         style.rel = 'stylesheet';
    //         style.href = 'https://cdn.moyasar.com/mpf/1.12.0/moyasar.css';
    //         document.head.appendChild(style);
    //         const script = document.createElement('script');
    //         script.type = 'text/javascript';
    //         script.src = 'https://cdn.moyasar.com/mpf/1.12.0/moyasar.js';
    //         document.head.appendChild(script);
    //
    //         // When your component is mounted, render the form within
    //         setTimeout(() => {
    //           Moyasar.init({
    //             element: '.mysr-form',
    //             // Amount in the smallest currency unit.
    //             // For example:
    //             // 10 SAR = 10 * 100 Halalas
    //             amount: this.moyasarData.amount * 100,
    //             currency: 'SAR',
    //             description: this.moyasarData.description,
    //             publishable_api_key: this.moyasarData.publishable_api_key,
    //             callback_url: this.moyasarData.callback_url,
    //             // supported_networks: ['mada'],
    //             methods: ['creditcard', 'applepay', 'stcpay'],
    //             apple_pay: {
    //               country: 'SA',
    //               label: 'SIN PLATFORM',
    //               validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
    //             }
    //           });
    //
    //           this.loading = false;
    //         }, 1000);
    //       })
    //       .catch(() => {
    //         this.failedPayment = true;
    //         setTimeout(() => {
    //           window.location.href = '/';
    //         }, 1000);
    //       });
    // } catch (error) {
    //   this.TriggerNotify('error', this.$t('something-goes-wrong'));
    //   console.error(error);
    // }
  }
};
</script>

<style scoped lang="scss">
.order_details_sec {
  background-color: #fafafa;
  padding: 0;
  margin-bottom: 25px;
  min-height: 75vh;
}

.__bread {
  box-shadow: none !important;
  margin-bottom: 24px;

  .v-breadcrumbs {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none !important;
    padding: 12px;
    text-align: start;
  }

  .v-breadcrumbs__item {
    color: #000 !important;
  }

  .v-breadcrumbs__item--disabled {
    color: #868e96 !important;
  }
}

.skeleton-loader-width {
  width: 25%;
}

@media (max-width: 767px) {
  .skeleton-loader-width {
    width: 100%;
  }
}

.v-alert {
  margin-bottom: 48px !important;
}
</style>