<template>
  <div class="my-chats">
    <!-- search -->
    <div class="input-group mb-4">
      <input v-model="search" @input="searchWithTimeOut" type="text" class="form-control"
             :placeholder="$t('search-about')">
      <span class="input-group-text">
            <i class="mdi mdi-magnify"></i>
          </span>
    </div>

    <!-- skeleton placeholder -->
    <v-skeleton-loader v-if="loading.data" type="paragraph" class="mt-3" v-for="n in 7"></v-skeleton-loader>

    <!-- loading -->
    <div class="spinner-border" role="status" v-if="loading.search">
      <span class="visually-hidden">Loading...</span>
    </div>

    <!-- items -->
    <div class="chat-items" v-if="!loading.data">
      <!-- not found data -->
      <h6 class="mt-3 d-flex justify-content-center align-items-center text-danger" v-if="data.data.length === 0">
        <i class="mdi mdi-magnify-close mdi-18px"></i>&nbsp;&nbsp;{{ $t('no-chats-found') }}
      </h6>
      <!-- item -->
      <a href="javascript:void(0);" v-for="item in data.data" :key="item.chat_id"
         @click="item.chat_unread_messages_count = 0;$emit('changeChatId', item.chat_id);">
        <div class="chat-item">
          <div class="row align-items-end">
            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img class="img-fluid chat-image" :src="item.chat_member.user_image_url"
                       :alt="item.chat_member.user_name">
                </div>
                <div class="col">
                  <span class="chat-username">{{ item.chat_member.user_name }}</span>
                  <span class="chat-last-message" :class="{'unread':item.chat_unread_messages_count > 0}">
                      {{ item.chat_last_message_text }}
                    </span>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="text-center">
                <div class="chat-time">
                  {{ item.chat_last_message_date }}
                </div>
                <div class="unread-messages-count" v-if="item.chat_unread_messages_count > 0">
                  {{ item.chat_unread_messages_count }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>

      <div class="mt-3" v-if="data.pagination.has_more_pages">
        <!-- loading -->
        <div class="spinner-border" role="status" v-if="loading.more">
          <span class="visually-hidden">Loading...</span>
        </div>
        <!-- watch more -->
        <a href="javascript:void(0);" class="link-dark d-block" @click="showMore">{{ $t("show-more") }} ...</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {io} from "socket.io-client";

export default {
  props: {
    changeLastMessage: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      search: '',
      timer: null,
      loading: {
        data: false,
        search: false,
        more: false,
      },
      data: null,
      page: 1
    };
  },
  async created() {
    this.loading.data = true;
    await this.getData();
    this.loading.data = false;

    let that = this;
    this.socket = io('https://chat.seenpark.sa/');
    let userData = JSON.parse(localStorage.getItem('user'));

    this.socket.disconnect();
    this.socket.connect();
    this.socket.on('send-to-other-' + userData.id, function () {
      that.page = 1;
      that.getData();
    });
  },
  methods: {
    async getData() {
      try {
        await axios.get("my-chats?search=" + this.search + '&page=' + this.page)
            .then((res) => {
              if (this.page > 1) {
                let allData = res.data.data;
                this.data.data = [...this.data.data, ...allData.data];
                this.data.pagination = allData.pagination;
              } else {
                this.data = res.data.data;
              }
            })
            .catch((e) => {
              this.TriggerNotify('error', e.response.data.message);
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        console.error(error);
      }
    },
    searchWithTimeOut() {
      this.loading.search = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.page = 1;
        this.getData();
        this.loading.search = false;
      }, 1000);
    },
    showMore() {
      this.page = ++this.data.pagination.current_page;
      this.getData();
    }
  },
  watch: {
    changeLastMessage() {
      this.page = 1;
      this.getData();
    }
  }
}
</script>