<template>
  <v-col cols="12" md="12">
    <v-card
        class="p-3 orders mb-4"
        v-for="(list, index) in allOrders"
        :key="index"
    >
      <router-link :to="{ name: 'studioOrdersDetails', params: { id: list.id } }">
        <div
            class="_top d-flex justify-content-between align-center flex-row flex-wrap"
        >
          <p>{{ list.studio.category_name }} - {{ list.studio.city_name }}</p>
          <p>
            <v-icon small> mdi-clock-outline</v-icon>
            <span> {{ list.from_date }} </span>
          </p>
        </div>
        <div class="_title">
          <v-card-title> {{ list.studio_name }}</v-card-title>
        </div>
        <hr>
        <div
            class="_info d-flex justify-content-between align-items-start flex-row flex-wrap gap-4 w-100 mt-4"
        >
          <p class="_date">
            <v-icon> mdi-calendar-range</v-icon>
            {{ list.from_date }} - {{ list.to_date }}
          </p>
          <v-chip label color="#FC7D12" style="color: #fff !important" v-if="list.status_key == 'wait'">
            {{ $t('status-wait') }}
          </v-chip>
          <v-chip label color="#2E8B57" style="color: #fff !important" v-if="list.status_key == 'open'">
            {{ $t('status-open') }}
          </v-chip>
          <v-chip label color="#000" style="color: #fff !important" v-if="list.status_key == 'inprogress'">
            {{ $t('status-working') }}
          </v-chip>
          <v-chip label color="#2A3B4E" style="color: #fff !important" v-if="list.status_key == 'completed'">
            {{ $t('status-completed') }}
          </v-chip>
          <v-chip label color="#949494" style="color: #fff !important" v-if="list.status_key == 'cancelled'">
            {{ $t('status-cancelled') }}
          </v-chip>
          <v-chip label color="#ff3636" style="color: #fff !important" v-if="list.status_key == 'rejected'">
            {{ $t('status-rejected') }}
          </v-chip>
        </div>
      </router-link>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    allOrders: {
      type: Array,
    },
    typeOrder: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.orders {
  ._top {
    p {
      color: #868e96;
      margin-bottom: 5px;
    }
  }

  ._title {
    .v-card__title {
      padding: 0;
      color: #212529;
      font-size: 18px;
      text-align: start;
      font-weight: bold;
    }
  }

  ._info {
    p,
    i {
      color: #4d4d4d;
      font-weight: 500;
      font-size: 16px;
    }

    i {
      font-size: 22px;
    }
  }
}

.v-card {
  box-shadow: none !important;
  border: 1px solid #e8e9ea !important;
}
</style>
