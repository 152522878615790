<template>
  <div class="_home">
    <slider :homeSetting="homeSetting" :siteData="siteData"></slider>
    <!--    <categories :sections="sections"/>-->
    <!--    <services :services="services" :advantages="advantages" :homeSetting="homeSetting" v-if="homeSetting.show_services == 1"/>-->
    <discover :cities="cities" :homeSetting="homeSetting" v-if="homeSetting.show_studios_cities == 1"/>

    <div class="bg-black">
      <div class="container">
        <div class="d-flex justify-content-between gap-3 seen-banner">
          <div class="seen-banner-info">
            <h1>استئجر عقاراتك المميزة مع سين بارك</h1>
            <p>اكتشف الشقق والشاليهات المميزة المجهزة بأحدث التقنيات لتحويل أفكارك إلى واقع.</p>
            <h5> ابدأ واحصل على تجربة حجز مميزة</h5>
          </div>
          <img v-if="siteData.first_banner_url" :src="siteData.first_banner_url" alt="logo" class="img-fluid"/>
        </div>
      </div>
    </div>

    <classification :studioCategories="studioCategories" :homeSetting="homeSetting"
                    v-if="homeSetting.show_studios_categories == 1"/>
    <spaces :specialStudios="specialStudios" :homeSetting="homeSetting" v-if="homeSetting.show_studios_special == 1"/>
    <v-container v-else>
      <div class="">
        <div class="row mt-5">
          <v-col cols="12" md="12" class="m-auto">
            <v-skeleton-loader
                class="mx-auto"
                type="article"
                width="500"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
        </div>
        <div class="row mt-5">
          <v-col cols="12" md="12" class="m-auto">
            <v-skeleton-loader
                class="mx-auto"
                type="article"
                width="500"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
        </div>
        <div class="row mt-5">
          <v-col cols="12" md="12" class="m-auto">
            <v-skeleton-loader
                class="mx-auto"
                type="article"
                width="500"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
        </div>
      </div>
    </v-container>

    <div>
      <div class="container">
        <div class="d-flex justify-content-between gap-3 seen-bottom-banner">
          <div class="seen-bottom-banner-info">
            <div class="info-item">
              <img src="/img/mainPage/icon1.png" alt="logo" class="img-fluid"/>
              <span>تجربة حجز سلسة وسهلة الاستخدام</span>
            </div>
            <div class="info-item">
              <img src="/img/mainPage/icon2.png" alt="logo" class="img-fluid"/>
              <span>توفير أفضل العروض والأسعار</span>
            </div>
            <div class="info-item">
              <img src="/img/mainPage/icon3.png" alt="logo" class="img-fluid"/>
              <span>طرق متعدده وسهولة الدفع</span>
            </div>
            <div class="info-item">
              <img src="/img/mainPage/icon4.png" alt="logo" class="img-fluid"/>
              <span>دعم فني على مدار الساعة</span>
            </div>
          </div>
          <img v-if="siteData.second_banner_url" :src="siteData.second_banner_url" alt="logo"
               class="img-fluid seen-bottom-banner-img"/>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center text-center gap-3 seen-app">
      <div class="seen-app-info">
        <h1>تطبيق SEEN PARK</h1>
        <h1 :class="(social.app_store || social.google_play) ? 'mb-8 mb-md-16' : ''">قريباً SOON</h1>
        <div class="mb-5" v-if="social.app_store">
          <a :href="social.app_store" target="_blank" class="btn btn-outline-dark seen-app-btn">
            <i class="mdi mdi-apple"></i>&nbsp;&nbsp;Apple Store
          </a>
        </div>
        <div v-if="social.google_play">
          <a :href="social.google_play" target="_blank" class="btn btn-outline-dark seen-app-btn">
            <i class="mdi mdi-google-play"></i>&nbsp;&nbsp;Google Paly
          </a>
        </div>
      </div>
      <img :src="siteData.app_mobile_image_url ?? '/img/mainPage/mobile.png'" alt="logo" class="img-fluid"/>
    </div>

    <!--    <howWork :homeSetting="homeSetting" v-if="homeSetting.show_how_work == 1"/>-->

    <LightBox :media="explanations" v-if="Openlightbox && explanations.length" @onClosed="closeLightbox"
              :showCaption="true" :showThumbs="false"></LightBox>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import slider from "@/components/home/slider.vue";
import categories from "@/components/home/categories.vue";
import services from "@/components/home/services.vue";
import discover from "@/components/home/discover.vue";
import spaces from "@/components/home/spaces.vue";
import howWork from "@/components/home/how-work.vue";
import classification from "@/components/home/classification.vue";
import LightBox from 'vue-it-bigger';

import('vue-it-bigger/dist/vue-it-bigger.min.css');

export default {
  name: "index",
  components: {
    slider,
    categories,
    services,
    discover,
    spaces,
    howWork,
    classification,
    LightBox
  },
  data() {
    return {
      Openlightbox: true,
    };
  },
  computed: {
    advantages() {
      return this.services.reduce((allAdvantages, service) => {
        return allAdvantages.concat(service.advantages);
      }, []);
    },
    ...mapGetters("home", [
      "getSections",
      "getServices",
      "getCatg",
      "getStudios",
      "getCities",
      "getSetting",
      "getSiteData",
      "getSocial",
      "getExplanations",
    ]),
    sections() {
      return this.getSections;
    },
    services() {
      return this.getServices;
    },
    studioCategories() {
      return this.getCatg;
    },
    specialStudios() {
      return this.getStudios;
    },
    cities() {
      return this.getCities;
    },
    homeSetting() {
      return this.getSetting;
    },
    siteData() {
      return this.getSiteData;
    },
    social() {
      return this.getSocial;
    },
    explanations() {
      return this.getExplanations;
    },
  },
  mounted() {
    this.$store.dispatch("home/fetchSiteData");
  },
  methods: {
    closeLightbox() {
      this.Openlightbox = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.seen-banner {
  padding: 100px 0;

  .seen-banner-info {
    color: #fff;
    text-align: start;

    h1 {
      font-size: 40px;
      font-weight: 500;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      padding: 40px 0;
    }

    h5 {
      font-size: 18px;
      font-weight: 700;
    }
  }

  img {
    height: 300px;
    object-fit: contain;
  }
}

.seen-bottom-banner {
  padding: 40px 0;

  .seen-bottom-banner-info {
    .info-item {
      display: flex;
      align-items: center;
      text-align: start;
      margin-bottom: 15px;

      span {
        color: #000;
        font-size: 36px;
        font-weight: 500;
      }
    }
  }

  .seen-bottom-banner-img {
    height: 400px;
    object-fit: contain;
  }
}

@media (max-width: 1023px) {
  .seen-banner {
    padding: 35px 0;

    .seen-banner-info {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
        padding: 10px 0;
      }

      h5 {
        font-size: 14px;
      }
    }

    img {
      height: 150px;
    }
  }

  .seen-bottom-banner {
    padding: 0;

    .seen-bottom-banner-info {
      .info-item {
        span {
          font-size: 16px;
        }
      }
    }

    .seen-bottom-banner-img {
      height: 250px;
    }
  }
}

@media (max-width: 575px) {
  .seen-banner {
    padding: 25px 0;

    .seen-banner-info {
      h1 {
        font-size: 14px;
      }

      p {
        font-size: 10px;
        padding: 10px 0;
      }

      h5 {
        font-size: 10px;
      }
    }

    img {
      height: 90px;
    }
  }

  .seen-bottom-banner {
    padding: 0;

    .seen-bottom-banner-info {
      .info-item {
        img {
          width: 45px;
          height: 45px;
          object-fit: contain;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .seen-bottom-banner-img {
      height: 150px;
    }
  }
}

.seen-app {
  background: rgba(172, 172, 172, 0.09);
  padding: 35px 0;

  .seen-app-info {
    padding: 0 50px;

    h1 {
      font-size: 60px;
      color: #000;
      font-weight: 500;
    }

    .seen-app-btn {
      min-width: 300px;
      border-radius: 50px;
      padding: 15px 0;
      font-size: 18px;
      font-weight: 500;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 1024px) {
      h1 {
        font-size: 20px;
      }
      .seen-app-btn {
        min-width: 150px;
        padding: 5px 0;
        font-size: 14px;
      }
      .seen-app-btn i {
        font-size: 16px;
      }
    }
  }

  img {
    max-height: 600px;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    .seen-app-info {
      padding: 0;
    }
    img {
      max-height: 250px;
    }
  }
}

span {
  strong {
    font-weight: bold;
    background: #000;
    color: #fff;
    padding: 6px 10px 10px 14px;
    margin: 0 5px;
  }
}
</style>

<style>
.vib-hidden {
  opacity: 1;
}

.vib-close {
  right: auto;
  left: 0;
}
</style>
