import axios from 'axios'
const state = {
    favsList: [],
    loading: false
};

const mutations = {
    SET_FAVS_LIST(state, favsList) {
        state.favsList = favsList
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

const actions = {
    async GetFavsList({ commit }, params) {
        commit('SET_LOADING', true);
        return axios.get('favorite', { params }).then(res => {
            commit('SET_LOADING', false);
            commit('SET_FAVS_LIST', res.data.data.data)
        })
    }
};

const getters = {
    GetListFavs: state => state.favsList,
    Loading: state => state.loading
}


export default {
    actions,
    state,
    mutations,
    getters
};