<template>
    <v-col cols="12" md="12" v-if="allOrders.length > 0">
      <v-card
        class="p-3 orders mb-4"
        v-for="(list, index) in allOrders"
        :key="index"
      >
        <router-link :to="{ name: 'ordersDetails', params: { id: list.id } }">
          <div
            class="_top d-flex justify-content-between align-center flex-row flex-wrap"
          >
            <p>{{ list.stock.category_name }}</p>
          </div>
          <div class="_title">
            <v-card-title> {{ list.stock_name }} </v-card-title>
          </div>
          <div class="_title">
            <v-card-title> {{ list.stock.description }} </v-card-title>
          </div>
          <div
            class="_info d-flex justify-content-start align-start flex-row flex-wrap gap-4 w-100 mt-4"
          >
            <p class="_id"><v-icon> mdi-pound </v-icon> {{ list.id }}</p>
            <p class="_name" v-if="list.stock.member_name">
              <v-icon> mdi-account </v-icon> {{ list.stock.member_name }}
            </p>
            <p class="_location">
              <v-icon> mdi-format-list-bulleted-type </v-icon> {{ list.option }}
            </p>
            <p class="_date">
              <v-icon> mdi-currency-usd </v-icon>
              {{ list.option_price_display }}
            </p>
            <p class="_status">
              <v-icon> mdi-cog-outline </v-icon> {{ list.provider_agree_status }}
            </p>
          </div>
        </router-link>
      </v-card>
    </v-col>
</template>

<script>
export default {
  props: {
    allOrders: {
      type: Array,
    },
    typeOrder: {
      type: String,
    },
  },
};
</script> 

<style lang="scss" scoped>
.orders {
  ._top {
    p {
      color: #868e96;
      margin-bottom: 5px;
    }
  }
  ._title {
    .v-card__title {
      padding: 0;
      color: #212529;
      font-size: 18px;
      text-align: start
    }
  }
  ._info {
    p,
    i {
      color: #4d4d4d;
      font-weight: 500;
      font-size: 16px;
    }
    i {
      font-size: 22px;
    }
  }
}

.v-card {
  box-shadow: none !important;
  border: 1px solid #e8e9ea;
  border-color: #e8e9ea !important;
}
</style>
