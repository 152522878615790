<template>
  <section class="static-pages">
    <v-container>
      <div class="__bread mt-5">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div class="content">
        <h3>{{ $t('footer.contact') }}</h3>
        <hr style="margin: 16px 0 32px 0;"/>
        <v-row class="w-100">
          <v-col cols="12" md="7">
            <div
                class="d-flex justify-content-start align-items-start form flex-column"
                style="gap: 10px"
            >
              <label for=""> {{ $t('contactUs.name') }} </label>
              <v-text-field
                  v-model="name"
                  required
                  class="w-100 h-3"
                  outlined
                  :error-messages="nameError"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
              ></v-text-field>
            </div>
            <div
                class="d-flex justify-content-start align-items-start flex-column w-100 position-relative form mobile"
                style="gap: 10px"
            >
              <span> {{ $t('contactUs.phone') }}</span>
              <div class="saudi-num">
                <span> 966+ </span>
                <img
                    src="../../../public/img/ksa.webp"
                    alt="ksa"
                    width="17"
                    height="17"
                />
              </div>
              <v-text-field
                  v-model="mobile"
                  dir="ltr"
                  required
                  outlined
                  class="w-100"
                  :error-messages="mobileError"
                  @input="$v.mobile.$touch()"
                  @blur="$v.mobile.$touch()"
              ></v-text-field>
            </div>
            <div
                class="d-flex justify-content-start align-items-start form flex-column"
                style="gap: 10px"
            >
              <label for=""> {{ $t('contactUs.email') }}</label>
              <v-text-field
                  v-model="email"
                  required
                  class="w-100 h-3"
                  outlined
                  :error-messages="emailError"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
              ></v-text-field>
            </div>
            <div
                class="d-flex justify-content-start align-items-start flex-column textarea"
                style="gap: 10px"
            >
              <label for=""> {{ $t('contactUs.message') }} </label>
              <v-textarea
                  v-model="message"
                  required
                  class="w-100"
                  outlined
                  :error-messages="messageError"
                  @input="$v.message.$touch()"
                  @blur="$v.message.$touch()"
              ></v-textarea>
            </div>
            <hr style="margin: 32px 0 16px 0;">
            <div class="row justify-content-end align-items-center">
              <div class="col-auto">
                <v-btn class="bg-dark text-white" @click="handleContactUs" :loading="loading">
                  {{ $t('send') }}
                </v-btn>
              </div>
              <div class="col-auto">
                <v-btn class="bg-white text-dark border border-dark">
                  <a :href="'https://wa.me/'+getWhatsappSettings.whatsapp_number" target="_blank">
                    <i class="mdi mdi-whatsapp mdi-18px"></i>&nbsp;&nbsp;{{ $t('whatsapp-contact') }}
                  </a>
                </v-btn>
              </div>
            </div>
          </v-col>
          <!-- <v-col cols="12" md="5">
            <div class="contact-image">
              <img src="../../../public/img/Col-4.png" alt="contact us" />
            </div>
          </v-col> -->
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {contactUs} from "@/rules";

export default {
  mixins: [contactUs],
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t('footer.contact'),
          disabled: true,
          to: "",
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("home", ["getContactData", "getWhatsappSettings"]),
    contactPayload() {
      const payload = {
        sender_name: this.name,
        email: this.email,
        mobile: this.mobile,
        message: this.message,
      };
      return {...payload};
    },
  },
  methods: {
    handleContactUs() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      this.$store
          .dispatch("home/contactUs", this.contactPayload)
          .then((res) => {
            this.loading = false;
            this.resetContactUs();
            this.$v.$reset();
            this.TriggerNotify("success", this.getContactData);
          })
          .catch((err) => {
            this.loading = false;
            this.TriggerNotify("error", err.response.data.message);
          });
    },
    resetContactUs() {
      this.name = null;
      this.email = null;
      this.mobile = null;
      this.message = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.static-pages {
  height: 100%;
  background-color: #fafafa;
  padding: 0px 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }

  .__bread {
    box-shadow: none !important;
    margin-bottom: 48px;

    .v-breadcrumbs {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: none !important;
      padding: 12px;
    }

    .v-breadcrumbs__item {
      color: #000 !important;
    }

    .v-breadcrumbs__item--disabled {
      color: #868e96 !important;
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 48px;

    h3 {
      font-weight: bold;
      margin-top: 5px;
      color: #4d4d4d;
    }

    hr {
      height: 5px;
      color: #e0e0e0;
      width: 100%;
    }
  }

  .saudi-num {
    position: absolute;
    top: 35px;
    left: 12px;
    z-index: 9;
    background: #fafafa;
    padding: 6px 22px;
    border-radius: 5px;

    span {
      margin-inline-end: 5px;
    }
  }

  .contact-image {
    // margin-top: 115px;
  }
}

.mobile {
  input {
    padding-left: 120px;
  }
}

.v-input__slot, .v-text-field__slot {
  margin-bottom: 0px !important
}
</style>
