import { render, staticRenderFns } from "./academy.vue?vue&type=template&id=7cf4b51b&scoped=true"
import script from "./academy.vue?vue&type=script&lang=js"
export * from "./academy.vue?vue&type=script&lang=js"
import style0 from "./academy.vue?vue&type=style&index=0&id=7cf4b51b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf4b51b",
  null
  
)

export default component.exports