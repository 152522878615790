import axios from 'axios'

const state = {
    getDataProfile: '',
    getImag: '',
    messageProfile: "",
    errorProfile: "",
    messagePassword: "",
    errorPassword: '',
    messagePhone: "",
    errorPhone: '',
    messageEmail: "",
    errorEmail: '',
    verfiyMeesage: ""
}
const getters = {
    GetDataProfile: (state) => state.getDataProfile,
    getImag: (state) => state.getImag,
    getMessageProfile: (state) => state.messageProfile,
    getErrorProfile: (state) => state.errorProfile,
    getMessagePassword: (state) => state.messagePassword,
    getErrorPassword: (state) => state.errorPassword,
    getMessagePhone: (state) => state.messagePhone,
    GetSmsType2: state => state.smsType,
    getErrorPhone: (state) => state.errorPhone,
    getMessageEmail: (state) => state.messageEmail,
    getErrorEmail: (state) => state.errorEmail,
    GetVerifyEmail: (state) => state.verfiyMeesage
}
const mutations = {
    SET_DATA_PROFILE(state, getDataProfile) {
        state.getDataProfile = getDataProfile
    },
    SET_IMAGE_USER(state, getImag) {
        state.getImag = getImag
    },
    SET_MESSAGE_PROFILE(state, messageProfile) {
        state.messageProfile = messageProfile
    },
    SET_ERROR_PROFILE(state, errorProfile) {
        state.errorProfile = errorProfile
    },
    SET_MESSAGE_PASSWORD(state, messagePassword) {
        state.messagePassword = messagePassword
    },
    SET_ERROR_PASSWORD(state, errorPassword) {
        state.errorPassword = errorPassword
    },
    SET_MESSAGE_PHONE(state, messagePhone) {
        state.messagePhone = messagePhone
    },
    SET_SMS_TYPE(state, smsType) {
        state.smsType = smsType
    },
    SET_MESSAGE_EMAIL(state, messageEmail) {
        state.messageEmail = messageEmail
    },
    SET_VERIFY_MESSAGE(state, verfiyMeesage) {
        state.verfiyMeesage = verfiyMeesage
    }
}
const actions = {
    async handleGetDataProfile({commit}) {
        return axios.post('profile-data').then((res) => {
            commit('SET_DATA_PROFILE', res.data.data)
            localStorage.setItem('userImage', res.data.data.image_url)
        });
    },
    async changeProfile({commit}, payload) {
        return axios.post('profile-update', payload).then((res) => {
            commit('SET_IMAGE_USER', res.data.data.image_url)
            commit('SET_MESSAGE_PROFILE', res.data.message)
            commit('SET_ERROR_PROFILE', res.data.error)
        });
    },
    async changePassword({commit}, payload) {
        return axios.post('update-password', payload).then((res) => {
            commit('SET_MESSAGE_PASSWORD', res.data.message)
            commit('SET_ERROR_PASSWORD', res.data.message)
        });
    },
    async changePhone({commit}, payload) {
        return axios.post('mobile-update', payload).then((res) => {
            commit('SET_MESSAGE_PHONE', res.data.message);
            commit('SET_SMS_TYPE', res.data.data.sms_type);
        });
    },
    async changeEmail({commit}, payload) {
        return axios.post('email-update', payload).then((res) => {
            commit('SET_MESSAGE_EMAIL', res.data.message)
        });
    },
    async verifyEmail({commit}, payload) {
        return axios.post('email-update_verify', payload).then((res) => {
            commit('SET_VERIFY_MESSAGE', res.data.message)
        });
    },
    async verifyPhone({commit}, payload) {
        return axios.post('mobile-update_verify', payload).then((res) => {
            commit('SET_VERIFY_MESSAGE', res.data.message)
        });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}