<template>
  <section class="static-pages">
    <v-container>
      <div class="__bread mt-5">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div class="content" v-if="contract">
        <h3>{{ contract.title }}</h3>
        <hr/>
        <p v-html="contract.text"></p>

        <template>
          <div class="mt-8 w-100">
            <p v-if="!contract.status">{{ $t('make-your-signature-text') }}</p>

            <img v-if="contract.status" class="img-fluid" :src="contract.image_path" :alt="contract.title">
            <VueSignaturePad v-else width="100%" height="350px" ref="signaturePad"
                             :customStyle="{border: '1px solid #111', borderRadius: '8px'}"/>

            <div class="d-flex justify-content-end align-items-center mt-4" v-if="!contract.status">
              <button class="btn btn-light px-4 d-inline-flex align-items-center" @click="undo">
                <i class="mdi mdi-undo-variant"></i>&nbsp;&nbsp;{{ $t('undo') }}
              </button>
              &nbsp;&nbsp;
              <button class="btn btn-secondary text-white px-4 d-inline-flex align-items-center" @click="clear">
                <i class="mdi mdi-delete"></i>&nbsp;&nbsp;{{ $t('delete-signature') }}
              </button>
              &nbsp;&nbsp;
              <button class="btn btn-dark text-white px-16 d-inline-flex align-items-center" @click="save">
                <i class="mdi mdi-send"></i>&nbsp;&nbsp;{{ $t('send') }}
              </button>
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <NoData/>
      </div>
    </v-container>
  </section>
</template>

<script>
import NoData from "../no-data.vue";
import axios from "axios";

export default {
  components: {
    NoData,
  },
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t('rent-terms'),
          disabled: true,
          to: "",
        },
      ],
      contract_id: parseInt(this.$route.params.id),
      contract: null,
      signatureImage: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        await axios.get("user-contract/" + this.contract_id)
            .then((res) => {
              this.contract = res.data.data;
            })
            .catch((e) => {
              this.TriggerNotify('error', e.response.data.message);
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        console.error(error);
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature('image/png');
      if (!isEmpty) {
        this.signatureImage = data;
        this.sendSignature();
        return false;
      }
      this.TriggerNotify('error', this.$t('please-sign-first'));
    },
    async sendSignature() {
      try {
        let sendData = new FormData();
        sendData.append('contract_id', this.contract_id);
        sendData.append('image', this.signatureImage);

        await axios.post("send-signature", sendData)
            .then((res) => {
              this.contract = res.data.data;
              this.TriggerNotify('success', res.data.message);
            })
            .catch((e) => {
              this.TriggerNotify('error', e.response.data.message);
            });
      } catch (error) {
        this.TriggerNotify('error', this.$t('something-goes-wrong'));
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.static-pages {
  height: 100%;
  background-color: #fafafa;
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }

  .__bread {
    box-shadow: none !important;

    .v-breadcrumbs {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: none !important;
      padding: 12px;
    }

    .v-breadcrumbs__item {
      color: #000 !important;
    }

    .v-breadcrumbs__item--disabled {
      color: #868e96 !important;
    }
  }

  .content {
    margin-top: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    h3 {
      font-weight: bold;
      margin-top: 5px;
      color: #4d4d4d;
    }

    p {
      line-height: 1.8;
      color: #4d4d4d;
      text-align: start;
    }

    hr {
      height: 5px;
      color: #e0e0e0;
      width: 100%;
    }
  }
}
</style>
