import axios from 'axios'

const state = {
    proffesionals: [],
    cities: [],
    categories: [],
    nationalities: [],
    currentPage: 1,
    lastPage: "",
    totalProffesionals: 0,
    profffesionalsDetails: {},
    profffesionalsDetailsAdditional: [],
    reviews: []
}
const mutations = {
    SET_ALL_PROFFESIONALS(state, proffesionals) {
        state.proffesionals = proffesionals
    },
    SET_ALL_CITIES(state, cities) {
        state.cities = cities
    },
    SET_ALL_CATEGORIES(state, categories) {
        state.categories = categories
    },
    SET_ALL_NATIONALITIES(state, nationalities) {
        state.nationalities = nationalities
    },
    SET_CURRENT_PAGE(state, currentPage) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state, lastPage) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PROFFESIONALS(state, totalProffesionals) {
        state.totalProffesionals = totalProffesionals
    },
    SET_DETAILS_PROFFESIONALS(state, profffesionalsDetails) {
        state.profffesionalsDetails = profffesionalsDetails
    },
    SET_DETAILS_PROFFESIONALS_ADDITIONALS(state, profffesionalsDetailsAdditional) {
        state.profffesionalsDetailsAdditional = profffesionalsDetailsAdditional
    },
    SET_REVIEWS_PRO(state, reviews) {
        state.reviews = reviews
    }
}
const actions = {
    async getAllProffesionals({ commit }, { id, params }) {
        const queryParams = new URLSearchParams(params).toString();
        return axios.get(`section-details/${id}?${queryParams}`).then(res => {
            commit("SET_ALL_PROFFESIONALS", res.data.data.data)
            commit("SET_ALL_CITIES", res.data.additional.cities)
            commit("SET_ALL_CATEGORIES", res.data.additional.categories)
            commit("SET_ALL_NATIONALITIES", res.data.additional.nationalities)
            commit("SET_CURRENT_PAGE", res.data.data.pagination.currentPage)
            commit("SET_LAST_PAGE", res.data.data.pagination.last_page)
            commit("SET_TOTAL_PROFFESIONALS", res.data.data.pagination.total)
        })
    },
    async getProffesionalsDetails({ commit }, id) {
        return axios.post(`professional-details/${id}`).then(res => {
            commit('SET_DETAILS_PROFFESIONALS', res.data.data)
            commit('SET_DETAILS_PROFFESIONALS_ADDITIONALS', res.data.additional)
        })
    },
    async getReviwesPro({ commit }, id) {
        return axios.post(`professional-rates/${id}`).then(res => {
            commit("SET_REVIEWS_PRO", res.data.data.data)
        })
    }
}
const getters = {
    GetAllProffesionals: state => state.proffesionals,
    GetAllCities: state => state.cities,
    GetAllCategories: state => state.categories,
    GetAllNationalities: state => state.nationalities,
    GetAllCurrentPage: state => state.currentPage,
    GetAllLastPage: state => state.lastPage,
    GetAllTotalProffesionals: state => state.totalProffesionals,
    GetDetailsProffesionals: state => state.profffesionalsDetails,
    GetDetailsProffesionalsAdditionals: state => state.profffesionalsDetailsAdditional,
    GetReviewsPro: state => state.reviews
}

export default {
    state,
    mutations,
    actions,
    getters
}