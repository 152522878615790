import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../locales/en.json';
import ar from '../locales/ar.json';

Vue.use(VueI18n);

const messages = {
  en,
  ar,
};
const url = new URL(window.location.href);
const langParam = url.searchParams.get("lang");
const i18n = new VueI18n({
  // locale: localStorage.getItem('lang') || 'ar', // Default language
    locale: localStorage.getItem('lang') || langParam || 'ar', // Default language

  messages
});

export default i18n;