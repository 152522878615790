<template>
  <div>
    <v-snackbar color="green" v-model="snackbar" top right>
      تم عمل حساب بنجاح
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import { signupMixin } from "../../rules.js";
export default {
  mixins: [signupMixin],
  data() {
    return {
      verifyAccDialog: true,
      loading: false,
      errors: [],
      error: "",
    };
  },
  methods: {
    ...mapActions("user", ["setUser"]),
    HandleVerify() {
      this.$v.$touch();
      // if (this.$v.$invalid) return;
      this.loading = true;
      const form_data = new FormData();
      // Retrieve email from local storage
      const email = localStorage.getItem("email");
      form_data.append("email", email);
      form_data.append("code", this.verify);
      axios
        .post("register-verify", form_data)
        .then(() => {
          this.loading = false;
          this.$v.$reset();
          this.verifyDialog = false;
          this.newPassDialog = true;
          localStorage.setItem("verify", this.verify);
          localStorage.setItem("token", res.data.data.accessToken);
          this.user = res.data.data;
          this.$store.dispatch("user/setUser", res.data.data); // Dispatch the 'setUser' action
        })
        .catch((e) => {
          this.errorVerify = e.response.data.message;
          this.errorVerifysEC = e.response.data.error;
          this.loading = false;
        });
    },
    openDialog() {
      this.verifyAccDialog = true;
    }
  },
};
</script>

<style lang="scss" scoped></style>
