import Vue from 'vue'
import Vuex from 'vuex'
import user from './user.js'
import home from './home.js'
import addprojectJS from './addproject.js'
import orderProjectDetails from './orderProjectDetails.js'
import orderStudioDetails from './orderStudioDetails.js'
import projects from './projects.js'
import proffesionals from './proffesionals.js'
import auth from './auth.js'
import notification from './notification.js'
import profile from './profile.js'
import favourite from './favourite.js'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        home,
        addprojectJS,
        orderProjectDetails,
        orderStudioDetails,
        projects,
        proffesionals,
        auth,
        notification,
        profile,
        favourite
    },
    namespaced: true
})