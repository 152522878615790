<template>
  <section class="related_stockes">
    <div class="container">
      <div class="section_title text-start">
        <h4 class="font-weight-bold">{{ $t("studios.anotherStudios") }}</h4>
        <hr style="margin-bottom:32px !important"/>
      </div>
      <div class="" v-if="relatedStudios.length > 0">
        <div class="" style="direction: rtl">
          <carousel v-bind="options">
            <slide class="slide-item" v-for="(list, index) in relatedStudios" :key="index">
              <router-link :to="{ name: 'studiosDetails', params: { id: list.id } }" @click.native="$router.go()">
                <div class="studios_lists__item">
                  <div class="studios_lists__item__img">
                    <img :src="list.main_image_url" :alt="list.name"/>
                  </div>
                  <div class="studios_rate d-flex justify-content-center align-start gap-1"
                       v-if="list.totalRatesCount > 0">
                    <v-rating v-model="list.totalRatesCount" background-color="white" color="yellow accent-4" dense
                              size="18" length="1"></v-rating>
                    <p>{{ list.totalRatesCount }}</p>
                  </div>
                  <div class="studios_info d-flex justify-content-start text-start flex-column flex-wrap">
                    <span class="title_"> {{ list.name }} </span>
                    <div class="d-flex justify-content-between align-center flex-row flex-wrap">
                      <span class="catg__">
                        {{ list.city_name }} - {{ list.category_name }}
                      </span>
                      <span class="price__">{{ list.rent_price }}</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </slide>
          </carousel>
        </div>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["relatedStudios"],
  data() {
    return {
      studios: [
        {
          id: 1,
          date: 27 - 11 - 2020,
          name: " عقار في مصر",
          city_name: "الدمام",
          category_name: "التصوير",
          price: "300 " + this.$t("riyal-at-hour"),
        },
        {
          id: 2,
          date: 24 - 4 - 1907,
          name: " عقار بلا بلا بلا في مصر",
          city_name: "الرياض",
          category_name: "التصوير",
          price: "360 " + this.$t("riyal-at-hour"),
        },
      ],
      options: {
        loop: true,
        margin: 5,
        perPage: 3,
        center: true,
        autoplay: true,
        autoplayTimeout: 3000,
        paginationEnabled: false,
        autoplayHoverPause: true,
        navigationEnabled: false,
        paginationColor: "#555",
        paginationActiveColor: "#9f762b",
        paginationSize: 5,
        rtl: true,
        // ltr: true,
        // responsive: true,
        perPageCustom: [
          [0, 1],
          [480, 1],
          [768, 1],
          [1000, 2],
          [1200, 3],
        ],
      },
      attrs: {
        class: "mb-2",
        boilerplate: true,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: start !important;
  color: #000 !important;
  padding: 12px 10px 0 10px !important;
  font-size: 19px !important;
  font-weight: 500 !important;
}

._subtitle {
  text-align: start !important;
  width: 100% !important;
  display: block !important;
  padding: 0 10px 20px 10px !important;
  color: #868e96;
  font-size: 16px;
}

.v-card {
  margin-bottom: 15px;
  transition: 0.3s ease-in-out all;

  .type_cine {
    background: #00000080 0 0 no-repeat padding-box;
    border-radius: 2px;
    padding: 4px 8px;
    display: inline-flex;
    position: absolute;
    top: 10px;
    left: 15px;

    span {
      color: #fff;
    }

    i {
      color: #fff;
    }
  }
}

.studios_lists__item {
  position: relative;
  border-radius: 4px;
  height: 250px;

  .studios_lists__item__img {
    border-radius: 10px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #000000 100%) 0 0 no-repeat padding-box;
      border-radius: 4px;
    }

    img {
      width: 100%;
      border-radius: 4px;
      height: 250px;
    }
  }

  .studios_rate {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px;

    p {
      margin-bottom: 0 !important;
      margin-top: 2px;
      color: #fff;
    }
  }

  .studios_info {
    position: absolute;
    bottom: 0;
    padding: 15px;
    right: 0;
    width: 100%;

    span {
      color: #fff;
    }

    .date_ {
      color: #e0e0e0;
      font-size: 12px;

      i {
        color: #e0e0e0;
        font-size: 14px;
      }
    }

    .title_ {
      font-weight: 600;
      margin: 4px 0 0 0;
      font-size: 15px !important;
    }

    .catg__ {
      font-size: 16px;
      color: #6c757d !important;
    }

    .price__ {
      color: #fff;
      font-weight: bold;
      padding: 5px;
      border-radius: 4px;
    }
  }
}

.related_stockes {
  margin: 36px 0 !important;
}

.VueCarousel-slide-center {
  .studios_lists__item {
    margin: 0 16px;
  }
}
</style>
